import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import InputAdornment from '@mui/material/InputAdornment'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import { styled } from '@mui/material/styles'
import AnalysisVariantsSelect from '@/features/eeg-sessions/components/AnalysisVariantsSelect'
import BrainCanvas from '@/features/eeg-sessions/components/BrainCanvas'
import ExecutiveStyleAutocomplete from '@/features/eeg-sessions/components/ExecutiveStyleAutocomplete'
import SensorsAutocomplete from '@/features/eeg-sessions/components/SensorsAutocomplete'
import WiringPatternsAutocomplete from '@/features/eeg-sessions/components/WiringPatternsAutocomplete'
import {
  analysisShape,
  sensorsShape,
} from '@/features/eeg-sessions/store/propTypes'
import {
  EEG_ANALYSIS_TOP_SKILLS_COUNT,
  EEG_ANALYSIS_TOP_SKILLS_IN_THE_WORKPLACE_COUNT,
} from '@/features/eeg-sessions/store/propTypes/analyses'
import {
  getExecutiveStyle,
  getTopCircuitDetails,
  getTopSkills,
  getTopStarburstDetails,
  parseNodes,
} from '@/features/eeg-sessions/utils/analyses'
import { parseCircuitsTextFile } from '@/features/eeg-sessions/utils/sessions'
import { getSensorsFromAnalysis } from '@/features/eeg-sessions/utils/sensors'
import { onFileChange } from '@/utils/files'
import { Typography } from '@mui/material'

const Input = styled('input')({ display: 'none' })

function AnalysisFormSection({
  analysis,
  duration,
  onChange,
  onDelete,
  onDurationChange,
  onSensorsChange,
  sensors,
  textFieldProps,
  ...otherProps
}) {
  const intl = useIntl()

  const {
    executiveStyle = null,
    topCircuitHits = '',
    topSkills = [],
    topWiringPatterns = [],
    variant = null,
  } = analysis || {}

  const handleFileChange = onFileChange((file) => (event) => {
    if (!file) return

    const session = parseCircuitsTextFile(file.name, event.target.result)
    if (!session) return

    const analysis = session.analyses[0]
    const { circuits, normalizedScores, topCircuitHits } = analysis || {}

    // Compute items from analysis
    const sensors = getSensorsFromAnalysis(analysis)

    // Update analysis
    const executiveStyle = getExecutiveStyle({ analysis, sensors })
    const topSkills = getTopSkills({
      analysis,
      top: EEG_ANALYSIS_TOP_SKILLS_COUNT,
    })
    onChange({
      circuits,
      executiveStyle,
      normalizedScores,
      topCircuitHits,
      topSkills,
    })

    // Update session
    onDurationChange(session.duration)
    onSensorsChange(sensors)
  })

  const { circuit: topCircuit, period: topCircuitPeriod } =
    getTopCircuitDetails(analysis, duration)

  const {
    circuit: topStarburstCircuit,
    period: topStarburstPeriod,
    ranking: topStarburstRanking,
  } = getTopStarburstDetails(analysis, duration)

  const areCircuitsUploaded = !!analysis?.circuits?.length

  const onExecutiveStyleChange = React.useCallback(
    (e, executiveStyle) => onChange({ executiveStyle }),
    [onChange]
  )

  return (
    <Card {...otherProps}>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Stack spacing={3}>
              <AnalysisVariantsSelect
                {...textFieldProps}
                onChange={(e) => onChange({ variant: e.target.value })}
                value={variant}
              />
              {areCircuitsUploaded && (
                <>
                  <TextField
                    {...textFieldProps}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <FormattedMessage
                            defaultMessage="Hits"
                            description="Adornment for top circuit hits TextField"
                          />
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) =>
                      onChange({
                        topCircuitHits: e.target.value
                          ? Number(e.target.value)
                          : null,
                      })
                    }
                    value={topCircuitHits}
                    label={
                      <FormattedMessage defaultMessage="Top circuit hits" />
                    }
                    helperText={
                      <FormattedMessage defaultMessage="Uploading an analysis fills in top circuit hits." />
                    }
                  />
                  <Stack spacing={3}>
                    {topCircuit && topCircuitPeriod && (
                      <Typography>
                        <FormattedMessage
                          defaultMessage="Fastest connection ({circuit}) hits every {period} seconds on average."
                          values={{
                            circuit: parseNodes(topCircuit).join(', '),
                            period: topCircuitPeriod.toFixed(1),
                          }}
                        />
                      </Typography>
                    )}
                    {!!(topStarburstCircuit && topStarburstPeriod) && (
                      <Typography>
                        <FormattedMessage
                          defaultMessage="Fastest starburst hits every {period} seconds on average, ranking #{ranking}, using {ratio}% of channels."
                          values={{
                            period: topStarburstPeriod.toFixed(1),
                            ranking: topStarburstRanking,
                            ratio:
                              sensors?.length &&
                              (
                                (100 *
                                  parseNodes(topStarburstCircuit)?.length) /
                                sensors?.length
                              ).toFixed(1),
                          }}
                        />
                      </Typography>
                    )}
                  </Stack>
                </>
              )}
            </Stack>
          </Grid>
          {areCircuitsUploaded && (
            <>
              <Grid item xs={6} sm={3}>
                <BrainCanvas
                  analysis={analysis}
                  display="heatmap"
                  sensors={sensors}
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <BrainCanvas
                  analysis={analysis}
                  display="wiring"
                  sensors={sensors}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <ExecutiveStyleAutocomplete
                  value={executiveStyle}
                  onChange={onExecutiveStyleChange}
                  textFieldProps={{
                    ...textFieldProps,
                    helperText: (
                      <FormattedMessage defaultMessage="Uploading an analysis fills in an executive style." />
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={0} md={6} />
              <Grid item xs={12}>
                <SensorsAutocomplete
                  value={topSkills}
                  onChange={(e, topSkills) => onChange({ topSkills })}
                  textFieldProps={{
                    ...textFieldProps,
                    helperText: (
                      <FormattedMessage
                        defaultMessage="Uploading an analysis fills in top skills."
                        description="SensorsAutocomplete helperText"
                      />
                    ),
                    label: (
                      <FormattedMessage
                        defaultMessage="Top skills ({count})"
                        description="SensorsAutocomplete label"
                        values={{ count: EEG_ANALYSIS_TOP_SKILLS_COUNT }}
                      />
                    ),
                    placeholder: intl.formatMessage({
                      defaultMessage: 'Skill',
                      description: 'SensorsAutocomplete placeholer',
                    }),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <WiringPatternsAutocomplete
                  value={topWiringPatterns}
                  onChange={(e, topWiringPatterns) =>
                    onChange({ topWiringPatterns })
                  }
                  textFieldProps={{
                    ...textFieldProps,
                    label: (
                      <FormattedMessage
                        defaultMessage="Top wiring patterns ({count})"
                        description="WiringPatternsAutocomplete label"
                        values={{
                          count: EEG_ANALYSIS_TOP_SKILLS_IN_THE_WORKPLACE_COUNT,
                        }}
                      />
                    ),
                  }}
                />
              </Grid>
            </>
          )}
        </Grid>
      </CardContent>
      <CardActions>
        <label>
          <Input multiple={false} type="file" onChange={handleFileChange} />
          <Button component="span">
            <FormattedMessage
              defaultMessage="Upload circuits"
              description="Card action"
            />
          </Button>
        </label>
        {areCircuitsUploaded && (
          <Button onClick={onDelete}>
            <FormattedMessage
              defaultMessage="Delete"
              description="Card action"
            />
          </Button>
        )}
      </CardActions>
    </Card>
  )
}

AnalysisFormSection.propTypes = {
  analysis: analysisShape,
  onChange: PropTypes.func,
  onDurationChange: PropTypes.func,
  onSensorsChange: PropTypes.func,
  sensors: sensorsShape,
}

export default AnalysisFormSection

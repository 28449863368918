import React from 'react'
import { useNavigate } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import LoadingButton from '@mui/lab/LoadingButton'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/styles'

import { useLoginMutation } from '@/features/auth/services/api'
import BodyContainer from '@/features/ui/components/BodyContainer'
import Helmet from '@/features/ui/components/Helmet'

function LoginView ({ history }) {
  const intl = useIntl()
  const theme = useTheme()
  const navigate = useNavigate()

  const [email, setEmail] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [errors, setErrors] = React.useState({})
  const [login, { isLoading }] = useLoginMutation()

  const handleSubmit = (e) => {
    e.preventDefault()

    return login({ email, password })
      .unwrap()
      .then(() => {
        setErrors({})
        return navigate('/account/brain-imaging/reports')
      })
      .catch(({ status, data }) => {
        const { code, error } = data || {}

        const errors = {}
        if (code === 'auth/wrong-password') errors.password = <FormattedMessage defaultMessage='Wrong password' />
        if (error === 'auth/user-not-found') errors.email = <FormattedMessage defaultMessage='Email not found' />
        if (error === 'auth/too-many-requests') errors.general = <FormattedMessage defaultMessage='Too many requests' />
        if (code === 'auth/internal-error' || error === 'auth/internal-error') errors.general = <FormattedMessage defaultMessage='Internal error' />

        setErrors(errors)
      })
  }

  const handleChange = (set) => (e) => { set(e.target.value) }

  const title = intl.formatMessage({ defaultMessage: 'Log In', description: 'Title for log in forms' })

  return (
    <React.Fragment>
      <Helmet
        title={title} />
      <BodyContainer maxWidth='xs'
        sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Avatar sx={{ m: 1, bgcolor: theme.palette.secondary.main }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component='h1' variant='h5'>
          {title}
        </Typography>
        <form noValidate>
          <TextField id='email' name='email' autoComplete='email' required
            fullWidth autoFocus variant='outlined' margin='normal'
            helperText={errors.email} error={!!errors.email} onChange={handleChange(setEmail)}
            label={<FormattedMessage defaultMessage='Email address' />} />
          <TextField id='password' name='password' type='password' autoComplete='current-password' required
            fullWidth variant='outlined' margin='normal'
            helperText={errors.password} error={!!errors.password} onChange={handleChange(setPassword)}
            label={<FormattedMessage defaultMessage='Password' />} />
          <LoadingButton type='submit' fullWidth variant='contained' color='primary' sx={{ mt: 3, mb: 2 }}
            onClick={handleSubmit} disabled={!email || !password} loading={isLoading}>
            <FormattedMessage defaultMessage='Sign in' />
          </LoadingButton>
          {/* Forgot password link */}
          <Box sx={{ my: 1 }}>
            <Link href='/forgot-password' variant='body2'>
              <FormattedMessage defaultMessage='Forgot password?' />
            </Link>
          </Box>
          {/* Sign up link */}
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant='body2'>
              <FormattedMessage defaultMessage="Don't have an account?" />
            </Typography>
            <Link href='/signup' variant='body2'>
              <FormattedMessage defaultMessage='Sign up' />
            </Link>
          </Box>
          {/* Errors */}
          {errors.general && (
            <Typography variant='body2' sx={{ color: 'red', fontSize: '0.8rem', mt: 1 }}>
              {errors.general}
            </Typography>
          )}
        </form>
      </BodyContainer>
    </React.Fragment>
  )
}

export default LoginView
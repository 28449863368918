import React from 'react'
import { IntlProvider } from 'react-intl'
import { useSelector } from 'react-redux'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import '@fontsource/poppins/300.css'
import '@fontsource/poppins/400.css'
import '@fontsource/poppins/500.css'
import '@fontsource/poppins/700.css'
import '@fontsource/poppins/800.css'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider, createTheme } from '@mui/material/styles'

import AccountProfileView from '@/features/account/components/AccountProfileView'
import AccountReportsView from '@/features/account/components/AccountReportsView'
import ForgotPasswordView from '@/features/auth/components/ForgotPasswordView'
import LoginView from '@/features/auth/components/LoginView'
import SessionManager from '@/features/auth/components/SessionManager'
import SignupView from '@/features/auth/components/SignupView' // TODO: Fix circular dependency when moving this import as first import
import CreateReportView from '@/features/eeg-sessions/components/CreateReportView'
import EditReportView from '@/features/eeg-sessions/components/EditReportView'
import PlaygroundView from '@/features/eeg-sessions/components/PlaygroundView'
import ReportView from '@/features/eeg-sessions/components/ReportView'
import { localeSelector, messagesSelector } from '@/features/intl/store/reducers/intl'
import BrainImagingLandingView from '@/features/landing/components/BrainImagingLandingView'
import BrainImagingReportExampleView from '@/features/landing/components/BrainImagingReportExampleView'
// import ConferencesLandingView from '@/features/landing/components/ConferencesLandingView'
import ContactUsView from '@/features/landing/components/ContactUsView'
import LandingView from '@/features/landing/components/LandingView'
import NotFoundView from '@/features/landing/components/NotFoundView'
import SeminarsLandingView from '@/features/landing/components/SeminarsLandingView'
// import TrainingAndCoachingLandingView from '@/features/landing/components/TrainingAndCoachingLandingView'
import WorkshopsAndEpicsLandingView from '@/features/landing/components/WorkshopsAndEpicsLandingView'
import CoreLayout from '@/features/ui/components/CoreLayout'
import Helmet from '@/features/ui/components/Helmet'
import { modeSelector } from '@/features/ui/store/reducers/themeMode'
// eslint-disable-next-line no-unused-vars
import { firebaseApp } from '@/services/firebase' // NOTE: Initialize firebase app
import { getDesignTokens } from '@/theme'

function App () {
  const mode = useSelector(modeSelector)

  // Update the theme only if the mode changes
  const theme = React.useMemo(() => createTheme(getDesignTokens(mode)), [mode])

  const locale = useSelector(localeSelector)
  const messages = useSelector(messagesSelector)

  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <CssBaseline>
          <IntlProvider locale={locale} messages={messages}>
            <Helmet />
            <BrowserRouter>
              <Routes>
                <Route path='*' element={<NotFoundView />} />
                <Route path='/' element={<CoreLayout withFooter withNavigation />}>
                  <Route index element={<LandingView />} />
                  <Route path='contact-us' element={<ContactUsView />} />
                  <Route path='playground' element={<PlaygroundView />} />
                  {/* Auth */}
                  <Route path='login' element={<LoginView />} />
                  <Route path='forgot-password' element={<ForgotPasswordView />} />
                  <Route path='signup' element={<SignupView />} />
                  {/* Services */}
                  <Route path='brain-imaging'>
                    <Route index element={<BrainImagingLandingView />} />
                    <Route path='meet-jessica' element={<BrainImagingReportExampleView />} />
                  </Route>
                  {/* <Route path='conferences' element={<ConferencesLandingView />} /> */}
                  <Route path='seminars' element={<SeminarsLandingView />} />
                  {/* <Route path='training-and-coaching' element={<TrainingAndCoachingLandingView />} /> */}
                  <Route path='workshops-and-epics' element={<WorkshopsAndEpicsLandingView />} />
                </Route>
                <Route path='account' element={<CoreLayout withFooter={false} withNavigation />}>
                  <Route path='brain-imaging/reports'>
                    <Route index element={<AccountReportsView />} />
                    <Route path='create' element={<CreateReportView />} />
                    <Route path=':sessionId' element={<ReportView />} />
                    <Route path=':sessionId/edit' element={<EditReportView />} />
                  </Route>
                  <Route path='profile' element={<AccountProfileView />} />
                </Route>
              </Routes>
              <SessionManager />
            </BrowserRouter>
          </IntlProvider>
        </CssBaseline>
      </ThemeProvider>
    </React.Fragment>
  )
}

export default App

import { defineMessages } from 'react-intl'

export const tabs = defineMessages({
  introduction: {
    defaultMessage: 'Introduction',
    description: 'This is a tab label',
  },
  engagement: {
    defaultMessage: 'Your engagement',
    description: 'This is a tab label',
  },
  dynamicsMap: {
    defaultMessage: 'Your dynamics',
    description: 'This is a tab label',
  },
  networks: {
    defaultMessage: 'Your networks',
    description: 'This is a tab label',
  },
  orientation: {
    defaultMessage: 'Your orientation',
    description: 'This is a tab label',
  },
  // executiveStyle: {
  //   defaultMessage: 'Executive style',
  //   description: 'This is a tab label',
  // },
  topSkills: {
    defaultMessage: 'Top skills',
    description: 'This is a tab label',
  },
})

export const messages = {
  tabs,
}

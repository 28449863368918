import React from 'react'
import { FormattedMessage } from 'react-intl'

import userDetails from './userDetails'
import Report from '@/features/eeg-sessions/components/Report'
import { useGetEEGSessionQuery } from '@/features/eeg-sessions/services/api'
import { br } from '@/features/intl/utils/intl'
import Header from '@/features/ui/components/Header'

function BrainImagingReportExample () {
  const sessionId = 'MH3vOomUVCXlNPhZVJg8' // NOTE: Morgane Mary
  const { data: session, isLoading } = useGetEEGSessionQuery(sessionId)
  
  return (
    <React.Fragment>
      <Header id='introduction' component='h1' variant='h1'
        title={<FormattedMessage defaultMessage="{firstName}'s brain-savvy{br}personality & skills{br}profile" description='Header title' values={{
          br,
          firstName: userDetails.firstName,
        }} />} />
      <Report hideActivities isLoading={isLoading} session={session} userDetails={userDetails} />
    </React.Fragment>
  )
}

export default BrainImagingReportExample

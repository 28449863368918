import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector, useDispatch } from 'react-redux'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Stack from '@mui/material/Stack'

import SensorDescription from '@/features/eeg-sessions/components/SensorDescription'
import { circuitSelector, openSelector, setOpen } from '@/features/eeg-sessions/store/reducers/circuitDialog'
import { isDiamond, isStarburst } from '@/features/eeg-sessions/components/BrainCanvas/utils'
import { parseNodes } from '@/features/eeg-sessions/utils/analyses'

function CircuitDialog() {
  const circuit = useSelector(circuitSelector)
  const open = useSelector(openSelector)

  const dispatch = useDispatch()
  const handleClose = () => { dispatch(setOpen(false)) }

  const descriptionElementRef = React.useRef(null)
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef
      if (descriptionElement !== null) {
        descriptionElement.focus()
      }
    }
  }, [open])

  let title
  const hasDiamond = isDiamond(circuit)
  const hasStarburst = isStarburst(circuit)
  if (hasDiamond) title = <FormattedMessage defaultMessage='This is a diamond!' />
  else if (hasStarburst) title = <FormattedMessage defaultMessage='This is a starburst!' />
  else if (open) title = <FormattedMessage defaultMessage='Circuit between {nodes}' values={{ nodes: circuit.nodes.split(',').join(', ') }} />

  return (
    <Dialog open={open} onClose={handleClose} scroll='paper'
      aria-labelledby="circuit-info-dialog-title"
      aria-describedby="circuit-info-dialog-description">
      <DialogTitle id="circuit-info-dialog-title">
        {open && (
          <Container disableGutters sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            {title}
            <span>
              <FormattedMessage defaultMessage='{score}%' values={{ score: circuit.score }} />
            </span>
          </Container>
        )}
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText id="circuit-info-dialog-description"
          ref={descriptionElementRef} tabIndex={-1}>
          <Stack spacing={3}>
            {hasDiamond && (
              <div>
                <FormattedMessage defaultMessage='This diamond represents a connection between the {count} following regions: {nodes}.' values={{
                  count: parseNodes(circuit).length,
                  nodes: circuit.nodes.split(',').join(', '),
                }} />
              </div>
            )}
            {hasStarburst && (
              <div>
                <FormattedMessage defaultMessage='This starburst represents a connection between the {count} following regions: {nodes}.' values={{
                  count: parseNodes(circuit).length,
                  nodes: circuit.nodes.split(',').join(', '),
                }} />
              </div>
            )}
            {circuit && parseNodes(circuit).map((id) => (
              <SensorDescription key={id} id={id} withTitle />
            ))}
          </Stack>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>
          <FormattedMessage defaultMessage='Sweet' />
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CircuitDialog

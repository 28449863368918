import React from 'react'
import PropTypes from 'prop-types'
import BodyContainer from '@/features/ui/components/BodyContainer'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'

import DynamicsMapSection from './DynamicsMapSection'
import EngagementSection from './EngagementSection'
import IntroductionSection from './IntroductionSection'
import OrientationSection from './OrientationSection'
import TopSkillsSection from './TopSkillsSection'
import TopWiringPatternsSection from './TopWiringPatternsSection'
import AnalysisVariantsSelect from '@/features/eeg-sessions/components/AnalysisVariantsSelect'
import CircuitDialog from '@/features/eeg-sessions/components/CircuitDialog'
import SensorDialog from '@/features/eeg-sessions/components/SensorDialog'
import WiringPatternDialog from '@/features/eeg-sessions/components/WiringPatternDialog'
import { sessionShape } from '@/features/eeg-sessions/store/propTypes'
import { EEG_ANALYSIS_VARIANT_MIXED } from '@/features/eeg-sessions/store/propTypes/analyses'
import { getAnalysis, getAnalysesVariants } from '@/features/eeg-sessions/utils/analyses'
import { userDetailsShape } from '@/features/users/store/propTypes'
import { noPrint } from '@/utils/print'

function Report ({ hideActivities, isLoading, session, userDetails }) {
  // Analysis variant
  const analysisVariants = getAnalysesVariants(session)
  const [analysisVariant, setAnalysisVariant] = React.useState(EEG_ANALYSIS_VARIANT_MIXED)
  const handleAnalysisVariantChange = (e) => { setAnalysisVariant(e.target.value) }

  // Analysis and sensors
  const { analyses, sensors, title } = session || {}
  const analysis = getAnalysis(session, analysisVariant)

  return (
    <React.Fragment>
      <BodyContainer maxWidth="md" sx={{ whiteSpace: 'pre-line' }}>
        {isLoading ? (
          <React.Fragment>
            <Skeleton variant="text" height={140} />
            <Skeleton variant="text" height={300} />
            <Skeleton variant="text" height={120} />
          </React.Fragment>
        ) : (
          <React.Fragment>
            {/* Controllers */}
            <Stack
              direction="row"
              spacing={2}
              justifyContent="flex-end"
              sx={{ ...noPrint }}
            >
              {/* TODO: <FormControlLabel control={<Switch checked={short} onChange={handleReportVariantChange} />}
                label={<FormattedMessage defaultMessage='Short' />} />*/}
              {analysisVariants.length > 1 && (
                <AnalysisVariantsSelect
                  id="analysis-variant-select"
                  variant="outlined"
                  sx={{ minWidth: 240, mb: 10 }}
                  onChange={handleAnalysisVariantChange}
                  value={analysisVariant}
                  options={analysisVariants}
                />
              )}
            </Stack>
            {/* Report sections*/}
            <IntroductionSection title={title} userDetails={userDetails} />
            <EngagementSection
              hideActivities={hideActivities}
              session={session}
            />
            <DynamicsMapSection
              analyses={analyses}
              analysis={analysis}
              duration={session?.duration}
              sensors={sensors}
            />
            <TopWiringPatternsSection analysis={analysis} />
            <OrientationSection analysis={analysis} sensors={sensors} />
            <TopSkillsSection analysis={analysis} />
            {/* Dialogs */}
            <WiringPatternDialog />
            <SensorDialog />
            <CircuitDialog />
          </React.Fragment>
        )}
      </BodyContainer>
    </React.Fragment>
  )
}

Report.propTypes = {
  hideActivities: PropTypes.bool,
  isLoading: PropTypes.bool,
  session: sessionShape,
  userDetails: userDetailsShape,
}

export default Report

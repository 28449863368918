import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  display: 'wiring',
  filename: undefined,
  session: undefined,
}

export const playgroundSettingsSlice = createSlice({
  name: 'playgroundSettings',
  initialState,
  reducers: {
    setDisplay: (state, action) => ({
      ...state,
      display: action.payload,
    }),
    setSession: (state, action) => ({
      ...state,
      session: action.payload.session,
      filename: action.payload.filename,
    }),
  },
})

export const { setDisplay, setHeadset, setSession } = playgroundSettingsSlice.actions

export const displaySelector = ((state) => state.eegSessions.playgroundSettings.display)
export const filenameSelector = ((state) => state.eegSessions.playgroundSettings.filename)
export const sessionSelector = ((state) => state.eegSessions.playgroundSettings.session)

export default playgroundSettingsSlice.reducer

import PropTypes from 'prop-types'

export const SENSOR_IDS = [
  'Fp1', 'Fpz', 'Fp2',
  'F7', 'F3', 'Fz', 'F4', 'F8',
  'FT9', 'FC5', 'FC1', 'FC2', 'FC6', 'FTX',
  'T3', 'C3', 'Cz', 'C4', 'T4',
  'CP5', 'CP1', 'CP2', 'CP6',
  'T5' /* P7 */, 'P3', 'Pz', 'P4', 'T6' /* P8 */,
  'PO7', 'O1', 'O2', 'PO8',
]

export const sensorIdShape = PropTypes.oneOf(SENSOR_IDS)

export const sensorsShape = PropTypes.arrayOf(sensorIdShape)

export const sensorsCoordinatesShape = PropTypes.objectOf(PropTypes.shape({
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
}).isRequired)

export const sensorsPolygonsShape = PropTypes.objectOf(PropTypes.shape({
  polygon: PropTypes.arrayOf(PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
  }).isRequired).isRequired,
}).isRequired)

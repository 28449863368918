import sortBy from 'lodash/sortBy'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { Bar, BarChart, XAxis, YAxis } from 'recharts'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'

import { sessionShape } from '@/features/eeg-sessions/store/propTypes'
import Heading from '@/features/ui/components/Heading'
import { modeSelector } from '@/features/ui/store/reducers/themeMode'
import { useElementSize } from '@/utils/hooks'
import { pagebreak } from '@/utils/print'
import { timeToSeconds } from '@/utils/times'

const CHART_HEIGHT = 800
const XAXIS_HEIGHT = 30
const CHART_MARGIN = { top: 0, right: 0, bottom: 0, left: 0 }
const SAMPLE_DOWN_TO_AROUND = 500

export const ActivitiesBox = styled(Box)({
  position: 'absolute',
  top: XAXIS_HEIGHT,
  bottom: 0,
  left: 0,
  right: 0,
})


// TODO: Match backgroundColor with theme
export const Activity = styled(Box)(({ activity, duration, index, mode }) => {
  const height = CHART_HEIGHT - XAXIS_HEIGHT
  const from = timeToSeconds(activity.from)
  const to = timeToSeconds(activity.to)
  const bgColor = mode === 'dark' ? '255, 255, 255' : '0, 0, 0'
  let opacity = 0.1
  if (index % 2) opacity = opacity * 2

  return {
    position: 'absolute',
    top: height * (from / duration),
    height: height * ((to - from) / duration),
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    backgroundColor: `rgba(${bgColor}, ${opacity})`,
  }
})

function EngagementSection ({ hideActivities, session }) {
  const intl = useIntl()
  const mode = useSelector(modeSelector)
  const [data, setData] = React.useState(null)
  const [chartContainerRef, { width: chartWidth }] = useElementSize()

  React.useEffect(() => {
    const count = session?.amplitudes?.length

    const data = []
    const sampleDownBy = Math.round(count / SAMPLE_DOWN_TO_AROUND)
    for (let i = 0; i < count; i++) {
      if (i % sampleDownBy) continue

      data.push({
        amplitude: session.amplitudes[i],
        time: i * (session.duration / (count - 1)),
      })
    }

    setData(data)
  }, [session])

  if (!session?.amplitudes) return null
  
  const hideYAxis = session?.activities?.length

  return (
    <React.Fragment>
      <Heading id='engagement' component='h2' variant='h2' gutterTop gutterBottom style={pagebreak}
        title={<FormattedMessage defaultMessage='Your engagement record' />} />
      <FormattedMessage defaultMessage="The chart shows how active your brain was (in microvolts). Longer bars indicate
        more activity when interested, challenged, and energized. Shorter bars indicate boredom, tiredness or
        relaxation. The chart bars do not indicate skill, just voltage. Voltage varies a bit with time of day and
        such. High peaks indicate times of problem solving, change, and perhaps insight." />
      {!session?.activities?.length ? null : (
        <React.Fragment>
          <br/>
          <br/>
          <FormattedMessage defaultMessage='Please take a moment to notice how you started, worked, and completed each task. Usually people show very low activity when
            meditating or listening to music.' />
        </React.Fragment>
      )}
      <Box ref={chartContainerRef} sx={{ position: 'relative', mt: 5 }}>
        <BarChart layout='vertical' width={chartWidth} height={CHART_HEIGHT} data={data}
          margin={CHART_MARGIN} style={{ zIndex: 1 }}>
          <XAxis type='number' orientation='top' unit={intl.formatMessage({ defaultMessage: 'µV', description: 'unit' })} />
          <YAxis hide={hideYAxis} dataKey="time"
            domain={[0, 'dataMax']}
            type='number' unit={intl.formatMessage({ defaultMessage: 's', description: 'unit' })} />
          <Bar layout='vertical' dataKey='amplitude' 
            name={<FormattedMessage defaultMessage='Amplitudes' />}                
            fill='#0091ea' />
        </BarChart>
        {(!session?.activities?.length || !session?.duration) ? null : (
          <ActivitiesBox>
             {sortBy(session.activities, (o) => o.from).map((activity, i) => (
                <Activity key={i} activity={activity} duration={session.duration} index={i} mode={mode} sx={{ px: 2 }}>
                  <Typography variant='body2'>
                    {hideActivities
                      ? <FormattedMessage defaultMessage='#{id} Secret activity' values={{ id: i + 1 }} />
                      : activity.title}
                  </Typography>
                </Activity>
             ))}
          </ActivitiesBox>
        )}
      </Box>
    </React.Fragment>
  )
}

EngagementSection.propTypes = {
  session: sessionShape,
}

export default EngagementSection
import PropTypes from 'prop-types'
import React from 'react'
import { Outlet, useLocation } from 'react-router-dom'

import Footer from '@/features/ui/components/Footer'
import NavigationBar from '@/features/ui/components/NavigationBar'

function CoreLayout ({ withFooter, withNavigation }) {
  const location = useLocation()

  // Scroll to top on page transitions
  React.useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0)
  }, [location.pathname])

  return (
    <React.Fragment>
      {!withNavigation ? null : <NavigationBar />}
      <Outlet />
      {!withFooter ? null : <Footer />}
    </React.Fragment>
  )
}

CoreLayout.propTypes = {
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  withBodyContainer: PropTypes.bool.isRequired,
  withFooter: PropTypes.bool,
  withNavigation: PropTypes.bool.isRequired,
}

CoreLayout.defaultProps = {
  withBodyContainer: true,
  withNavigation: true,
}

export default CoreLayout

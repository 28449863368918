import { createSlice } from '@reduxjs/toolkit'

import messages_en from '@/lang/compile/en.json'
import messages_fr from '@/lang/compile/fr.json'
import messages_ja from '@/lang/compile/ja.json'

function getMessages (locale) {
  switch (locale) {
    case 'fr':
      return messages_fr
    case 'ja':
      return messages_ja
    case 'en':
    default:
      return messages_en
  }
}

const initialLocale = localStorage.getItem('Locale') || navigator.language.split(/[-_]/)[0]

// WNG: Messages should be imported as locale gets initialized to prevent loading all messages
const initialState = {
  locale: initialLocale,
  messages: getMessages(initialLocale),
}

export const intlSlice = createSlice({
  name: 'intl',
  initialState,
  reducers: {
    setLocale: (state, action) => {
      const { locale } = action.payload

      // TODO: Move this out of this reducer
      localStorage.setItem('Locale', locale)

      return { ...state, locale, messages: getMessages(locale) }
    },
  }
})

export const { setLocale } = intlSlice.actions

export const localeSelector = ((state) => state.intl.intl.locale)
export const messagesSelector = ((state) => state.intl.intl.messages)

export default intlSlice.reducer

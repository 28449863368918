import { coreApi } from '@/services/api'

export const landingApi = coreApi
  .injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
      sendContactForm: builder.mutation({
        query: ({ firstName, lastName, email, phoneNumber, comment }) => ({
          url: 'contacts/emails',
          method: 'POST',
          body: { firstName, lastName, email, phoneNumber, comment },
        }),
      }),
    }),
  })

export const { useSendContactFormMutation } = landingApi

import { coreApi } from '@/services/api'

export const authApi = coreApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    login: builder.mutation({
      query: ({ email, password }) => ({
        url: 'auth/login',
        method: 'POST',
        body: { email, password },
      }),
    }),
    refreshSession: builder.query({
      query: ({ refreshToken }) => ({
        url: 'auth/refresh',
        method: 'POST',
        body: { refreshToken },
      }),
    }),
    sendPasswordResetLink: builder.mutation({
      query: ({ email }) => ({
        url: 'auth/send-password-reset-link',
        method: 'POST',
        body: { email },
      }),
    }),
    signup: builder.mutation({
      query: ({ email, password, firstName, lastName, phoneNumber, country }) => ({
        url: 'auth/signup',
        method: 'POST',
        body: { email, password, firstName, lastName, phoneNumber, country },
      }),
    }),
  }),
})

export const { 
  useLoginMutation, 
  useRefreshSessionQuery, 
  useSendPasswordResetLinkMutation,
  useSignupMutation,
} = authApi

import isEmpty from 'lodash/isEmpty'

import { computeAmplitudesAndFrequencies, computeAnalyses, computeNormalizedScores } from '@/features/eeg-sessions/utils/analyses'
import { SENSOR_IDS, getSensorsFromAnalysis, toRadianceSensorId } from '@/features/eeg-sessions/utils/sensors'

/**
 * Build session from EDF data
 * 
 * @param {!EDF} edf
 * @return {EEGSession}
 */
export function buildSessionFromEdf (edf) {
  const channels = prepareEdfChannels(edf)
  if (!channels.length) return

  const { amplitudes, frequencies } = computeAmplitudesAndFrequencies(channels)
  const sensors = channels.map((channel) => channel.id)
  
  return {
    analyses: computeAnalyses(channels, sensors),
    amplitudes,
    frequencies,
    doneAt: edf.startdatetime?.toISOString(),
    duration: edf.duration, // In seconds
    samplingRate: channels[0].samplingRate,
    sensors,
    title: edf.rid,
  }
}

/** List of prefixes to remove for retrieving meaningful channel ids. */
export const LABEL_PREFIXES_TO_INCLUDE = ['EEG '];

/** List of keywords to exclude to select meaningful channel ids. */
export const LABEL_KEYWORDS_TO_EXCLUDE = ['EQ', 'CQ'];

/** Retrieve channel ID (brain region) from EDF channel label. Throws on errors. */
export function retrieveChannelIdFromLabel(label) {
  if (!label) throw new Error('Expected a channel label to be available.');

  // Use uppercase to compare to BrainRegionEnum.
  let id = label.toUpperCase()

  // Skip labels including no known brain region.
  if (!SENSOR_IDS.some((region) => id.includes(region.toUpperCase()))) {
    console.debug(
      `Skipped channel label "${label}" with no known brain region.`
    )

    return null
  }

  // Skip labels with excluded prefixes.
  const includesSome = LABEL_KEYWORDS_TO_EXCLUDE.some((keyword) => {
    const includes = id.includes(keyword)
    if (includes)
      console.debug(
        `Skipped channel label "${label}" with "${keyword}" excluded keyword.`
      )

    return includes
  })

  if (includesSome) return null

  // Remove potential included prefixes.
  // NOTE: Some headsets use the following labels: "EEG FP1-LE".
  for (const prefix of LABEL_PREFIXES_TO_INCLUDE) {
    if (id.startsWith(prefix)) id = id.slice(prefix.length)
  }

  // Remove channel relation to another channel.
  // NOTE: Some headsets use the following labels: "FP1-A1".
  if (id.includes('-')) id = id.split('-')[0]

  // NOTE: This should handle extra Emotiv channels for "EQ_" and ""
  if (!SENSOR_IDS.map((id) => id.toUpperCase()).includes(id))
    throw new Error(`Could not retrieve brain region from label "${label}".`)

  // Handle case.
  id = id.replace('FP', 'Fp').replace('Z', 'z')

  // Skip labels including no known brain region.
  if (!SENSOR_IDS.some((region) => id.includes(region)))
    throw new Error(`Failed to retrieve id "${id}" from label ${label}.`)

  console.debug(`Retrieved id ${id} from label ${label}.`)

  return id;
}


/**
 * Prepare channels and ensure expected EDF data format
 * 
 * @param {!EDF} edf
 * @return {Array.<EdfChannel>|undefined} - channels
 */
export function prepareEdfChannels (edf) {
  const channels = edf.channels
    .map((channel) => ({
      amplitudes: channel.blob,
      id: toRadianceSensorId(retrieveChannelIdFromLabel(channel.label)),
      samplingRate: channel.sampling_rate,
      unit: channel.physical_dimension,
    }))
    .filter(({ id }) => !!id)

  if (!channels.length) {
    console.error(`Could not identify channels in EDF.`, edf.channels.map((channel) => channel.label))
    return
  }
  
  // Make sure EDF has a single sampling rate
  const samplingRate = channels[0]?.samplingRate
  
  for (const channel of channels) {
    if (channel.samplingRate !== samplingRate) {
      console.error('Unexpected data format in EDF with multiple sampling rates.')
      return
    }
  }

  // Make sure all channels are in microvolts
  for (const channel of channels) {
    if (channel.unit !== 'uV')  {
      console.error(`Unexpected unit in EDF for channel ${channel.id}: ${channel.unit}.`)
      return
    }
  }

  return channels
}

/**
 * Parse analysis from a formatted text file
 *
 * @param {!String} filename
 * @param {!String} content
 * @return {EEGAnalysis}
 */
export function parseCircuitsTextFile (filename, content) {
  if (!filename.includes('.txt')) return

  const lines = content?.trim().split('\n')
  if (!lines?.length) return

  // Parse duration
  let duration = lines.filter((o) => o.includes('Duration'))[0]
  duration = Number.parseInt(duration?.match(/([0-9]+) seconds/)[1]) + Number.parseInt(duration?.match(/([0-9]+) minutes/)[1]) * 60 // seconds

  // Parse brain speed
  let topCircuitHits = lines.filter((o) => o.includes('Highest score'))[0]?.match(/= ([0-9]+) hits/)
  if (!topCircuitHits) return
  topCircuitHits = Number.parseInt(topCircuitHits[1])

  // Parse circuit scores
  const circuits = lines.filter((o) => o.includes('\t') && o.includes(',')).map((row) => {
    const [ score, nodes ] = row.split('\t')
    return { nodes: nodes.trim(), score: parseInt(score.trim()) }
  })

  // Parse normalized scores
  let normalizedScores = lines.filter((o) => o.includes('\t') && !(o.includes(',') || o.includes('Score'))).reduce((prev, txt) => {
    const [ id, score, ...rest ] = txt.split('\t') // eslint-disable-line no-unused-vars
    return { ...prev, [id]: parseInt(score) }
  }, {})

  if (isEmpty(normalizedScores) && circuits?.length) normalizedScores = computeNormalizedScores({ circuits })
  
  const analysis = { circuits, normalizedScores, topCircuitHits}

  // Get sensors from normalized scores if available
  let sensors = getSensorsFromAnalysis(analysis)

  return {
    analyses: [analysis],
    duration,
    sensors,
  }
}

/**
 * Parse frequencies and amplitudes from a formatted text file
 *
 * @param {!String} filename
 * @param {!String} content
 * @return {Object}
 */
 export function parseFreqAndAmplitudesTextFile (filename, content) {
  if (!filename.includes('.txt')) return
  
  // Remove first header row
  const lines = content?.trim().split('\n').slice(1)
  if (!lines?.length) return
  
  // Parse each row: "freq\tamplitude"
  const frequencies = []
  const amplitudes = []

  for (const line of lines) {
    const row = line.split('\t')
    const freq = parseFloat(row[0].trim())
    const amp = parseFloat(row[1].trim())
    
    // NOTE: Last line could have max frequency and a null amplitude
    if (!amp) continue

    frequencies.push(freq)
    amplitudes.push(amp)
  }



  return {
    amplitudes,
    frequencies,
  }
}

import React from 'react'
import { FormattedMessage } from 'react-intl'
import Button from '@mui/material/Button'

import { br } from '@/features/intl/utils/intl'
import PriceTable from '@/features/ui/components/PriceTable'
import PriceTableBenefits from '@/features/ui/components/PriceTableBenefits'
import PriceTableDescription from '@/features/ui/components/PriceTableDescription'
import PriceTableImage from '@/features/ui/components/PriceTableImage'
import PriceTablePrice from '@/features/ui/components/PriceTablePrice'
import PriceTableTitle from '@/features/ui/components/PriceTableTitle'
import { getMediaPictureSrc } from '@/utils/mediaStorage'

function BrainImagingPriceTable () {
  return (
    <PriceTable container spacing={1}>
      {/* Solo explorers */}
      <PriceTable item xs={12} md={4} highlight>
        <PriceTableTitle>
          <FormattedMessage defaultMessage='Solo{br}explorer' description='PriceTable title' values={{ br }} />
        </PriceTableTitle>
        <PriceTablePrice price={250} discount={125}
          pricePer={<FormattedMessage defaultMessage='per session' />} />
        <PriceTableImage
          src={getMediaPictureSrc('/misc/brain-imaging/solo-explorer-768x768.png?alt=media&token=6aa8703b-7ed0-405e-be2a-a214bafa514a')} />
        <PriceTableBenefits sx={{ flexGrow: 1 }}>
          <FormattedMessage defaultMessage='1 brain imaging session (1 hour)' />
          <FormattedMessage defaultMessage='1 web report' />
          <FormattedMessage defaultMessage='1 introduction to brain basics' />
          <FormattedMessage defaultMessage='1 crazy selfie 😉' />
        </PriceTableBenefits>
        <PriceTableDescription>
          <FormattedMessage defaultMessage='Ready to explore what makes you, you? Step ahead and get unique insights about your brain activity, shaped by years of experiences and habits.' />
        </PriceTableDescription>
        <Button href='/contact-us' variant='outlined'>
          <FormattedMessage defaultMessage='Contact us' description='Button label' />
        </Button>
      </PriceTable>
      {/* Exploring squad */}
      <PriceTable item xs={12} md={4} highlight>
        <PriceTableTitle>
          <FormattedMessage defaultMessage='Exploring{br}squad' description='PriceTable title' values={{ br }} />
        </PriceTableTitle>
        <PriceTablePrice price={200} discount={100}
          pricePer={<FormattedMessage defaultMessage='per person' />} />
        <PriceTableImage
          src={getMediaPictureSrc('/misc/brain-imaging/exploring-squads-768x768.png?alt=media&token=f4a56039-c820-4e55-b2db-10b205b3f4f4')} />
        <PriceTableBenefits sx={{ flexGrow: 1 }}>
          <FormattedMessage defaultMessage='1 brain imaging session (1 hour)' />
          <FormattedMessage defaultMessage='1 web report' />
          <FormattedMessage defaultMessage='1 introduction to brain basics' />
          <FormattedMessage defaultMessage='1 team brain (optional)' />
          <FormattedMessage defaultMessage='1 crazy selfie 😉' />
        </PriceTableBenefits>
        <PriceTableDescription>
          <FormattedMessage defaultMessage='For teams, couples or even friends. Ideal to explore differences together. What are you common grounds? Where do you complement each other?' />
        </PriceTableDescription>
        <Button href='/contact-us' variant='outlined'>
          <FormattedMessage defaultMessage='Contact us' description='Button label' />
        </Button>
      </PriceTable>
      {/* Pioneering company */}
      <PriceTable item xs={12} md={4} highlight>
        <PriceTableTitle>
          <FormattedMessage defaultMessage='Pioneering{br}company' description='PriceTable title' values={{ br }} />
        </PriceTableTitle>
        <PriceTablePrice price={500}
          pricePer={<FormattedMessage defaultMessage='per person' />} />
        <PriceTableImage
          src={getMediaPictureSrc('/misc/brain-imaging/pioneering-company-768x768.png?alt=media&token=adae366a-972a-4316-a746-c43d1f5b3c49')} />
        <PriceTableBenefits sx={{ flexGrow: 1 }}>
          <FormattedMessage defaultMessage='1 session (1 hour) with various tasks to highlight your default mode' />
          <FormattedMessage defaultMessage='2 sessions (30 min) focusing on specific activities of your choice' />
          <FormattedMessage defaultMessage='3 web reports' />
          <FormattedMessage defaultMessage='1 introduction to brain basics' />
          <FormattedMessage defaultMessage='1 team brain (included)' />
          <FormattedMessage defaultMessage='1 crazy selfie 😉' />
        </PriceTableBenefits>
        <PriceTableDescription>
          <FormattedMessage defaultMessage='What makes you tick in your default mode? How far do you stretch from it in your daily activities? How do you compare to your peers?' />
        </PriceTableDescription>
        <Button href='/contact-us' variant='outlined'>
          <FormattedMessage defaultMessage='Contact us' description='Button label' />
        </Button>
      </PriceTable>
    </PriceTable>
  )
}

export default BrainImagingPriceTable

import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'

import { setLocale, localeSelector } from '@/features/intl/store/reducers/intl'

const values = {
  en: 'English',
  fr: 'Français',
  ja: '日本語',
}

function LocaleSelect () {
  const locale = useSelector(localeSelector)
  const dispatch = useDispatch()

  const handleChange = (event) => dispatch(setLocale({ locale: event.target.value }))

  return (
    <FormControl>
      <InputLabel id='locale-select'>
        <FormattedMessage defaultMessage='Language' />
      </InputLabel>
      <Select id='demo-simple-select' labelId='locale-select' autoWidth
        value={locale} label={values[locale]}
        onChange={handleChange}>
        {Object.keys(values).map((v) => (
          <MenuItem key={v} value={v}>{values[v]}</MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default LocaleSelect

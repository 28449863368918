import React from 'react'
import { FormattedMessage } from 'react-intl'
import Button from '@mui/material/Button'

function Brand (props) {
  return (
    <Button href='/' color='inherit' {...props}>
      <FormattedMessage defaultMessage='Puzzlin.org' description='NavigationBar button label' />
    </Button>
  )
}

export default Brand

import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Bar, BarChart, Legend, XAxis, YAxis } from 'recharts'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'

import { parseFreqAndAmplitudesTextFile } from '@/features/eeg-sessions/utils/sessions'
import { onFileChange } from '@/utils/files'
import { useElementSize } from '@/utils/hooks'

const Input = styled('input')({ display: 'none' })

const HEIGHT = 200
const MARGIN = { top: 0, right: 0, bottom: 0, left: 0 }
const SAMPLE_DOWN_TO_AROUND = 300

const ChartsFormSection = ({ duration, amplitudesAndFreq, setAmplitudesAndFreq }) => {
  const intl = useIntl()
  const [data, setData] = React.useState(null)
  const [chartContainerRef, { width }] = useElementSize()

  // Load amplitudes and frequencies from text file
  const handleFileChange = onFileChange((file) => (event) => {
    if (!file) return

    const { frequencies, amplitudes } = parseFreqAndAmplitudesTextFile(file.name, event.target.result) || {}
    if (!frequencies || !amplitudes) return

    setAmplitudesAndFreq({ amplitudes, frequencies })
  })

  // Format charts data when data changes
  React.useEffect(() => {
    if (!duration) {
      setData(null)
      return
    }

    const count = amplitudesAndFreq?.frequencies?.length

    const data = []
    const sampleDownBy = Math.round(count / SAMPLE_DOWN_TO_AROUND)
    for (let i = 0; i < count; i++) {
      if (i % sampleDownBy) continue

      data.push({
        amplitude: amplitudesAndFreq.amplitudes[i],
        frequency: amplitudesAndFreq.frequencies[i],
        time: i * (duration / (count - 1)),
      })
    }

    setData(data)
  }, [amplitudesAndFreq, duration])

  const handleRemoveAmplitudesAndFreq = (e) => {
    setAmplitudesAndFreq({ amplitudes: [], frequencies: [] })
  }

  const secondsUnit = intl.formatMessage({ defaultMessage: 's', description: 'unit' })
  
  const canUploadCharts = !!duration
  const hasCharts = !!data?.length

  return (
    <Card variant='outlined'>
        <CardContent>
          <Typography component='div' variant='body1'>
            <FormattedMessage defaultMessage='You must upload circuits before you can upload charts.' />
          </Typography>
          {hasCharts && (
            <Grid container spacing={1}>
              <Grid item xs={12} md={6} ref={chartContainerRef}>
                <BarChart width={width} height={HEIGHT} data={data} margin={MARGIN}>
                  <XAxis dataKey="time" type='number' unit={secondsUnit} />
                  <YAxis scale='sqrt' unit={intl.formatMessage({ defaultMessage: 'µV', description: 'unit' })} />
                  <Legend />
                  <Bar dataKey='amplitude'
                    name={<FormattedMessage defaultMessage='Amplitudes' />}                
                    fill='#0091ea' />
                </BarChart>
              </Grid>
              <Grid item xs={12} md={6}>
                <BarChart width={width} height={HEIGHT} data={data} margin={MARGIN}>
                  <XAxis dataKey="time" type='number' unit={secondsUnit} />
                  <YAxis scale='sqrt' unit={intl.formatMessage({ defaultMessage: 'Hz', description: 'unit' })} />
                  <Legend />
                  <Bar dataKey='frequency'
                    name={<FormattedMessage defaultMessage='Frequencies' />}                
                    fill='#d50000' />
                </BarChart>
              </Grid>
            </Grid>
          )}
        </CardContent>
      <CardActions>
      <label htmlFor={`upload-charts-button`}>
          <Input id={`upload-charts-button`} disabled={!canUploadCharts} multiple={false} type='file' onChange={handleFileChange} />
          <Button component='span' disabled={!canUploadCharts}>
            <FormattedMessage defaultMessage='Upload charts' description='Card action' />
          </Button>
        </label>
        {hasCharts && (
          <Button onClick={handleRemoveAmplitudesAndFreq}>
            <FormattedMessage defaultMessage='Delete' description='Card action' />
          </Button>
        )}
      </CardActions>
    </Card>
  )
}

export default ChartsFormSection
import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import BrainCanvas from '@/features/eeg-sessions/components/BrainCanvas'
import { analysisShape } from '@/features/eeg-sessions/store/propTypes'
import { SENSOR_IDS } from '@/features/eeg-sessions/store/propTypes/sensors'
import { setOpen } from '@/features/eeg-sessions/store/reducers/wiringPatternDialog'

function WiringPattern ({ analysis, disableDialogs, id }) {
  const dispatch = useDispatch()

  const cursor = { cursor: 'pointer' }

  const handleClick = disableDialogs ? undefined : (e) => {
    e.stopPropagation()
    dispatch(setOpen(id))
  }

  return (
    // NOTE: Pass all sensors since data is defined for that
    <BrainCanvas analysis={analysis} display='wiring'
      disableScoreDialogs disableSensorDialogs
      onClick={handleClick} sensors={SENSOR_IDS} style={cursor} />
  )
}

WiringPattern.propTypes = {
  analysis: analysisShape.isRequired,
  disableDialogs: PropTypes.bool,
  id: PropTypes.string.isRequired,
}

export default WiringPattern

import { isLoggedInSelector, logout, refreshTokenSelector } from '@/features/auth/store/reducers/tokens'
import { authApi } from '@/features/auth/services/api'

export function withReauth (baseQuery) {
  return async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions)

    if (result.error && result.error.status === 401) {
      // Try refreshing tokens
      const refreshResult = await api
        .dispatch(authApi.endpoints.refreshSession.initiate({
          refreshToken: refreshTokenSelector(api.getState()),
        }, {
          subscriptionOptions: {
            pollingInterval: 45 * 60 * 1000, // NOTE: Refresh user session after 45 minutes
          },
        }))

      if (refreshResult.data) {
        // NOTE: Reducers catch results automatically to update the store

        // Retry the initial request
        result = await baseQuery(args, api, extraOptions)
      } else {
        if (isLoggedInSelector(api.getState())) api.dispatch(logout())

        // TODO: Push snack: Your must be logged in to access this content?
      }
    } else if (result.error && result.error.status === 403) {
      // TODO: Handle missing user rights with a Snack?
    }

    return result
  }
}

export default withReauth

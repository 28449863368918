import React from 'react'
import { useIntl } from 'react-intl'

import ReportTabs from '@/features/eeg-sessions/components/ReportTabs'
import BrainImagingReportExample from '@/features/landing/components/BrainImagingReportExample'
import Helmet from '@/features/ui/components/Helmet'

function BrainImagingReportExampleView () {
  const intl = useIntl()
  
  return (
    <React.Fragment>
      <Helmet
        title={intl.formatMessage({ defaultMessage: "Jessica's brain-savvy personality & skills profile", description: 'Meta title' })}
        description={intl.formatMessage({ defaultMessage: 'This report provides detailed feedback based on your session. You will learn about your cognitive skills and neural wiring.', description: 'Meta description' })} />
      <ReportTabs />
      <BrainImagingReportExample />
    </React.Fragment>
  )
}

export default BrainImagingReportExampleView

import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import SendIcon from '@mui/icons-material/Send'
import LoadingButton from '@mui/lab/LoadingButton'
import Alert from '@mui/material/Alert'
import Grid from '@mui/material/Grid'
import Snackbar from '@mui/material/Snackbar'
import TextField from '@mui/material/TextField'

import { useSendContactFormMutation } from '@/features/landing/services/api'

const textFieldProps = {
  fullWidth: true,
  margin: 'normal',
  variant: 'outlined',
}

function ContactUsForm (props) {
  const intl = useIntl()
  const [sendContactForm, { isLoading }] = useSendContactFormMutation()

  const [comment, setComment] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [errors, setErrors ] = React.useState({})
  const [firstName, setFirstName] = React.useState('')
  const [lastName, setLastName] = React.useState('')
  const [phoneNumber, setPhoneNumber] = React.useState('')
  const [open, setOpen] = React.useState(false)
  const disabled = !(firstName && lastName && email && comment)

  const handleChange = (set) => (e) => { set(e.target.value) }
  const handleClose = (e) => { setOpen(false) }

  const handleSubmit = (e) => {
    e.preventDefault()

    return sendContactForm({ firstName, lastName, email, phoneNumber, comment })
      .unwrap()
      .then(() => {
        // Reset values
        setComment('')
        setEmail('')
        setFirstName('')
        setLastName('')
        setPhoneNumber('')
        setErrors({})

        // Open Snackbar
        setOpen(true)
      })
      .catch(({ status, data }) => {
        // const { code, error } = data || {}

        const errors = {}
        // TODO
        setErrors(errors)
      })
  }

  return (
    <form noValidate {...props}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField autoFocus id='firstName' name='firstName' autoComplete='firstName' required
            helperText={errors.firstName} error={!!errors.firstName} onChange={handleChange(setFirstName)}
            label={<FormattedMessage defaultMessage='First name' />}
            {...textFieldProps} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField id='email' name='email' autoComplete='email' required
            helperText={errors.lastName} error={!!errors.lastName} onChange={handleChange(setLastName)}
            label={<FormattedMessage defaultMessage='Last name' />}
            {...textFieldProps} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField id='email' name='email' autoComplete='email' required
            helperText={errors.email} error={!!errors.email} onChange={handleChange(setEmail)}
            label={<FormattedMessage defaultMessage='Email address' />}
            {...textFieldProps} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField id='phoneNumber' name='phoneNumber' autoComplete='phoneNumber'
            helperText={errors.phoneNumber} error={!!errors.phoneNumber} onChange={handleChange(setPhoneNumber)}
            label={<FormattedMessage defaultMessage='Phone number' />}
            {...textFieldProps} />
        </Grid>
        <Grid item xs={12}>
          <TextField id='comment' name='comment' autoComplete='comment' required
            helperText={errors.phoneNumber} error={!!errors.phoneNumber} onChange={handleChange(setComment)}
            label={<FormattedMessage defaultMessage='Comment' />}
            multiline rows={4} placeholder={intl.formatMessage({ defaultMessage: 'Tell us how we can help you.', description: 'TextField placeholder' })}
            {...textFieldProps} />
        </Grid>
        <Grid item xs={12}>
          <LoadingButton type='submit' variant='contained' color='primary'
            onClick={handleSubmit} disabled={disabled} loading={isLoading}
            endIcon={<SendIcon />}>
            <FormattedMessage defaultMessage='Send' />
          </LoadingButton>
        </Grid>
        {errors.general && (
          <Grid item xs={12}>
            <Alert severity='error' variant='outlined'>
              {errors.general}
            </Alert>
          </Grid>
        )}
      </Grid>
      <Snackbar open={open} onClose={handleClose}>
        <Alert onClose={handleClose} severity='success'>
          <FormattedMessage defaultMessage='Thank you, your message has been sent.' description='Alert message' />
        </Alert>
      </Snackbar>
    </form>
  )
}

export default ContactUsForm

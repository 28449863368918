import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'

import { countries } from './countries'

export const getOptionLabel = (option) => option ? option.label : ''

export const renderOption = (props, option) => (
  <Box component='li' sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
    <img loading='lazy' width='20' alt=''
      src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
      srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`} />
    {option.label}
  </Box>
)


function CountrySelect ({ id, inputValue, label, onChange, onInputChange, value, ...otherProps}) {
  const renderInput = (params) => (
    <TextField {...params} label={label} inputProps={{
        ...params.inputProps,
        autoComplete: 'new-password', // NOTE: Disable autocomplete and autofill
      }} {...otherProps} />
  )

  return (
    <Autocomplete id={id} options={countries} autoHighlight
      getOptionLabel={getOptionLabel} renderOption={renderOption} renderInput={renderInput}
      value={value} onChange={onChange}
      inputValue={inputValue} onInputChange={onInputChange} />
  )
}

CountrySelect.propTypes = {
  id: PropTypes.string,
  inputValue: PropTypes.string,
  label: PropTypes.element.isRequired,
  onChange: PropTypes.func,
  onInputChange: PropTypes.func,
  value: PropTypes.any,
}

CountrySelect.defaultProps = {
  label: <FormattedMessage defaultMessage='Country' description='Country select label' />,
}

export default CountrySelect

import without from 'lodash/without'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Box from '@mui/material/Box'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Typography from '@mui/material/Typography'

import { getExecutiveStyle } from '@/features/eeg-sessions/utils/analyses'
import { messages as intlExecutiveStyles } from '@/features/eeg-sessions/messages/executiveStyles.msg'
import { analysisShape, sensorsShape } from '@/features/eeg-sessions/store/propTypes'
import { EXECUTIVE_STYLES_IDS } from '@/features/eeg-sessions/store/propTypes/executiveStyles'
import { b, br, li } from '@/features/intl/utils/intl'
import Heading from '@/features/ui/components/Heading'

function ExecutiveStyleSection ({ analysis, sensors }) {
  if (!analysis) return null

  const executiveStyle = analysis.executiveStyle || getExecutiveStyle({ analysis, sensors })
  if (!executiveStyle) return null

  const otherExecutiveStyles = without(EXECUTIVE_STYLES_IDS, executiveStyle)

  return (
    <React.Fragment>
      <Heading id='executiveStyle' component='h3' variant='h3' gutterTop gutterBottom
        title={<FormattedMessage defaultMessage='Your favorite executive style: {style}' values={{
          style: <FormattedMessage {...intlExecutiveStyles.titles[executiveStyle]} />
        }} />} />
      <FormattedMessage defaultMessage='There are 4 different executive styles: {expediters}, {refiners}, {energizers}, and {experimenters}.{br}{br}Based on your brain imaging results, here is a description and some tips to get the most of your likely style.'
        values={{
          br,
          expediters: <FormattedMessage {...intlExecutiveStyles.titles['expediters']} />,
          refiners: <FormattedMessage {...intlExecutiveStyles.titles['refiners']} />,
          energizers: <FormattedMessage {...intlExecutiveStyles.titles['energizers']} />,
          experimenters: <FormattedMessage {...intlExecutiveStyles.titles['experimenters']} />,
        }}/>
      <Heading component='h4' variant='h4' gutterTop gutterBottom
          title={<FormattedMessage defaultMessage='About {people}' values={{
            people: <FormattedMessage {...intlExecutiveStyles.titles[executiveStyle]} />
        }} />} />
      <FormattedMessage defaultMessage='Here are characteristics that you likely share:' />
      <ul style={{ margin: 0 }}>
        <FormattedMessage {...intlExecutiveStyles.about[executiveStyle]} values={{ li }} />
      </ul>
      <Heading component='h4' variant='h4' gutterTop gutterBottom
        title={<FormattedMessage defaultMessage='Meeting your needs' />} />
      <FormattedMessage defaultMessage='Others can get the most of you when they:' />
      <ul style={{ margin: 0 }}>
        <FormattedMessage {...intlExecutiveStyles.needs[executiveStyle]} values={{ b, li }} />
      </ul>
      <Box sx={{ mt: 5 }}>
        {/* The 4 executive styles */}
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography component='h3' variant='h4'>
              <FormattedMessage defaultMessage='Figuring out your favorite executive style' />
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormattedMessage defaultMessage='<b>Are you more decisive or open-ended?</b> If your brain were a company, it would have two Chief Executive Officers behind your forehead. Each CEO has a different style, strengths, and pitfalls. We tap both, just as we use both hands for many daily tasks but prefer one for important tasks. Your brain lab session revealed which you likely prefer to play a lead role. You trust it more and tend to start with it.{br}{br}<b>Are you faster or slower paced?</b> When your brain receives a prompt, it can react one of two ways. The fast path: You quickly take the prompt "as is", relying on what you know and fits the current situation. Or, the slow path: You compare and integrate the prompt with it all you know to come with a nuanced and thoughtful response. Both offer benefits. Your brain lab session revealed your likely preference.{br}{br}<b>Taken together, there are 4 executive styles:</b> {expediters} (fast and decisive), {refiners} (slow and decisive), {energizers} (fast and open-ended), and {experimenters} (slow and open-ended).' values={{
              b,
              br,
              expediters: <FormattedMessage {...intlExecutiveStyles.titles['expediters']} />,
              refiners: <FormattedMessage {...intlExecutiveStyles.titles['refiners']} />,
              energizers: <FormattedMessage {...intlExecutiveStyles.titles['energizers']} />,
              experimenters: <FormattedMessage {...intlExecutiveStyles.titles['experimenters']} />,
            }} />
          </AccordionDetails>
        </Accordion>
        {/* The other executive styles */}
        {otherExecutiveStyles.map((style, i) => (
          <React.Fragment key={i}>
            {/* About the other executive styles */}
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography component='div' variant='h4'>
                  <FormattedMessage defaultMessage='About {people}' values={{ people: <FormattedMessage {...intlExecutiveStyles.titles[style]} /> }} />
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <FormattedMessage defaultMessage='Others can get the most of them when they:' />
                <ul style={{ margin: 0 }}>
                  <FormattedMessage {...intlExecutiveStyles.about[style]} values={{ b, li }} />
                </ul>
              </AccordionDetails>
            </Accordion>
            {/* Meeting the other executive styles' needs */}
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography component='div' variant='h4'>
                  <FormattedMessage defaultMessage='Meeting {people} needs' values={{ people: <FormattedMessage {...intlExecutiveStyles.titles[style]} /> }} />
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <FormattedMessage defaultMessage='Others can get the most of them when they:' />
                <ul style={{ margin: 0 }}>
                  <FormattedMessage {...intlExecutiveStyles.needs[style]} values={{ b, li }} />
                </ul>
              </AccordionDetails>
            </Accordion>
          </React.Fragment>
        ))}
      </Box>
    </React.Fragment>
  )
}

ExecutiveStyleSection.propTypes = {
  analysis: analysisShape,
  sensors: sensorsShape,
}

export default ExecutiveStyleSection

/**
 * Build a URL to point to a media picture stored on Firebase Storage
 * @param {!String} relativeSrc
 * @returns {!String}
 */
export function getMediaPictureSrc (relativeSrc) {
  if (!relativeSrc) return ''
  
  // Process input for Firebase Storage
  if (relativeSrc.charAt(0) === '/') relativeSrc = relativeSrc.substring(1)
  relativeSrc = '/' + relativeSrc.replaceAll('/', '%2F')

  // NOTE: Replace "/" with "%2F" is to encode URI path for Firebase Storage
  return process.env.REACT_APP_FIREBASE_STORAGE_BASE_URL + relativeSrc
}
import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query/react'
import { combineReducers } from 'redux'

import authReducer from '@/features/auth/store/reducers'
import eegSessionsReducer from '@/features/eeg-sessions/store/reducers'
import intlReducer from '@/features/intl/store/reducers'
import uiReducer from '@/features/ui/store/reducers'
import { coreApi } from '@/services/api'

export const rootReducer = combineReducers({
  auth: authReducer,
  eegSessions: eegSessionsReducer,
  intl: intlReducer,
  ui: uiReducer,
  // Add the generated reducer as a specific top-level slice
  [coreApi.reducerPath]: coreApi.reducer,
})

export const store = configureStore({
  reducer: rootReducer,
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([
    coreApi.middleware,
  ])
})

// Optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch)

export default store

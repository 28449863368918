import React from 'react'
import Box from '@mui/material/Box'
import { styled } from '@mui/styles'

const Title = styled(Box)(({ theme }) => ({
  color: theme.palette.primary.light,
  fontSize: '2rem',
  fontWeight: 'bold',
  textAlign: 'center',
  textTransform: 'uppercase',
}))

function PriceTableTitle ({ children }) {
  return (
    <Title>
      {children}
    </Title>
  )
}

export default PriceTableTitle

import PropTypes from 'prop-types'

import { activityShape } from './activities'
import { analysisShape } from './analyses'
import { sensorsShape } from './sensors'

// NOTE: No fields required to prevent throwing errors when uploading sessions from text files
export const sessionShape = PropTypes.shape({
  activities: PropTypes.arrayOf(activityShape),
  amplitudes: PropTypes.arrayOf(PropTypes.number), // in µV
  analyses: PropTypes.arrayOf(analysisShape),
  createdAt: PropTypes.string, // ISO date
  doneAt: PropTypes.string, // ISO date
  duration: PropTypes.number, // in seconds
  fileUrl: PropTypes.string,
  frequencies: PropTypes.arrayOf(PropTypes.number), // in Hz
  headsetId: PropTypes.string,
  id: PropTypes.string.isRequired,
  practitionerId: PropTypes.string,
  samplingRate: PropTypes.number,
  sensors: sensorsShape,
  title: PropTypes.string,
  userId: PropTypes.string,
})

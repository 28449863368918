export const evenField = {
  circuits: [
    { nodes: 'Fp1,Fpz', score: 100 },
    { nodes: 'Fpz,Fp2', score: 96 },
    { nodes: 'Fp1,Fp2', score: 93 },
    { nodes: 'F7,FT9', score: 83 },
    { nodes: 'F8,FTX', score: 77 },
    { nodes: 'F4,C4', score: 72 },
    { nodes: 'F3,C3', score: 55 },
    { nodes: 'T3,T5', score: 55 },
    { nodes: 'T4,T6', score: 52 },
    { nodes: 'O1,O1', score: 47 },
    { nodes: 'Fp1,F3', score: 47 },
    { nodes: 'Fp2,F4', score: 45 },
    { nodes: 'Fp1,F7', score: 41 },
    { nodes: 'Fp2,F8', score: 36 },
    { nodes: 'F7,F3', score: 33 },
    { nodes: 'F4,F8', score: 33 },
    { nodes: 'F7,T3', score: 32 },
    { nodes: 'F8,T4', score: 31 },
    { nodes: 'T3,C3', score: 31 },
    { nodes: 'C4,T4', score: 29 },
    { nodes: 'C3,Cz', score: 28 },
    { nodes: 'Cz,C4', score: 28 },
    { nodes: 'C3,P3', score: 27 },
    { nodes: 'C4,P4', score: 27 },
    { nodes: 'T5,P3', score: 26 },
    { nodes: 'P4,T6', score: 26 },
    { nodes: 'P4,O2', score: 25 },
    { nodes: 'P3,O1', score: 25 },
    { nodes: 'T5,O1', score: 24 },
    { nodes: 'O2,T6', score: 23 },
    { nodes: 'F3,Fz', score: 23 },
    { nodes: 'Fz,F4', score: 23 },
    { nodes: 'F3,FC1', score: 23 },
    { nodes: 'F4,FC2', score: 22 },
    { nodes: 'FC1,CP1', score: 22 },
    { nodes: 'FC2,CP2', score: 20 },
    { nodes: 'F7,FC5', score: 20 },
    { nodes: 'F8,FC6', score: 20 },
    { nodes: 'FC6,C4', score: 20 },
    { nodes: 'FC5,C3', score: 20 },
    { nodes: 'C3,CP5', score: 20 },
    { nodes: 'C4,CP6', score: 20 },
    { nodes: 'CP6,T6', score: 19 },
    { nodes: 'CP5,T5', score: 19 },
    { nodes: 'CP1,P3', score: 19 },
    { nodes: 'P3,Pz', score: 19 },
    { nodes: 'Pz,P4', score: 19 },
    { nodes: 'CP2,P4', score: 19 },
    { nodes: 'PO8,T6', score: 18 },
    { nodes: 'T5,PO7', score: 18 },
  ],
}

export default evenField

import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  open: false,
  sensorId: undefined,
}

export const sensorDialogSlice = createSlice({
  name: 'sensorDialog',
  initialState,
  reducers: {
    // Pass false to close, pass a sensor ID to open
    setOpen: (state, action) => ({
      ...state,
      open: !!action.payload,
      sensorId: action.payload || undefined,
    }),
  },
})

export const { setOpen } = sensorDialogSlice.actions

export const openSelector = ((state) => state.eegSessions.sensorDialog.open)
export const sensorIdSelector = ((state) => state.eegSessions.sensorDialog.sensorId)

export default sensorDialogSlice.reducer

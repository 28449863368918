import React from 'react'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

function PriceTableBenefits ({ children, ...otherProps }) {
  return (
    <Stack alignItems='center' spacing={.5} {...otherProps} sx={{ textAlign: 'center', ...(otherProps.sx || {}) }}>
      {children.map((item, i) => (
        <Typography key={i} component='div' variant='body1'>
          {item}
        </Typography>
      ))}
    </Stack>
  )
}

export default PriceTableBenefits

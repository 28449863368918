export const diamonds = {
  circuits: [
    { nodes: 'Fp1,Fp2', score: 100 },
    { nodes: 'Fp1,Fpz', score: 96 },
    { nodes: 'Fpz,Fp2', score: 93 },
    { nodes: 'FC6,FTX', score: 83 },
    { nodes: 'T3,F4', score: 77 },
    { nodes: 'F4,F8', score: 72 },
    { nodes: 'F8,FTX', score: 55 },
    { nodes: 'O1,O2', score: 55 },
    { nodes: 'T3,C3', score: 52 },
    { nodes: 'C3,FTX', score: 47 },
    { nodes: 'F7,FT9', score: 47 },
    { nodes: 'Pz,F4,F8,FC6,FTX,T3', score: 45 },
    { nodes: 'FT9,T3', score: 41 },
    { nodes: 'FTX,T4', score: 36 },
    { nodes: 'T3,T5', score: 33 },
    { nodes: 'Fp1,Fz,P4,T6,O1', score: 33 },
    { nodes: 'F7,FT9', score: 32 },
    { nodes: 'F7,FT9', score: 31 },
    { nodes: 'F7,FT9', score: 31 },
    { nodes: 'F7,FT9', score: 29 },
    { nodes: 'F7,FT9', score: 28 },
    { nodes: 'F7,FT9', score: 28 },
    { nodes: 'F7,FT9', score: 27 },
    { nodes: 'F7,FT9', score: 27 },
    { nodes: 'F7,FT9', score: 26 },
    { nodes: 'F7,FT9', score: 26 },
    { nodes: 'F7,FT9', score: 25 },
    { nodes: 'F7,FT9', score: 25 },
    { nodes: 'F7,FT9', score: 24 },
    { nodes: 'F7,FT9', score: 23 },
    { nodes: 'Fp1,F7', score: 23 },
    { nodes: 'Fp1,FC5', score: 23 },
    { nodes: 'FC5,F3', score: 23 },
    { nodes: 'Fp2,F8', score: 22 },
    { nodes: 'Fp2,T4', score: 22 },
    { nodes: 'F8,T4', score: 20 },
    { nodes: 'F7,T3', score: 20 },
    { nodes: 'T3,FC5', score: 20 },
    { nodes: 'C4,T4', score: 20 },
    { nodes: 'C4,T6', score: 20 },
    { nodes: 'T4,T6', score: 20 },
    { nodes: 'O2,PO8', score: 20 },
    { nodes: 'PO7,O1', score: 19 },
    { nodes: 'T5,PO7', score: 19 },
    { nodes: 'T5,P3', score: 19 },
    { nodes: 'PO8,T6', score: 19 },
    { nodes: 'T5,C3', score: 19 },
    { nodes: 'Fpz,F4', score: 19 },
  ],
}

export default diamonds

import PropTypes from 'prop-types'

export const userDetailsShape = PropTypes.shape({
  country: PropTypes.string,
  email: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  lastName: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string,
})

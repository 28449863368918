import PropTypes from 'prop-types'

export const EXECUTIVE_STYLE_ENERGIZERS = 'energizers'
export const EXECUTIVE_STYLE_EXPEDITERS = 'expediters'
export const EXECUTIVE_STYLE_EXPERIMENTERS = 'experimenters'
export const EXECUTIVE_STYLE_REFINERS = 'refiners'

export const EXECUTIVE_STYLES_IDS = [
  EXECUTIVE_STYLE_ENERGIZERS,
  EXECUTIVE_STYLE_EXPEDITERS,
  EXECUTIVE_STYLE_EXPERIMENTERS,
  EXECUTIVE_STYLE_REFINERS,
]

export const executiveStyleShape = PropTypes.oneOf(EXECUTIVE_STYLES_IDS)

import { defineMessages } from 'react-intl'

export const titles = defineMessages({
  'audio-kinesthetic': {
    id: 'wiringPatterns.titles.audioKinesthetic',
    defaultMessage: 'Audio-Kinesthetic',
  },
  back: {
    id: 'wiringPatterns.titles.back',
    defaultMessage: 'Back',
  },
  'back-left': {
    id: 'wiringPatterns.titles.backLeft',
    defaultMessage: 'Back Left',
  },
  'back-right': {
    id: 'wiringPatterns.titles.backRight',
    defaultMessage: 'Back Right',
  },
  bookends: {
    id: 'wiringPatterns.titles.bookends',
    defaultMessage: 'Bookends',
  },
  center: {
    id: 'wiringPatterns.titles.center',
    defaultMessage: 'Center',
  },
  'center-back': {
    id: 'wiringPatterns.titles.centerBack',
    defaultMessage: 'Center Back',
  },
  'center-front': {
    id: 'wiringPatterns.titles.centerFront',
    defaultMessage: 'Center Front',
  },
  diamond: {
    id: 'wiringPatterns.titles.diamond',
    defaultMessage: 'Singular Diamond',
  },
  diamonds: {
    id: 'wiringPatterns.titles.diamonds',
    defaultMessage: 'Multi-Diamonds',
  },
  'even-field': {
    id: 'wiringPatterns.titles.evenField',
    defaultMessage: 'Even-Field',
  },
  'focused-holistic': {
    id: 'wiringPatterns.titles.focusedHolistic',
    defaultMessage: 'Focused-Holistic',
  },
  front: {
    id: 'wiringPatterns.titles.front',
    defaultMessage: 'Front',
  },
  halo: {
    id: 'wiringPatterns.titles.halo',
    defaultMessage: 'Halo',
  },
  'hidden-starburst': {
    id: 'wiringPatterns.titles.hiddenStarburst',
    defaultMessage: 'Hidden Starburst',
  },
  left: {
    id: 'wiringPatterns.titles.left',
    defaultMessage: 'Left',
  },
  'open-analytic': {
    id: 'wiringPatterns.titles.openAnalytic',
    defaultMessage: 'Open-Analytic',
  },
  pillar: {
    id: 'wiringPatterns.titles.pillar',
    defaultMessage: 'Pillar',
  },
  'random-field': {
    id: 'wiringPatterns.titles.randomField',
    defaultMessage: 'Random Field',
  },
  right: {
    id: 'wiringPatterns.titles.right',
    defaultMessage: 'Right',
  },
  'strong-starburst': {
    id: 'wiringPatterns.titles.strongStarburst',
    defaultMessage: 'Strong Starburst',
  },
  'super-audio': {
    id: 'wiringPatterns.titles.superAudio',
    defaultMessage: 'Super Audio',
  },
  'weak-starburst': {
    id: 'wiringPatterns.titles.weakStarburst',
    defaultMessage: 'Weak Starburst',
  },
  zigzag: {
    id: 'wiringPatterns.titles.zigzag',
    defaultMessage: 'Zig-Zag',
  },
})

export const descriptions = defineMessages({
  'audio-kinesthetic': {
    id: 'wiringPatterns.descriptions.audioKinesthetic',
    defaultMessage:
      "You talk and listen in order to take action. When listening, you attend to a range of things such as people's voice tone, intentions, word choice, and factual details. You listen, share ideas, ask clarifying questions, and such in order to get going and implement. You talk out details. And you are in touch with your body, move skillfully and gracefully, and likely enjoy music and dance. A lack of input and action bores or frustrates you.",
  },
  back: {
    id: 'wiringPatterns.descriptions.back',
    defaultMessage:
      'You are a keen observer and think in a visual-spatial way. You are more introverted or at least often take time to reflect on how things work. You prefer to gather data and plug it in to what you know before making a decision or engaging someone. You like to watch, keep  your knowledge banks open, and run data through your pallet of mental frameworks to gain a nuanced understanding of how things function (or not). You may find it difficult to explain  your deep understanding.',
  },
  'back-left': {
    id: 'wiringPatterns.descriptions.backLeft',
    defaultMessage:
      'You focus on instructions and rules, and you like feedback from others on those. You have a good eye and memory for visual detail and a good ear for words. You are often aware of how others are responding to what you are saying or doing. You like facts, often take time to sort new data, check things before acting, and stay orderly. You like to label, build, and correct. You like to fix something that is out of place. You may be good at sports and similar physical activities after sufficient practice.',
  },
  'back-right': {
    id: 'wiringPatterns.descriptions.backRight',
    defaultMessage:
      'You are fantastic at working with complex systems. Such systems might be technical or might be people in organizations. Whatever the specifics, you can juggle many interconnected variables. In such systems, you can find and test leverage points to locate the best means to a bring out a desired outcome. You are adept finding patterns in visual data. You may feel you just “see” or “know” something and then have a hard time expressing an insight in words.',
  },
  bookends: {
    id: 'wiringPatterns.descriptions.bookends',
    defaultMessage:
      'You look like an extrovert, but looks can be deceiving. Even as you interact with others in an energetic way, you are taking in data, usually voice tone and facial expressions, to figure out the situation and the best way to move things tactically. No one sees your quick calculations, and your responses may surprise others. Other times, you may just be quietly appreciating some point of visual interest or a memory that the situation evokes even as you keep up a show with others.',
  },
  center: {
    id: 'wiringPatterns.descriptions.center',
    defaultMessage:
      "You are concrete and physical. You feel connected to your body and are more of a doer, or at least enjoy life's pleasures, rather than getting so abstract. You are adept at athletic and/or outdoor activities such as climbing, dancing, swimming, and team sports if that interests you, and also artistic pursuits using your hands if that interests you. You are good at learning by physically trying a set of steps or a routine. And you can improvise with your hands or body as well. You can be too concrete sometimes.",
  },
  'center-back': {
    id: 'wiringPatterns.descriptions.centerBack',
    defaultMessage:
      "You are contemplative. You tend to gather information and consider a wide blend of facts, precise details, others' input, broad patterns, gut-feel, and personal memories. You can get talkative but mainly on subjects that interest you or only after you feel ready to share. You prefer to understand situations before passing judgment or taking action. You are generally good at managing your impulses, and you can come off to others as inhibited. You can find it difficult to respond quickly to situations.",
  },
  'center-front': {
    id: 'wiringPatterns.descriptions.centerFront',
    defaultMessage:
      "You seek a balanced understanding of life's various sides. Across a variety of situations, familiar or new, you can apply clear values. Also, you can balance imagination with realism, brainstorming with practicalities, and logic with social insight. With all this, you can be a skilled manager, though you may be low-key, more of a careful communicator, and not necessarily an extrovert. You think more in terms of language than visuals. You may lack when it comes to making a decision or acting on it.",
  },
  diamond: {
    id: 'wiringPatterns.descriptions.diamond',
    defaultMessage:
      'You tend to rely on a special "mode" of thinking. That is, you have developed a sophisticated way of thinking, likely specific to a role or context. That mode brings together all the resources needed to perform in a highly effective, sophisticated way. At the same time, if and when you face a situation that does not match this mode, then you can feel out of place and really unsure how to respond or do so awkwardly.',
  },
  diamonds: {
    id: 'wiringPatterns.descriptions.diamonds',
    defaultMessage:
      'You tend to switch between various "modes" of thinking. That is, you have developed several sophisticated ways of thinking, each specific to a role or context. Each way, or mode, brings together all the resources needed to perform in a highly effective, sophisticated way. At the same time, if and when you face a situation that does not match a mode you know, then you can feel out of place and really unsure how to respond or do so awkwardly.',
  },
  'even-field': {
    id: 'wiringPatterns.descriptions.evenField',
    defaultMessage:
      'You are concrete, practical, and balanced. You trust your senses and are skilled at taking action. That is, you are good at responding in a tangible way to whatever comes up. You can quickly learn how to do new tasks and are generally flexible, adapting to situations and solving problems by whatever means. You probably have difficulty with abstract material or connecting seemingly unrelated points unless can see an idea play out in the real world.',
  },
  'focused-holistic': {
    id: 'wiringPatterns.descriptions.focusedHolistic',
    defaultMessage:
      "You look goal-focused, but that's half the story. Even as you interact with others, you are taking in data, including voice tone, facial expressions, and other data, to figure out the situation and the best way to move things tactically. No one sees your quick calculations, and your responses may surprise others. Other times, you may just be quietly appreciating some point of visual interest or a memory that the situation evokes even as you keep up a show with others.",
  },
  front: {
    id: 'wiringPatterns.descriptions.front',
    defaultMessage:
      'You are more extroverted or at least easily function in a leader or manager role. You are quick to decide even when new data comes in. You are quick at balancing your principles and identity against ever-changing contextual demands, and you rely on language-based reasoning, which can include logic, metaphors, categories, hypotheses, etc. You have good working memory, and you easily build rapport, learn by observing, and best explore ideas using analogies.',
  },
  halo: {
    id: 'wiringPatterns.descriptions.halo',
    defaultMessage:
      'You are well-rounded with regard to language, people skills, memory and imagination, but you tend to be disconnected from your body and sensations and likely poor at or dislike many routine physical tasks. You are likely gifted academically at diverse subjects. You are socially well-adjusted even if on the more introverted side. You are likely a little slow at math or at tasks that require strong visual-spatial skills. You can be prone to neglect health and fitness.',
  },
  'hidden-starburst': {
    id: 'wiringPatterns.descriptions.hiddenStarburst',
    defaultMessage:
      'You have a hidden creative or intuitive side. Often other people do not see this side of you. In fact, you may not see it very often, and you may surprise yourself when you suddenly get a big ah-ha idea. You can make an effort to activate this side. For example, you might sketch, free-write or do something similar. It needs a kickstart, goal or context. Also, an insight might come in a dream. This side can complement your work, giving you an innovative edge.',
  },
  left: {
    id: 'wiringPatterns.descriptions.left',
    defaultMessage:
      'You are analytical and good with data that is measured, detailed or discrete such as word content, charts and diagrams, and step-wise moves. You attend to visual and verbal details, with a strong memory for facts. You are good with rules and mental planning. You learn best by visualizing simple analogies or familiar what-if scenarios. You work best using templates. You have solid interpersonal boundaries and are efficient. You are likely good at sports and/or math.',
  },
  'open-analytic': {
    id: 'wiringPatterns.descriptions.openAnalytic',
    defaultMessage:
      'You look open-ended, curious, and process oriented; at the same time, you handle detail well and are anchored in principles and values. You seek out new information, are open to brainstorming, and like discovery. Along the way, you attend to verbal, visual, and factual details. For example, you might scan through diagrams to gain an insight. You can build a complex representation from incoming data. This analytical process is internal, in the background. Others may not appreciate it.',
  },
  pillar: {
    id: 'wiringPatterns.descriptions.pillar',
    defaultMessage:
      'Your brain is wired for deep thinking and problem solving in a technical yet creative way. You have fine visual-spatial skills and work a lot with mental models. You rely on principles and definitions to understand problems, make inferences, and seek leverage for solutions, looking at problems from multiple angles. There is a constant back and forth process between goals and means, theory and data, and familiar techniques versus possible alternatives.',
  },
  'random-field': {
    id: 'wiringPatterns.descriptions.randomField',
    defaultMessage:
      'You are skilled at a variety of tasks - a mix of abstract and concrete, humanistic and technical - but often in idiosyncratic ways that reflect self-teaching or an unusual background. You can look brilliant in some ways yet slow in other tasks that most other people take for granted. You prefer to learn and solve problems following your own process. Your way of thinking gives meaning to the phrases, "follow your own path" and "think different".',
  },
  right: {
    id: 'wiringPatterns.descriptions.right',
    defaultMessage:
      'You attend to style such as body language, artistic impact, voice tone, mood, and intention. You are good at considering many factors at once to understand the complexity of situations without labeling or dissecting them. You enjoy good narrative memory. You learn best from stories or by trying something, perhaps playing around with it. You probably "get" people easily, with loose interpersonal boundaries. You are likely skilled at the arts.',
  },
  'strong-starburst': {
    id: 'wiringPatterns.descriptions.strongStarburst',
    defaultMessage:
      'You are highly creative, intuitive, and inquisitive with a tremendous capacity to connect data in novel ways. With every new input you get, every part of your brain gets involved to consider its potential meaning or use. You are talented across subjects: humanistic, artistic, technical, and physical. You are a generalist and may be visionary. You may struggle to focus and can waste time and energy trying to respond in a new or exciting way when not needed.',
  },
  'super-audio': {
    id: 'wiringPatterns.descriptions.superAudio',
    defaultMessage:
      'You like to communicate. You notice to a range of qualities from voice tone to word choice. You are skilled at using analogies, logical steps, concepts, and metaphors to work through and convey ideas. You quickly compare what you hear against evidence before you. You have a good ear for authenticity and believability as well as making logical connections. You can focus on speaking to control your impulses. With such a focus on language, you may miss or under-rate important physical or visual information.',
  },
  'weak-starburst': {
    id: 'wiringPatterns.descriptions.weakStarburst',
    defaultMessage:
      'You are notably creative and intuitive when a situation calls for it or when time and space permit you to think outside the box. Given the opportunity, you can step up in a meeting or withdraw to a private space to connect the dots and gain useful insights. For you, this creativity does not require pencil-paper or following a specific process; rather, it comes naturally. You are comfortable discussing a wide variety of subjects, from artistic to technical.',
  },
  zigzag: {
    id: 'wiringPatterns.descriptions.zigzag',
    defaultMessage:
      'You tend to think "diagonally". That is, you tend to connect things in ways that don’t typically seem to go together for people. For example, hearing someone’s voice tone triggers in you a “clairvoyant” visual images about that person. Or approaching a math problem evokes memories of music. You may think by moving here and then there, like in a "zig zag" pattern. Depending on the situation, this can come off as artistic, intuitive, or confusing.',
  },
})

export const messages = {
  descriptions,
  titles,
}

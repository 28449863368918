import React from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import { SENSORS_COORDINATES } from '@/features/eeg-sessions/datasets/sensors'
import { sensorsShape } from '@/features/eeg-sessions/store/propTypes'
import { setOpen } from '@/features/eeg-sessions/store/reducers/sensorDialog'

const SENSOR_BACKGROUND_OPACITY = 0.85
const SENSOR_RADIUS_RATIO = 26

export function Sensors ({ disableDialogs, display, sensors = [], strokeWidth }) {
  const dispatch = useDispatch()

  const bgFill = `rgba(255,255,255,${display === 'wiring' ? SENSOR_BACKGROUND_OPACITY : 1})`
  const cursor = disableDialogs ? undefined : { cursor: 'pointer' }

  let elements = []
  for (let i = 0; i < sensors.length; i++) {
    const sensorId = sensors[i]
    const { x, y } = SENSORS_COORDINATES[sensorId] || {}
    if (x === undefined) continue

    const handleClick = disableDialogs ? undefined : (e) => {
      e.stopPropagation()
      dispatch(setOpen(sensorId))
    }

    elements = [
      ...elements,
      <circle key={`circle-${sensorId}`} cx={x} cy={y} r={SENSOR_RADIUS_RATIO}
        fill={bgFill} stroke='black' strokeWidth={strokeWidth}
        style={cursor} onClick={handleClick} />,
      <text key={`text-${sensorId}`} x={x} y={y + 2}
        fill='black' textAnchor='middle' alignmentBaseline='middle'
        fontSize={20} fontWeight='bold' style={cursor} onClick={handleClick}>
        {sensorId}
      </text>,
    ]
  }

  return elements
}

Sensors.propTypes = {
  disableDialogs: PropTypes.bool,
  display: PropTypes.string,
  sensors: sensorsShape,
  strokeWidth: PropTypes.number.isRequired,
}

export default Sensors

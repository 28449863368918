export const firebaseConfig = {
  apiKey: 'AIzaSyBpT2j4S3oqKivxLSijl5uk6yxHI3MSJ-A',
  authDomain: 'puzzlin-org.firebaseapp.com',
  projectId: 'puzzlin-org',
  storageBucket: 'puzzlin-org.appspot.com',
  messagingSenderId: '623333581517',
  appId: '1:623333581517:web:1cddb5a91b81bbf6953f84',
  measurementId: 'G-861J28W8KK',
}

export default firebaseConfig

import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { isLoggedInSelector } from '@/features/auth/store/reducers/tokens'

export function SessionManager () {
  const navigate = useNavigate()

  const isLoggedIn = useSelector(isLoggedInSelector)
  const [loggedIn, setLoggedIn] = React.useState(isLoggedIn)

  // Navigate to login page after logging user out
  React.useEffect(() => {
    if (loggedIn && !isLoggedIn) navigate('/login')

    setLoggedIn(isLoggedIn)
  }, [loggedIn, isLoggedIn, navigate])

  return null
}

export default SessionManager

import cloneDeep from 'lodash/cloneDeep'
import { defineMessages } from 'react-intl'

import { SENSORS_COORDINATES } from './coordinates'

export const SPACER = 50
export const LINE_BREAK = '||'

/**
 * Return absolute coordinates for each tip assuming tips were defined with coordinates relative to a sensor's coordinates
 */
export function translateCoordinates (config) {
  config = cloneDeep(config) // Do not mutate entry

  for (const tipId of Object.keys(config)) {
    const tip = config[tipId]

    const { sensorId, x, y } = tip
    const sensor = SENSORS_COORDINATES[sensorId]

    tip.x = sensor.x + x
    tip.y = sensor.y + y
  }

  return config
}

// NOTE: Coordinates are first defined relatively to a its sensor's coordinates
export const SENSORS_TIPS = translateCoordinates(defineMessages({
  'Fp1': {
    sensorId: 'Fp1', x: 0, y: SPACER, textAnchor: 'middle', inner: true,
    id: 'sensors.tips.Fp1', defaultMessage: `stay positive||notice errors`,
  },
  'Fp1b': {
    sensorId: 'Fp1', x: -SPACER, y: -.75 * SPACER, textAnchor: 'end', inner: false,
    id: 'sensors.tips.Fp1b', defaultMessage: `decide||confidently`,
  },
  'Fpz': {
    sensorId: 'Fpz', x: 0, y: SPACER, textAnchor: 'middle', inner: true,
    id: 'sensors.tips.Fpz', defaultMessage: `self||manage`,
  },
  'Fp2': {
    sensorId: 'Fp2', x: 0, y: SPACER, textAnchor: 'middle', inner: true,
    id: 'sensors.tips.Fp2', defaultMessage: `stay open||track process`,
  },
  'Fp2b': {
    sensorId: 'Fp2', x: SPACER, y: -.75 * SPACER, textAnchor: 'start', inner: false,
    id: 'sensors.tips.Fp2b', defaultMessage: `manage||negative feelings`,
  },
  'F7': {
    sensorId: 'F7', x: 0, y: - 1.5 * SPACER, textAnchor: 'end', inner: false,
    id: 'sensors.tips.F7', defaultMessage: `mirror other's||behavior`,
  },
  'F7b': {
    sensorId: 'F7', x: -.75 * SPACER, y: -.5 * SPACER, textAnchor: 'end', inner: false,
    id: 'sensors.tips.F7b', defaultMessage: `mentally||rehearse`,
  },
  'F7c': {
    sensorId: 'F7', x: -SPACER, y: .5 * SPACER, textAnchor: 'end', inner: false,
    id: 'sensors.tips.F7c', defaultMessage: `infer||by context`,
  },
  'F3': {
    sensorId: 'F3', x: 0, y: SPACER, textAnchor: 'middle', inner: true,
    id: 'sensors.tips.F3', defaultMessage: `deduce`,
  },
  'F3b': {
    sensorId: 'F3', x: 0, y: -SPACER, textAnchor: 'middle', inner: true,
    id: 'sensors.tips.F3b', defaultMessage: `plan`,
  },
  'Fz': {
    sensorId: 'Fz', x: 0, y: SPACER, textAnchor: 'middle', inner: true,
    id: 'sensors.tips.Fz', defaultMessage: `abstract||learning`,
  },
  'Fzb': {
    sensorId: 'Fz', x: 0, y: -SPACER, textAnchor: 'middle', inner: true,
    id: 'sensors.tips.Fzb', defaultMessage: `hypothesize`,
  },
  'F4': {
    sensorId: 'F4', x: 0, y: SPACER, textAnchor: 'middle', inner: true,
    id: 'sensors.tips.F4', defaultMessage: `metaphors`,
  },
  'F4b': {
    sensorId: 'F4', x: 0, y: -SPACER, textAnchor: 'middle', inner: true,
    id: 'sensors.tips.F4b', defaultMessage: `concepts`,
  },
  'F8': {
    sensorId: 'F8', x: 0, y: -1.5 * SPACER, textAnchor: 'start', inner: false,
    id: 'sensors.tips.F8', defaultMessage: `place personal||value`,
  },
  'F8b': {
    sensorId: 'F8', x: .75 * SPACER, y: -.5 * SPACER, textAnchor: 'start', inner: false,
    id: 'sensors.tips.F8b', defaultMessage: `be modest`,
  },
  'F8c': {
    sensorId: 'F8', x: SPACER, y: .5 * SPACER, textAnchor: 'start', inner: false,
    id: 'sensors.tips.F8c', defaultMessage: `pay attention||to litteral details`,
  },
  'FT9': {
    sensorId: 'FT9', x: -.75 * SPACER, y: -.25 * SPACER, textAnchor: 'end', inner: false,
    id: 'sensors.tips.FT9', defaultMessage: `check||veracity`,
  },
  'FC5': {
    sensorId: 'FC5', x: 0, y: SPACER, textAnchor: 'middle', inner: true,
    id: 'sensors.tips.FC5', defaultMessage: `work||efficiently`,
  },
  'FC6': {
    sensorId: 'FC6', x: 0, y: SPACER, textAnchor: 'middle', inner: true,
    id: 'sensors.tips.FC6', defaultMessage: `sort out||emotions`,
  },
  'FTX': {
    sensorId: 'FTX', x: .75 * SPACER, y: -.25 * SPACER, textAnchor: 'start', inner: false,
    id: 'sensors.tips.FTX', defaultMessage: `check||structure`,
  },
  'T3': {
    sensorId: 'T3', x: .75 * SPACER, y: -.25 * SPACER, textAnchor: 'start', inner: true,
    id: 'sensors.tips.T3', defaultMessage: `hear||words`,
  },
  'T3b': {
    sensorId: 'T3', x: 0, y: SPACER, textAnchor: 'start', inner: true,
    id: 'sensors.tips.T3b', defaultMessage: `parse||sentence`,
  },
  'C3': {
    sensorId: 'C3', x: .75 * SPACER, y: -.1 * SPACER, textAnchor: 'start', inner: true,
    id: 'sensors.tips.C3', defaultMessage: `move||step-wise`,
  },
  'Cz': {
    sensorId: 'Cz', x: 0, y: -1.1 * SPACER, textAnchor: 'middle', inner: true,
    id: 'sensors.tips.Cz', defaultMessage: `have||drive`,
  },
  'C4': {
    sensorId: 'C4', x: -.75 * SPACER, y: -.1 * SPACER, textAnchor: 'end', inner: true,
    id: 'sensors.tips.C4', defaultMessage: `move||gracefully`,
  },
  'T4': {
    sensorId: 'T4', x: -.75 * SPACER, y: -.25 * SPACER, textAnchor: 'end', inner: true,
    id: 'sensors.tips.T4', defaultMessage: `hear||voice tone`,
  },
  'T4b': {
    sensorId: 'T4', x: 0, y: SPACER, textAnchor: 'end', inner: true,
    id: 'sensors.tips.T4b', defaultMessage: `weight||intent`,
  },
  'CP5': {
    sensorId: 'CP5', x: SPACER, y: -1.1 * SPACER, textAnchor: 'middle', inner: true,
    id: 'sensors.tips.CP5', defaultMessage: `outward||success`,
  },
  'CP6': {
    sensorId: 'CP6', x: -SPACER, y: -1.1 * SPACER, textAnchor: 'middle', inner: true,
    id: 'sensors.tips.CP6', defaultMessage: `inner||harmony`,
  },
  'T5': {
    sensorId: 'T5', x: -.75 * SPACER, y: -SPACER, textAnchor: 'end', inner: false,
    id: 'sensors.tips.T5', defaultMessage: `pay attention to||social feedback`,
  },
  'T5b': {
    sensorId: 'T5', x: -.75 * SPACER, y: .5 * SPACER, textAnchor: 'end', inner: false,
    id: 'sensors.tips.T5b', defaultMessage: `feel||embarrassed`,
  },
  'T5c': {
    sensorId: 'T5', x: 0, y: -1.25 * SPACER, textAnchor: 'start', inner: true,
    id: 'sensors.tips.T5c', defaultMessage: `fact||memory`,
  },
  'T5d': {
    sensorId: 'T5', x: .25 * SPACER, y: 1.75 * SPACER, textAnchor: 'end', inner: false,
    id: 'sensors.tips.T5d', defaultMessage: `analyze||faces`,
  },
  'P3': {
    sensorId: 'P3', x: 0, y: SPACER, textAnchor: 'middle', inner: true,
    id: 'sensors.tips.P3', defaultMessage: `fast reading||or arithmetic`,
  },
  'P3b': {
    sensorId: 'P3', x: .25 * SPACER, y: -1.1 * SPACER, textAnchor: 'middle', inner: true,
    id: 'sensors.tips.P3b', defaultMessage: `excel at||team sports`,
  },
  'Pz': {
    sensorId: 'Pz', x: 0, y: SPACER, textAnchor: 'middle', inner: true,
    id: 'sensors.tips.Pz', defaultMessage: `think in||3D models`,
  },
  'Pzb': {
    sensorId: 'Pz', x: 0, y: -1.25 * SPACER, textAnchor: 'middle', inner: true,
    id: 'sensors.tips.Pzb', defaultMessage: `concrete||learning`,
  },
  'P4': {
    sensorId: 'P4', x: 0, y: SPACER, textAnchor: 'middle', inner: true,
    id: 'sensors.tips.P4', defaultMessage: `weigh||many factors`,
  },
  'P4b': {
    sensorId: 'P4', x: -.25 * SPACER, y: -1.1 * SPACER, textAnchor: 'middle', inner: true,
    id: 'sensors.tips.P4b', defaultMessage: `pay attention||to body signals`,
  },
  'T6': {
    sensorId: 'T6', x: .75 * SPACER, y: -SPACER, textAnchor: 'start', inner: false,
    id: 'sensors.tips.T6', defaultMessage: `foresee||future`,
  },
  'T6b': {
    sensorId: 'T6', x: .75 * SPACER, y: .5 * SPACER, textAnchor: 'start', inner: false,
    id: 'sensors.tips.T6b', defaultMessage: `symbolize||meaning`,
  },
  'T6c': {
    sensorId: 'T6', x: 0, y: -1.25 * SPACER, textAnchor: 'end', inner: true,
    id: 'sensors.tips.T6c', defaultMessage: `felt||memory`,
  },
  'T6d': {
    sensorId: 'T6', x: -.25 * SPACER, y: 1.75 * SPACER, textAnchor: 'start', inner: false,
    id: 'sensors.tips.T6d', defaultMessage: `recognize||faces`,
  },
  'PO7': {
    sensorId: 'PO7', x: 0, y: SPACER, textAnchor: 'end', inner: false,
    id: 'sensors.tips.PO7', defaultMessage: `check quality||of details`,
  },
  'O1': {
    sensorId: 'O1', x: 0, y: SPACER, textAnchor: 'middle', inner: false,
    id: 'sensors.tips.O1', defaultMessage: `build & rotate||images`,
  },
  'O1b': {
    sensorId: 'O1', x: 0, y: -1.25 * SPACER, textAnchor: 'middle', inner: true,
    id: 'sensors.tips.O1b', defaultMessage: `like charts||& diagrams`,
  },
  'O2': {
    sensorId: 'O2', x: 0, y: SPACER, textAnchor: 'middle', inner: false,
    id: 'sensors.tips.O2', defaultMessage: `see themes||in images`,
  },
  'O2b': {
    sensorId: 'O2', x: 0, y: -1.1 * SPACER, textAnchor: 'middle', inner: true,
    id: 'sensors.tips.O2b', defaultMessage: `see body||language`,
  },
  'PO8': {
    sensorId: 'PO8', x: 0, y: SPACER, textAnchor: 'start', inner: false,
    id: 'sensors.tips.PO8', defaultMessage: `notice||choreography`,
  },
}))

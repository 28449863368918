import React from 'react'
import AppBar from '@mui/material/AppBar'
import Container from '@mui/material/Container'

function ActionAppBar ({ children }) {
  // Compute body padding height
  const appBarRef = React.useRef(null)
  const { current } = appBarRef
  const height = current && current.clientHeight

  return (
    <React.Fragment>
      {/* Body padding */}
      <div style={{ height }} />
      {/* AppBar */}
      <AppBar ref={appBarRef} color='inherit' position='fixed' sx={{ top: 'auto', bottom: 0 }}>
        <Container sx={{ py: 2 }}>
          {children}
        </Container>
      </AppBar>
    </React.Fragment>
  )
}

export default ActionAppBar

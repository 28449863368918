import { combineReducers } from 'redux'

import themeModeReducer from './themeMode'
import userRoleReducer from './userRole'

export const uiReducer = combineReducers({
  themeMode: themeModeReducer,
  userRole: userRoleReducer,
})

export default uiReducer

import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'

import FormattedSensor from '@/features/eeg-sessions/components/FormattedSensor'

export function SensorDescription ({ id, inTheWorkplace, rank, withTitle }) {
  if (!id) return null

  return (
    <Box>
      {withTitle && (
        <h3 style={{ marginTop: 0 }}>
        <Stack direction='row' justifyContent='space-between' alignItems='center'>
          {rank ? (
            <FormattedMessage defaultMessage='#{rank} {title}' values={{
              rank,
              title: <FormattedSensor id={id} messages='titles' />,
            }} />
          ) : (
            <FormattedSensor id={id} messages='titles' />
          )}
          <span>{id}</span>
        </Stack>
      </h3>
      )}
      <img style={{ float: 'left', marginRight: 16 }} alt='' width={60} height={60} src={`/sensorIcons/${id}.jpg`} />
      <FormattedSensor id={id} messages='descriptions' />
      {inTheWorkplace && (
        <Box sx={{ mt: 2 }}>
          <FormattedMessage defaultMessage='In the workplace, you:' />
          <ul style={{ margin: 0 }}>
            <FormattedSensor id={id} messages='inTheWorkplace' />
          </ul>
        </Box>
      )}
    </Box>
  )
}

SensorDescription.propTypes = {
  id: PropTypes.string,
  inTheWorkplace: PropTypes.bool,
  rank: PropTypes.number,
  withTitle: PropTypes.bool,
}
export default SensorDescription

import React from 'react'
import ReactDOM from 'react-dom'
import TagManager from 'react-gtm-module'
import { Provider } from 'react-redux'

import App from '@/App'
import store from '@/store'

import reportWebVitals from '@/reportWebVitals'

TagManager.initialize({
  gtmId: process.env.REACT_APP_GTM_ID,
  auth: process.env.REACT_APP_GTM_AUTH,
  preview: process.env.REACT_APP_GTM_ENV,
})

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

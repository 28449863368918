export const halo = {
  circuits: [
    { nodes: 'Fp1,Fpz', score: 100 },
    { nodes: 'Fp2,Fpz', score: 96 },
    { nodes: 'Fp1,Fp2', score: 93 },
    { nodes: 'F7,FT9', score: 83 },
    { nodes: 'F8,FTX', score: 77 },
    { nodes: 'FTX,T4', score: 72 },
    { nodes: 'FT9,T3', score: 55 },
    { nodes: 'T3,T5', score: 55 },
    { nodes: 'T4,T6', score: 52 },
    { nodes: 'O1,O2', score: 47 },
    { nodes: 'Fp1,F3', score: 47 },
    { nodes: 'Fp2,F4', score: 45 },
    { nodes: 'F4,F8', score: 41 },
    { nodes: 'Fp2,F8', score: 36 },
    { nodes: 'F8,T4', score: 33 },
    { nodes: 'FC6,T4', score: 33 },
    { nodes: 'T4,CP6', score: 32 },
    { nodes: 'P4,T6', score: 31 },
    { nodes: 'T6,PO8', score: 31 },
    { nodes: 'O2,PO8', score: 29 },
    { nodes: 'P4,O2', score: 28 },
    { nodes: 'P3,O1', score: 28 },
    { nodes: 'PO7,O1', score: 27 },
    { nodes: 'T5,PO7', score: 27 },
    { nodes: 'T5,P3', score: 26 },
    { nodes: 'Fp1,F7', score: 26 },
    { nodes: 'F7,F3', score: 25 },
    { nodes: 'F7,T3', score: 25 },
    { nodes: 'T3,CP5', score: 24 },
    { nodes: 'T3,FC5', score: 23 },
    { nodes: 'Fp1,Fz', score: 23 },
    { nodes: 'F3,Fz', score: 23 },
    { nodes: 'Fp1,FT9', score: 23 },
    { nodes: 'FT9,FC5', score: 22 },
    { nodes: 'F7,FC5', score: 22 },
    { nodes: 'F3,FC5', score: 20 },
    { nodes: 'T3,T4', score: 20 },
    { nodes: 'Fp2,FTX', score: 20 },
    { nodes: 'F8,FC6', score: 20 },
    { nodes: 'FC6,FTX', score: 20 },
    { nodes: 'F4,FC6', score: 20 },
    { nodes: 'F4,FC2', score: 20 },
    { nodes: 'T5,CP5', score: 19 },
    { nodes: 'CP5,P3', score: 19 },
    { nodes: 'P3,CP1', score: 19 },
    { nodes: 'PO7,P3', score: 19 },
    { nodes: 'Pz,P4', score: 19 },
    { nodes: 'P4,PO8', score: 19 },
    { nodes: 'P4,CP6', score: 18 },
    { nodes: 'CP6,T6', score: 18 },
  ],
}

export default halo

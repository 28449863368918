export const weakStarburst = {
  circuits: [
    { nodes: 'Fp1,Fp2', score: 100 },
    { nodes: 'Fp1,Fpz', score: 96 },
    { nodes: 'Fp2,Fpz', score: 93 },
    { nodes: 'O1,O2', score: 83 },
    { nodes: 'O1,O2', score: 77 },
    { nodes: 'O1,O2', score: 72 },
    { nodes: 'O1,O2', score: 55 },
    { nodes: 'O1,O2', score: 55 },
    { nodes: 'O1,O2', score: 52 },
    { nodes: 'O1,O2', score: 47 },
    { nodes: 'PO7,O1', score: 47 },
    { nodes: 'O2,PO8', score: 45 },
    { nodes: 'Fp2,F8', score: 41 },
    { nodes: 'F8,FC6', score: 36 },
    { nodes: 'FC6,T4', score: 33 },
    { nodes: 'FC6,C4', score: 33 },
    { nodes: 'FTX,T4', score: 32 },
    { nodes: 'F7,FT9', score: 31 },
    { nodes: 'F7,T3', score: 31 },
    { nodes: 'T3,T5', score: 29 },
    { nodes: 'T4,T6', score: 28 },
    { nodes: 'T4,CP6', score: 28 },
    { nodes: 'CP6,T6', score: 27 },
    { nodes: 'P4,T6', score: 27 },
    { nodes: 'PO8,T6', score: 26 },
    { nodes: 'T5,O1', score: 26 },
    { nodes: 'CP1,Cz', score: 25 },
    { nodes: 'FC5,F3', score: 25 },
    { nodes: 'FC5,C3', score: 24 },
    { nodes: 'F7,F3', score: 23 },
    { nodes: 'Cz,Fp1,Fpz,Fp2,F7,F8,F3,F4,FT9,FTX,FC1,FC2,FC5,FC6,T3,T4,C3,C4,CP1,CP2,CP5,CP6,T5,T6,P3,Pz,P4,PO7,PO8,O1,O2', score: 23 },
  ],
}

export default weakStarburst

import { coreApi } from '@/services/api'

export const USER_TAG = 'User'

export const usersApi = coreApi
  .enhanceEndpoints({
    addTagTypes: [USER_TAG],
  })
  .injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
      getUserDetails: builder.query({
        query: (id) => `users/${id}/details`,
        providesTags: (result, error, arg) => error ? [] : [{ type: USER_TAG, id: arg }]
      }),
      searchUsers: builder.mutation({
        query: ({ limit, offset, email }) => ({
          url: 'users',
          method: 'GET',
          params: { limit, offset, email },
        }),
      }),
      updateUserDetails: builder.mutation({
        query: ({ id, ...patch }) => ({
          url: `users/${id}/details`,
          method: 'PUT',
          body: patch,
        }),
        invalidatesTags: (result, error, arg) => error ? [] : [{ type: USER_TAG, id: arg.id }],
      }),
      updateUserProfilePicture: builder.mutation({
        query: ({ id, formData }) => ({
          url: `users/${id}/images`,
          method: 'POST',
          body: formData,
        }),
        invalidatesTags: (result, error, arg) => error ? [] : [{ type: USER_TAG, id: arg.id }],
      })
    }),
  })

export const { useGetUserDetailsQuery, useSearchUsersMutation, useUpdateUserDetailsMutation, useUpdateUserProfilePictureMutation } = usersApi

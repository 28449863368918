import React from 'react'
import { useIntl } from 'react-intl'
import { Helmet as ReactHelmet } from 'react-helmet'

const COMPANY_NAME = 'Puzzlin'


Helmet.defaultProps = {
  title: COMPANY_NAME,
}

function Helmet ({ children, description, title }) {
  const intl = useIntl()
  const author = COMPANY_NAME
  let appName = COMPANY_NAME
  if (process.env.NODE_ENV === 'development') appName += ' Dev'
  else if (process.env.NODE_ENV === 'test') appName += ' Test'

  const titleTemplate = `%s | ${appName}`

  description = description || intl.formatMessage({
    defaultMessage: 'Explore, understand and accept differences to build trust and find balance.',
    description: 'Meta description',
  })


  return (
    <ReactHelmet htmlAttributes={{ lang: intl.locale }}
      title={title} titleTemplate={titleTemplate}>
      {process.env.NODE_ENV === 'production' ? null : <meta name='robots' content='noindex' />}
      <meta name='description' content={description} />
      <meta name='author' content={author} />
      <meta name='copyright' content={COMPANY_NAME} />
      <meta name='application-name' content={appName} />
      {/* Required Meta for Facebook */}
      {/* <meta property='og:title' content={title} /> */}
      <meta property='og:type' content='website' />
      {/* <meta property='og:image' content={config.company.logo.primary} /> */}
      {/* <meta property='og:url' content={config.app.domain} /> */}
      {/* Optional Meta for Facebook */}
      <meta property='og:description' content={description} />
      {/* <meta property='og:locale' content={localeWithTerritory} /> */}
      <meta property='og:site_name' content={title} />
      {/* <meta property='fb:app_id' content={config.facebook.appId} /> */}
      {/* Meta for Twitter */}
      <meta property='twitter:card' content='summary' />
      <meta property='twitter:creator' content={author} />
      <meta property='twitter:title' content={title} />
      <meta property='twitter:description' content={description} />
      {children}
    </ReactHelmet>
  )
}

export default Helmet
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  open: false,
  pattern: undefined,
}

export const wiringPatternDialogSlice = createSlice({
  name: 'wiringPatternDialog',
  initialState,
  reducers: {
    // Pass false to close, pass a pattern to open
    setOpen: (state, action) => ({
      ...state,
      open: !!action.payload,
      pattern: action.payload || undefined,
    }),
  },
})

export const { setOpen } = wiringPatternDialogSlice.actions

export const openSelector = ((state) => state.eegSessions.wiringPatternDialog.open)
export const patternSelector = ((state) => state.eegSessions.wiringPatternDialog.pattern)

export default wiringPatternDialogSlice.reducer

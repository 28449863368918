export const USER_ROLE_ADMIN = 'admin'
export const USER_ROLE_EXPLORER = 'explorer' // NOTE: Default role. Defined on front-end only
export const USER_ROLE_PRACTITIONER = 'practitioner'
export const USER_ROLE_RESEARCHER = 'researcher'

export const USER_ROLES = [
  USER_ROLE_ADMIN,
  USER_ROLE_EXPLORER,
  USER_ROLE_PRACTITIONER,
  USER_ROLE_RESEARCHER,
]

import FFT from 'fft.js'
import get from 'lodash/get'
import intersection from 'lodash/intersection'
import isEmpty from 'lodash/isEmpty'
import max from 'lodash/max'
import maxBy from 'lodash/maxBy'
import sortBy from 'lodash/sortBy'
import without from 'lodash/without'

import { 
  EEG_ANALYSIS_TOP_SKILLS_COUNT,
  EEG_ANALYSIS_VARIANT_HIGH_FREQ,
  EEG_ANALYSIS_VARIANT_LOW_FREQ,
  EEG_ANALYSIS_VARIANT_MIXED,
} from '@/features/eeg-sessions/store/propTypes/analyses'
import { isStarburst } from '@/features/eeg-sessions/components/BrainCanvas/utils'
import { EXECUTIVE_STYLES_IDS } from '@/features/eeg-sessions/store/propTypes/executiveStyles'
import {
  WIRING_TIERS_QUANTITIES,
  WIRING_TIERS_WEIGHTS,
} from '@/features/eeg-sessions/store/propTypes/wiring'

export const FFT_WINDOW_SIZE_IN_S = 1
export const FREQUENCY_MAX_IN_HZ = 45 // European data has power-line interference above 45 Hertz - low line = 8 Hz.
export const FREQUENCY_MIN_IN_HZ = 0.5
export const CHARTS_SAMPLING_RATE_IN_HZ = 2 // = one sample every 0.5 seconds
export const TOP_CIRCUITS_REGISTERED = 1000

export const EEG_ANALYSIS_VARIANT_CONFIGS = [
  {
    variant: EEG_ANALYSIS_VARIANT_HIGH_FREQ,
    maxFrequency: FREQUENCY_MAX_IN_HZ,
    minFrequency: 8,
  },
  {
    variant: EEG_ANALYSIS_VARIANT_LOW_FREQ,
    maxFrequency: 8,
    minFrequency: FREQUENCY_MIN_IN_HZ,
  },
  {
    variant: EEG_ANALYSIS_VARIANT_MIXED,
    maxFrequency: FREQUENCY_MAX_IN_HZ,
    minFrequency: FREQUENCY_MIN_IN_HZ,
  },
]

/**
 * Get EEG analysis from an EEG session given an analysis variant.
 *
 * @param {Array.<EEGAnalysis>} analyses
 * @param {EEGAnalysisVariant} variant
 * @return {EEGAnalysis}
 */
export function getAnalysis({ analyses } = {}, variant) {
  if (!analyses) return

  // If no variant is specified, return first available analysis.
  if (!variant) return analyses[0]

  return analyses.find((analysis = {}) => analysis.variant === variant)
}

/**
 * Retrieve variants from analyses.
 *
 * @param {Array.<EEGAnalysis>} analyses
 * @return {!Array.<string>} An array of analysis variants
 */
export function getAnalysesVariants({ analyses } = {}) {
  return (analyses || []).map(({ variant }) => variant)
}

/**
 * Parse nodes' string into an array of sensor IDs.
 * 
 * @param  {Object} circuit
 * @return {Array} an array of sensor IDs
 */
export function parseNodes(circuit = {}) {
  return circuit.nodes?.split(',')
}

/**
 * Compute score for a list of sensors based on given normalized scores.
 *
 * @param {!Array.<SensorIdEnum>} sensors List of sensor IDs
 * @param {!EEGAnalysis} analysis.circuits
 * @param {EEGAnalysis} analysis.normalizedScores
 * @return {!number}
 */
export function computeSensorsScore(ids, { circuits, normalizedScores } = {}) {
  if (isEmpty(normalizedScores))
    normalizedScores = computeNormalizedScores({ circuits })
  if (isEmpty(normalizedScores)) return

  return (
    ids.reduce((prev, id) => prev + get(normalizedScores, id, 0), 0) /
    ids.length
  )
}

/**
 * Compute normalized scores from circuits.
 * 
 * @param  {EEGAnalysis} analysis.circuits
 * @return {Object}
 */
export function computeNormalizedScores({ circuits } = {}) {
  if (!circuits) return

  // Compute absolute scores.
  const normalizedScores = circuits.reduce((acc, circuit) => {
    const nodes = parseNodes(circuit)
    for (const node of nodes) {
      if (!acc[node]) acc[node] = circuit.score
      else acc[node] += circuit.score
    }

    return acc
  }, {})

  // Normalize scores.
  const max = Math.max(...Object.values(normalizedScores))
  for (const id of Object.keys(normalizedScores)) {
    normalizedScores[id] = Number(
      (100 * (normalizedScores[id] / max)).toFixed(1)
    )
  }

  return normalizedScores
}

const EXCLUSIVE_TOP_SKILLS = [
  ['Fp1', 'Fp2'],
  ['O1', 'O2'],
  ['PO7', 'PO8'],
  // ['T5', 'T6'],
]

/**
 * Compute top skills from EEG session data if not already available.
 *
 * @param {!EEGAnalysis} analysis
 * @param {!number} top The number of top skills to return
 * @return {!Array} An array of sensor IDs
 */
export function getTopSkills({
  analysis: { circuits, normalizedScores, topSkills } = {},
  top,
}) {
  if (topSkills) return top ? topSkills.slice(0, top) : topSkills

  topSkills = computeTopSkillsFromCircuits({ analysis: { circuits }, top })

  // Remove exclusive skill pairs.
  for (const pair of EXCLUSIVE_TOP_SKILLS) {
    topSkills = without(topSkills, pair[pair[0] >= pair[1] ? 1 : 0])
  }

  // Slice top skills.
  topSkills = topSkills.slice(0, Math.min(top, topSkills.length))

  return topSkills
}

/**
 * Compute top skills from circuits.
 *
 * @param {!EEGAnalysis} analysis
 * @return {!Array} An array of sensor IDs sorted by circuits from top to bottom
 */
export function computeTopSkillsFromCircuits({ analysis: { circuits } = {} }) {
  let topSkills = []
  if (!circuits) return topSkills

  // Compute sensor score based on circuits' tiers.
  const scores = {}

  // Ensure circuits are sorted per descending order.
  circuits = sortBy(circuits, (o) => -o.score)

  let offset = 0
  for (let t = 0; t < WIRING_TIERS_QUANTITIES.length; t++) {
    const quantity = WIRING_TIERS_QUANTITIES[t]
    const weight = WIRING_TIERS_WEIGHTS[t]

    for (let q = offset; q < offset + quantity; q++) {
      const circuit = circuits[q]
      if (!circuit) continue

      // Increment sensor score with its tier's weight.
      const nodes = parseNodes(circuit)
      for (const node of nodes) {
        // NOTE: Add a small bias based on circuit's score to differentiate each sensor.
        // This assumes circuits' array is already sorted per descending score.
        scores[node] = (scores[node] || 0) + weight + 0.1 * ((circuits.length - q) / circuits.length)
      }
    }

    offset += quantity
  }

  // Sort sensors by descending score.
  const ids = Object.keys(scores)
  topSkills = sortBy(ids, (o) => -scores[o])

  return topSkills
}

/**
 * Retrieve top skills if available.
 * Otherwise compute executive styles from normalized sores.
 *
 * @param {!EEGAnalysis} analysis
 * @param {!Array} sensors List of sensor ids
 * @return {!string}
 */
export function getExecutiveStyle({ analysis = {}, sensors = [] }) {
  if (!analysis.normalizedScores) return
  if (!sensors || !~sensors.length) return

  let styles = EXECUTIVE_STYLES_IDS

  // Decision making vs Perceiving process
  if (analysis.normalizedScores['Fp1'] >= analysis.normalizedScores['Fp2'])
    styles = intersection(styles, ['expediters', 'refiners'])
  else styles = intersection(styles, ['energizers', 'experimenters'])

  // const rightScore = computeSensorsScore(rightIds, analysis)
  // const leftScore = computeSensorsScore(leftIds, analysis)
  // if (leftScore >= rightScore) styles = intersection(styles, ['expediters', 'refiners'])
  // else styles = intersection(styles, ['energizers', 'experimenters'])

  const frontIds = intersection(sensors, [
    'Fp1',
    'Fp2',
    'F7',
    'F3',
    'Fz',
    'F4',
    'F8',
  ])
  const frontScore = computeSensorsScore(frontIds, analysis)
  const backIds = intersection(sensors, [
    'O1',
    'O2',
    'T5',
    'P3',
    'Pz',
    'P4',
    'T6',
  ])
  const backScore = computeSensorsScore(backIds, analysis)
  if (frontScore >= backScore)
    styles = intersection(styles, ['expediters', 'energizers'])
  else styles = intersection(styles, ['refiners', 'experimenters'])

  return styles[0]
}

/**
 * Compute amplitudes and frequencies from EDF channels.
 * TODO: Parallelize jobs.
 * 
 * @param {!Array.<EdfChannel>} channels
 * @return {!Object} - { amplitudes: number[], frequencies: number[] }
 */
export function computeAmplitudesAndFrequencies (channels) {
  const amplitudes = []
  const frequencies = []

  const sampleCount = channels[0]?.amplitudes.length
  const samplingRate = channels[0]?.samplingRate
  if (!sampleCount || !samplingRate) return { amplitudes, frequencies }

  // Prepare FFT items
  // NOTE: Window size is the length of input given to the FFT. Must be a power of 2.
  const windowSize = samplingRate * FFT_WINDOW_SIZE_IN_S

  const fft = new FFT(windowSize)
  let complexOutput = fft.createComplexArray()
  let output = new Array(windowSize)
  
  // Do not mutate input channels.
  const fftChannels = []
  for (const channel of channels) {
    fftChannels.push({
      ...channel,
      maxAmplitudes: [],
      frequencies: [],
    })
  }

  // NOTE: To retrieve a frequency from FFT, we use the following formula:
  // f = k * R / N, where f is frequency, k is the index in the output array, R a sampling rate, and N the window size.
  const frequencyStep = (samplingRate / windowSize) // = R / N

  // Hence to calculate required index, we can use the follow formula: k = f * N / R.
  const minFrequencyIndex = Math.floor(FREQUENCY_MIN_IN_HZ / frequencyStep) + 1; // NOTE: Make sure 0 Hz is ignored.
  const maxFrequencyIndex = Math.floor(FREQUENCY_MAX_IN_HZ / frequencyStep) + 1;
  
  const chartIndexStep = samplingRate / CHARTS_SAMPLING_RATE_IN_HZ
  if (chartIndexStep < 1) throw new Error(`Unexpected sampling rate in EDF: ${samplingRate}.`)
  
  for (let i = 0, j = 0; i < sampleCount; i = i + chartIndexStep, j++) {
    // TODO: Add an offset to compute within [t-dt/2;t+dt/2[ instead of [t;t+dt]?
    const start = i
    const end = i + windowSize
    if (end >= sampleCount) break

    // Compute FFT for each channel of each chart sample.
    for (const channel of fftChannels) {
      const input = channel.amplitudes.slice(start, end)
      fft.realTransform(complexOutput, input)
      fft.fromComplexArray(complexOutput, output)

      // Retrieve frequencies' highest amplitude.
      const slicedOutput = output.slice(minFrequencyIndex, maxFrequencyIndex)
      const maxAmplitude = max(slicedOutput) 
      channel.maxAmplitudes.push(Math.abs(maxAmplitude) / windowSize)

      // Retrieve frequency with highest amplitude.
      const frequency = (slicedOutput.indexOf(maxAmplitude) + 1 /* 0 Hz offset */) * frequencyStep
      channel.frequencies.push(frequency)
    }

    // Retrieve frequency and amplitude of the channel with highest amplitude.
    const maxFftChannel = maxBy(fftChannels, (channel) => channel.maxAmplitudes[j])
    
    amplitudes.push(maxFftChannel.maxAmplitudes[j])
    frequencies.push(maxFftChannel.frequencies[j])
  }

  return { amplitudes, frequencies }
}

/**
 * Compute EEG analyses from EDF channels.
 * TODO: Parallelize jobs.
 * 
 * @param {!Array.<EdfChannel>} channels
 * @param {!Array.<SensorIdEnum>} sensors
 * @return {!Array.<EegAnalysis>}
 */
export function computeAnalyses (channels, sensors) {
  const analyses = []

  const AMPLITUDE_OFFSET_IN_UV = 4100
  const NEGATIVE = -1
  const POSITIVE = 1
  const samplingRate = channels[0]?.samplingRate

  for (const variantConfig of EEG_ANALYSIS_VARIANT_CONFIGS) {
    // Compute summits.
    const summits = new Array(channels[0]?.amplitudes.length)

    for (const channel of channels) {
      let amplitude, previousAmplitude, summitAmplitude, troughAmplitude = 0  
      let previousSummitIndex = 0
      // Unused variables from Perl script: let maxAmplitude = 0, minAmplitude = 100000
      let previousSlope, slope
      
      for (let i = 0; i < channel.amplitudes.length; i++) {
        previousAmplitude = amplitude
        previousSlope = slope
        // Skip first sample.
        if (!i) continue 
        
        // Find local slope.
        amplitude = channel.amplitudes[i] + AMPLITUDE_OFFSET_IN_UV // Make sure all values above 0.
        slope = amplitude > previousAmplitude ? POSITIVE : NEGATIVE
        
        // Compare to min and max.
        // Unused variable from Perl script: maxAmplitude = Math.max(amplitude, maxAmplitude)
        // Unused variable from Perl script: minAmplitude = Math.min(amplitude, minAmplitude)
        
        if (previousSlope === NEGATIVE && slope === POSITIVE) { // At trough
          troughAmplitude = previousAmplitude
          // Unused variable from Perl script: lowIndex = i-1
        } else if (previousSlope === POSITIVE && slope === NEGATIVE) { // At summit
          const summitDt = (i - previousSummitIndex) / (samplingRate * 2)
          const hertz = Math.trunc(2 / summitDt) // TODO: What does this factor 2?
          
          if (hertz >= variantConfig.minFrequency && hertz <= variantConfig.maxFrequency) {
            summitAmplitude = Math.trunc(amplitude - troughAmplitude) // TODO: What do we truncate for?
  
            if (summitAmplitude > 0) {
              if (!summits[i]) summits[i] = []
              summits[i].push(channel.id)
              
              previousSummitIndex = i
            }
          }
        }
      }
    }

    // TODO: Double check original algorithm to compute scores.
    // There is some missing logic playing with limits.

    // Compute hits for circuits and channels from summits
    const channelHits = []
    let circuits = []
    for (let i = 0; i < summits.length; i++) {
      const channelIds = summits[i]

      // Do not count circuits with less than 2 nodes.
      if (!(channelIds?.length >= 2)) continue
      
      // Sort nodes to ensure unicity for a set of nodes, regardless of the order.
      channelIds.sort()

      // Compute circuit hits.
      const nodes = channelIds.join(',')
      const circuit = circuits.find((circuit) => circuit.nodes === nodes)
      if (!circuit) circuits.push({ nodes, hits: 1 })
      else circuit.hits++

      // Compute channel hits.
      for (const id of channelIds) {
        const channel = channelHits.find((channel) => channel.id === id)
        if (!channel) channelHits.push({ id, hits: 1 })
        else channel.hits++
      }
    }

    // Retrieve top circuit hits.
    const topCircuitHits = maxBy(circuits, (circuit) => circuit.hits).hits
    
    // Compute normalized circuit scores.
    circuits = sortBy(circuits, (circuit) => circuit.hits)
    circuits.reverse()
    
    // Keep top 1000 circuits only.
    circuits = circuits.slice(0, Math.min(TOP_CIRCUITS_REGISTERED, circuits.length))
    
    for (const circuit of circuits) {
      circuit.score = circuit.hits / topCircuitHits * 100

      // Clean up circuits.
      // delete circuit.hits
    }    

    // Format normalized scores for each channel.
    const normalizedScores = {}
    const maxChannelHits = maxBy(channelHits, (channel) => channel.hits).hits
    channelHits.forEach((channel) => {
      normalizedScores[channel.id] = Math.trunc(channel.hits / maxChannelHits * 1000) / 10
    })

    const analysis = {
      circuits,
      normalizedScores,
      variant: variantConfig.variant,
    }
    
    analyses.push({
      ...analysis,
      executiveStyle: getExecutiveStyle({ analysis, sensors }),
      topCircuitHits,
      topSkills: getTopSkills({ analysis, top: EEG_ANALYSIS_TOP_SKILLS_COUNT }),
      // Not handled yet: topWiringPatterns
    })
  }

  return analyses
}
/**
 * Get period of the top circuit.
 */
export function getTopCircuitDetails(analysis, duration) {
  const circuit = sortBy(analysis?.circuits || [], ({ score }) => -score)[0]

  const period =
    !duration || !analysis?.topCircuitHits
      ? undefined
      : duration / analysis.topCircuitHits

  return { circuit, period }
}

/**
 * Get top starburst details for a given analysis.
 */
export function getTopStarburstDetails(analysis, duration) {
  let circuit
  let period
  let ranking = analysis?.circuits?.length ? 0 : undefined

  const circuits = sortBy(analysis?.circuits || [], ({ score }) => -score)
  const topCircuitPeriod = getTopCircuitDetails(analysis, duration)?.period
  for (const c of circuits) {
    ranking++
    if (isStarburst(c)) {
      circuit = c
      period = topCircuitPeriod / (circuit.score / 100)
      break
    }
  }

  if (!circuit) ranking = undefined

  return { circuit, period, ranking }
}

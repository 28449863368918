import PropTypes from 'prop-types'

// Validate (m)mm:ss time format
function timeProp (props, propName, componentName) {
  if (!/\d{2,3}:\d{2}/.test(props[propName])) {
    return new Error(`Invalid prop ${propName} supplied to ${componentName}. Validation failed.`)
  }
}

export const activityShape = PropTypes.shape({
  from: timeProp.isRequired,
  observation: PropTypes.string,
  title: PropTypes.string.isRequired,
  to: timeProp.isRequired,
})

/**
 * Convert a time to seconds from 00:00
 * @param {String} time - (m)mm:ss time format
 * @returns {Number} - in seconds
 */
export function timeToSeconds(time) {
  if (!time) return

  time = time.split(':')
  const minutes = parseInt(time[0])
  const seconds = parseInt(time[1])
  return minutes * 60 + seconds
}
import React from 'react'
import FormatQuoteIcon from '@mui/icons-material/FormatQuote'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'

export const QuoteMark = styled(Box)(({ theme }) => ({
  position: 'absolute',
  textShadow: '7px 10px 10px rgb(0 0 0 / 21%)',
  top: '-100px',
}))

export function BlockQuote ({author, quote }) {
  return (
    <Paper sx={{ px: 3, py: 5 }}>
      <blockquote style={{ position: 'relative', margin: 0 }}>
        <QuoteMark>
          <FormatQuoteIcon color='primary' sx={{ fontSize: 120 }} />
        </QuoteMark>
        <Typography component='div' variant='h4'>
          {quote}
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
          {author}
        </Box>
      </blockquote>
    </Paper>
  )
}

export default BlockQuote

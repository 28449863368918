import React from 'react'
import { FormattedMessage } from 'react-intl'
import EmailIcon from '@mui/icons-material/Email'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import PhoneIcon from '@mui/icons-material/Phone'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'

import FooterItem from './FooterItem'
import BodyContainer from '@/features/ui/components/BodyContainer'
import Brand from '@/features/ui/components/Brand'
import FooterHeader from '@/features/ui/components/FooterHeader'
import Heading from '@/features/ui/components/Heading'

const GRID_SPACING = 10
const ITEM_STACK_SPACING = 2

function Footer () {
  const matchesMD = useMediaQuery((theme) => theme.breakpoints.only('md'))

  return (
    <React.Fragment>
      <FooterHeader />
      <BodyContainer>
        <Grid container spacing={GRID_SPACING}>
          {/* Branding */}
          <Grid item xs={12} md={6} lg={4}>
            <Stack spacing={2} sx={{ alignItems: 'flex-start' }}>
              <Brand size='large' />
              <Typography component='div' variant='body1'>
                <FormattedMessage defaultMessage='We help people explore, understand and accept differences to build trust and find balance.' description='Footer message' />
              </Typography>
              <Stack spacing={ITEM_STACK_SPACING}>
                <FooterItem icon={<LocationOnIcon />}
                  message={<FormattedMessage defaultMessage="100 Avenue de l'Adour, 64600 Anglet, France" description='Footer company address' />} />
                <FooterItem icon={<PhoneIcon />}
                  message={<FormattedMessage defaultMessage="+33 6 95 01 85 51" description='Footer company phone number' />} />
                <FooterItem icon={<EmailIcon />}
                  message={<FormattedMessage defaultMessage="go@puzzlin.org" description='Footer email address' />} />
              </Stack>
            </Stack>
          </Grid>
          {!matchesMD ? null : (
            <Grid item xs={0} md={6} lg={0} />
          )}
          {/* Services */}
          <Grid item xs={12} md={6} lg={4}>
              <Heading component='div' variant='h4' gutterBottom
                title={<FormattedMessage defaultMessage='Explore your differences' description='Heading title' />} />
              <Stack spacing={ITEM_STACK_SPACING}>
                <FooterItem href='/brain-imaging'
                  message={<FormattedMessage defaultMessage='Brain imaging' description='Footer item label' />} />
                <FooterItem href='/workshops-and-epics'
                  message={<FormattedMessage defaultMessage='Workshops & Epics' description='Footer item label' />} />
                <FooterItem href='/seminars'
                  message={<FormattedMessage defaultMessage='Seminars' description='Footer item label' />} />
              </Stack>
          </Grid>
          {/* Community */}
          <Grid item xs={12} md={6} lg={4}>
              <Heading component='div' variant='h4' gutterBottom
                title={<FormattedMessage defaultMessage='Join us' description='Heading title' />} />
              <Stack spacing={ITEM_STACK_SPACING}>
                <FooterItem href='/contact-us'
                  message={<FormattedMessage defaultMessage='Contact us' description='Footer item label' />} />
                <FooterItem href='/login'
                  message={<FormattedMessage defaultMessage='Login' description='Footer item label' />} />
                <FooterItem href='/signup'
                  message={<FormattedMessage defaultMessage='Signup' description='Footer item label' />} />
              </Stack>
          </Grid>
        </Grid>
      </BodyContainer>
      <Divider />
      <BodyContainer sx={{ display: 'flex', justifyContent: 'center', py: 3, textTransform: 'uppercase' }}>
        <Typography component='div' variant='body2'>
          <FormattedMessage defaultMessage='© Copyright - Puzzlin' description='Footer message' />
        </Typography>
      </BodyContainer>
    </React.Fragment>
  )
}

export default Footer

import React from 'react'
import { FormattedMessage } from 'react-intl'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'

import { br } from '@/features/intl/utils/intl'
import PriceTable from '@/features/ui/components/PriceTable'
import PriceTableDescription from '@/features/ui/components/PriceTableDescription'
import PriceTablePrice from '@/features/ui/components/PriceTablePrice'
import PriceTableTitle from '@/features/ui/components/PriceTableTitle'

function BrainImagingExtras () {
  return (
    <PriceTable container spacing={1}>
      {/* One-on-one debrief */}
      <PriceTable item xs={12} md={4} highlight>
        <PriceTableTitle>
          <FormattedMessage defaultMessage='One-on-one{br}debrief' description='PriceTable title' values={{ br }} />
        </PriceTableTitle>
        <PriceTablePrice price={100} discount={50}
          pricePer={<FormattedMessage defaultMessage='per hour' />} />
        <PriceTableDescription sx={{ flexGrow: 1 }}>
          <FormattedMessage defaultMessage="You've had a brain imaging session, been through your web report and have so many questions you can't sleep at night? Feel free to book a meeting with us, on-site or remotely, we're here to help." />
        </PriceTableDescription>
        <Button href='/contact-us' variant='outlined'>
          <FormattedMessage defaultMessage='I want it' description='Button label' />
        </Button>
      </PriceTable>
      {/* Team brain */}
      <PriceTable item xs={12} md={4} highlight>
        <PriceTableTitle>
          <FormattedMessage defaultMessage='Team{br}brain' description='PriceTable title' values={{ br }} />
        </PriceTableTitle>
        <PriceTablePrice price={200} discount={100}
          pricePer={<FormattedMessage defaultMessage='per team' />} />
        <PriceTableDescription sx={{ flexGrow: 1 }}>
          <FormattedMessage defaultMessage='What happens when we combine our brain activities? If your team was a single person, would it be more analytical or holistic? Decisive or open-ended? How would we balance things up?' />
        </PriceTableDescription>
        <Button href='/contact-us' variant='outlined'>
          <FormattedMessage defaultMessage='This is ours' description='Button label' />
        </Button>
      </PriceTable>
      {/* Inspiration */}
      <PriceTable item xs={12} md={4} highlight>
        <PriceTableTitle>
          <FormattedMessage defaultMessage='Creative{br}inspiration' description='PriceTable title' values={{ br }} />
        </PriceTableTitle>
        <Box sx={{ fontSize: '5rem' }}>💡</Box>
        <PriceTableDescription sx={{ flexGrow: 1 }}>
          <FormattedMessage defaultMessage='Not sure where to get started with your friends or company to explore differences? We handpicked for you a few experiences to open up ourselves.' />
        </PriceTableDescription>
        <Button href='/contact-us' variant='outlined'>
          <FormattedMessage defaultMessage='Ready for inspiration' description='Button label' />
        </Button>
      </PriceTable>
    </PriceTable>
  )
}

export default BrainImagingExtras

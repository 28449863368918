import React from 'react'
import { useParams } from 'react-router'
import { FormattedMessage } from 'react-intl'

import Report from '@/features/eeg-sessions/components/Report'
import ReportTabs from '@/features/eeg-sessions/components/ReportTabs'
import { useGetEEGSessionQuery } from '@/features/eeg-sessions/services/api'
import { br } from '@/features/intl/utils/intl'
import Header from '@/features/ui/components/Header'
import Helmet from '@/features/ui/components/Helmet'
import { useGetUserDetailsQuery } from '@/features/users/services/api'

function ReportView () {
  const { sessionId } = useParams()

  const { data: session, isLoading: isSessionLoading } = useGetEEGSessionQuery(sessionId, { skip: !sessionId })

  // Retrieve user information
  const { userId } = session || {}
  const { data: userDetails, isLoading: areUserDetailsLoading } = useGetUserDetailsQuery(userId, { skip: !userId })

  const isLoading = isSessionLoading || areUserDetailsLoading

  return (
    <React.Fragment>
      <Helmet
        title={userDetails && `${userDetails.firstName} ${userDetails.lastName}`} />
      <ReportTabs omit={session?.amplitudes? undefined : 'engagement'} />
      <Header id='introduction' component='h1' variant='h1'
        title={<FormattedMessage defaultMessage='Your brain-savvy{br}personality & skills{br}profile' description='Header title' values={{ br}} />} />
      <Report isLoading={isLoading} session={session} userDetails={userDetails} />
    </React.Fragment>
  )
}

export default ReportView

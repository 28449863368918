import cloneDeep from 'lodash/cloneDeep'

import { SENSORS_MIRRORS } from './mirrors'

export const SPACER = 90
const Cz = { x: 500, y: 520 }

/**
 * Compute sensors' coordinates for mirror sensors
 */
export function mirrorCoordinates (config) {
  config = cloneDeep(config) // Do not mutate entry

  for (const sensorId of Object.keys(config)) {
    const sensor = config[sensorId]

    const mirrorId = SENSORS_MIRRORS[sensorId]
    if (!mirrorId) continue

    // Add coordinates for mirror sensor
    config[mirrorId] = {
      x: 500 + (config.Cz.x - sensor.x),
      y: sensor.y,
    }
  }

  return config
}

export const SENSORS_COORDINATES = mirrorCoordinates({
  'Fp1': { x: Cz.x - 140, y: 170 },
  'Fpz': { x: Cz.x, y: 135 },
  'F7': { x: Cz.x - 3 * SPACER, y: Cz.y - (2 * SPACER + 55) },
  'F3': { x: Cz.x - (2 * SPACER - 30), y: Cz.y - (2 * SPACER + 10) },
  'Fz': { x: Cz.x, y: Cz.y - 2 * SPACER },
  'FT9': { x: Cz.x - (3.5 * SPACER + 5), y: Cz.y - (SPACER + 30) },
  'FC5': { x: Cz.x - 2.5 * SPACER, y: Cz.y - (SPACER + 10) },
  'FC1': { x: Cz.x - SPACER, y: Cz.y - SPACER },
  'T3': { x: Cz.x - (4 * SPACER - 20), y: Cz.y + 30 },
  'C3': { x: Cz.x - 2 * SPACER, y: Cz.y + 10 },
  'Cz': Cz,
  'CP5': { x: Cz.x - 2.5 * SPACER, y: Cz.y + (SPACER + 30) },
  'CP1': { x: Cz.x - SPACER, y: Cz.y + SPACER },
  'T5': { x: Cz.x - 3.5 * SPACER, y: Cz.y + 2.7 * SPACER },
  'P3': { x: Cz.x - (2 * SPACER - 30), y: Cz.y + (2 * SPACER + 20) },
  'Pz': { x: Cz.x, y: Cz.y + 2 * SPACER },
  'PO7': { x: Cz.x - 2.5 * SPACER, y: 910 - 40 },
  'O1': { x: Cz.x - 100, y: 910 },
})

import { createSlice } from '@reduxjs/toolkit'

import { userRolesArraySelector } from '@/features/auth/store/reducers/tokens'
import { USER_ROLE_EXPLORER } from '@/features/users/store/propTypes/roles'

export const DEFAULT_USER_ROLE = USER_ROLE_EXPLORER

const initialState = {
  activeRole: localStorage.getItem('ActiveUserRole') || DEFAULT_USER_ROLE,
}

export const userRoleSlice = createSlice({
  name: 'userRole',
  initialState,
  reducers: {
    setActiveRole: (state, action) => {
      const { activeRole } = action.payload

      // TODO: Move this out of the reducer
      // Move it to a thunk or some other solution for side-effect
      localStorage.setItem('ActiveUserRole', activeRole)

      return { ...state, activeRole }
    },
  },
})

export const { setActiveRole } = userRoleSlice.actions

// WNG: Ensure user role is only used when user has more roles than a default "user"
export const activeRoleSelector = (state) => {
  const userRoles = userRolesArraySelector(state)
  const { activeRole } = state.ui.userRole
  
  return ~userRoles.indexOf(activeRole) ? activeRole : DEFAULT_USER_ROLE
}

export default userRoleSlice.reducer

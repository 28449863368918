import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'

import { analysisVariantShape } from '@/features/eeg-sessions/store/propTypes'
import { EEG_ANALYSIS_VARIANTS } from '@/features/eeg-sessions/store/propTypes/analyses'
import { messages as intlAnalysisVariants } from '@/features/eeg-sessions/messages/analysisVariants.msg'

function AnalysisVariantsSelect ({ id, onChange, value, options, variant, ...otherProps }) {
  id = id ? `${id}-label` : undefined
  
  return (
    <FormControl variant={variant} {...otherProps}>
      <InputLabel id={id}>
        <FormattedMessage defaultMessage='Recorded modes' />
      </InputLabel>
      <Select id={id} labelId={id}
        value={value} onChange={onChange}
        label={<FormattedMessage defaultMessage='Recorded modes' />}>
        {(options || []).map((v) => (
          <MenuItem key={v} value={v}>
            <FormattedMessage {...intlAnalysisVariants.titles[v]} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

AnalysisVariantsSelect.propTypes = {
  id: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(analysisVariantShape).isRequired,
  value: analysisVariantShape,
  variant: PropTypes.string,
}

AnalysisVariantsSelect.defaultProps = {
  options: EEG_ANALYSIS_VARIANTS,
}

export default AnalysisVariantsSelect

import React from 'react'
import Container from '@mui/material/Container'
import { useTheme } from '@mui/material/styles'

function BodyContainer (props) {
  const theme = useTheme()

  return (
    <Container {...props}
      sx={{
        py: 10,
        [theme.breakpoints.down('sm')]: { py: 5 },
        ...(props.sx || {}),
      }} />
  )
}

export default BodyContainer

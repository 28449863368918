import { blueGrey, green, lightGreen, lime, teal, yellow } from '@mui/material/colors'

// import { WIRING_TIERS_QUANTITIES } from '@/features/eeg-sessions/store/propTypes/wiring'
import LinkBehavior from '@/features/mui/components/LinkBehavior'

const diamondColors = [lightGreen, green, teal, lime]
const starburstColors = [yellow]

const breakpoints = {
  values: { // NOTE: Default values
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1536,
  },
}

const palette = (light) => ({
  background: {
    paper: light ? '#FFFFFF' : '#111111',
    default: light ? '#FFFFFF' : '#111111',
  },
  divider: light ? 'rgba(0,0,0,0.12)' : 'rgba(255,255,255,0.12)',
  primary: {
    main: '#FF9911',
    light: '#FFDFC0',
    dark: '#D87D0F',
  },
  secondary: {
    main: '#D447AB',
    light: '#EFABDF',
    dark: '#99378B',
  },
  text: {
    primary: light ? 'rgba(0,0,0,0.87)' : '#FFFFFF',
    secondary: light ? 'rgba(0,0,0,0.6)' : '#9C9C9C',
    disabled : light ? 'rgba(0,0,0,0.38)' : 'rgba(255,255,255,0.5)',
    // icon: light ? 'rgba(0,0,0,0.38)' : 'rgba(255,255,255,0.5)',
  },
})

const headingStyles = (light) => ({
  color: light ? palette(light).text.primary : palette(light).primary.light,
  fontWeight: 800,
  letterSpacing: '1px',
  textTransform: 'uppercase',
})

export function getDesignTokens (mode) {
  const light = mode === 'light'

  return {
    breakpoints,
    mode,
    palette: {
      mode,
      ...palette(light),
    },
    typography: {
      fontFamily: [
        'Poppins',
        'Roboto',
        'Arial',
        'sans-serif',
      ].join(','),
      fontSize: 14,
      htmlFontSize: 16,
      h1: {
        ...headingStyles(light),
        fontSize: '4.5rem',
        textAlign: 'center',
        [`@media (max-width:${breakpoints.values['md'] - 1}px)`]: { fontSize: '3.5rem' },
        [`@media (max-width:${breakpoints.values['sm'] - 1}px)`]: { fontSize: '2rem' },
      },
      h2: { // NOTE: Copy of h1
        ...headingStyles(light),
        fontSize: '4.5rem',
        textAlign: 'center',
        [`@media (max-width:${breakpoints.values['md'] - 1}px)`]: { fontSize: '3.5rem' },
        [`@media (max-width:${breakpoints.values['sm'] - 1}px)`]: { fontSize: '2rem' },
      },
      h3: {
        ...headingStyles(light),
        fontSize: '2.5rem',
        [`@media (max-width:${breakpoints.values['sm'] - 1}px)`]: { fontSize: '1.5rem' },
      },
      h4: {
        ...headingStyles(light),
        fontSize: '1.5rem',
        [`@media (max-width:${breakpoints.values['sm'] - 1}px)`]: { fontSize: '1rem' },
      },
      h5: {
        ...headingStyles(light),
        fontSize: '1rem',
      },
      h6: {
        ...headingStyles(light),
        fontSize: '1rem',
      },
    },
    ...getCustomDesignTokens(light),
    ...getComponentsDesignTokens(),
  }
}

export function getComponentsDesignTokens () {
  return {
    components: {
      MuiButton: {
        styleOverrides: {
          contained: {
            borderRadius: 24, // NOTE: Just make it round
            letterSpacing: '1px',
          },
          outlined: {
            letterSpacing: '1px',
            borderRadius: 24, // NOTE: Just make it round
            border: '2px solid',
          },
          text: {
            letterSpacing: '1px',
          },
        },
      },
      MuiButtonBase: {
        defaultProps: {
          LinkComponent: LinkBehavior,
        },
      },
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            WebkitPrintColorAdjust: 'exact',
            // color: '#ffffff',
            // backgroundColor: '#111111',
            // "& h1": {
            //   color: "black"
            // }
          }
        }
      },
      MuiLink: {
        defaultProps: {
          component: LinkBehavior,
        },
      },
    },
  }
}

export function getCustomDesignTokens (light) {
  return {
    brainCanvas: {
      head: {
        fill: light ? 'none' : 'white',
        stroke: light ? 'black' : blueGrey[50],
      },
      heatmap: {
        stroke: light ? blueGrey[50] : blueGrey[50],
      },
      wiring: [
        {
          default: 'red',
          diamonds: diamondColors.map((c) => c[500]),
          starbursts: starburstColors.map((c) => c['A700']),
        }, {
          default: 'black', // light ? 'black' : blueGrey[50],
          diamonds: diamondColors.map((c) => c[300]),
          starbursts: starburstColors.map((c) => c[300]),
        }, {
          default: blueGrey[100], // light ? blueGrey[100] : blueGrey[500],
          diamonds: diamondColors.map((c) => c[100]),
          starbursts: starburstColors.map((c) => c[100]),
        },
      ],
    },
  }
}

// if (WIRING_TIERS_QUANTITIES.length > getCustomDesignTokens(true).brainCanvas.wiring.length) throw new Error('Unexpected length for WIRING_TIERS_QUANTITIES to compute theme.')

export default getDesignTokens

import React from 'react'
import { FormattedMessage } from 'react-intl'
import BatteryCharging90Icon from '@mui/icons-material/BatteryCharging90'
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact'
import ExtensionIcon from '@mui/icons-material/Extension'
import Grid from '@mui/material/Grid'

import { br } from '@/features/intl/utils/intl'
import IconBox from '@/features/ui/components/IconBox'

export function Benefits () {
  return (
    <Grid container spacing={10}>
      <Grid item xs={12} md={4}>
        <IconBox icon={<ExtensionIcon />}
          title={<FormattedMessage defaultMessage='Stress{br}management' values={{ br }} />}
          text={<FormattedMessage defaultMessage='We give you tools to understand tensions that operate within us and learn how to adapt to your environment.' />} />
      </Grid>
      <Grid item xs={12} md={4}>
        <IconBox icon={<ConnectWithoutContactIcon />}
          title={<FormattedMessage defaultMessage='Interpersonal{br}communication' values={{ br }} />}
          text={<FormattedMessage defaultMessage='Understand mechanisms, perspectives and distance between people to facilitate the exchange of information and emotions.' />} />
      </Grid>
      <Grid item xs={12} md={4}>
        <IconBox icon={<BatteryCharging90Icon />}
          title={<FormattedMessage defaultMessage='Personal & collective{br}growth' values={{ br }} />}
          text={<FormattedMessage defaultMessage='We help you with work fulfillment, leadership, personality profiling and development.' />} />
      </Grid>
    </Grid>
  )
}

export default Benefits

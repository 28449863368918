import React from 'react'
import { useSelector } from 'react-redux'
import { FormattedMessage, useIntl } from 'react-intl'
import AddIcon from '@mui/icons-material/Add'
import Box from '@mui/material/Box'
import Fab from '@mui/material/Fab'
import Grid from '@mui/material/Grid'
import Skeleton from '@mui/material/Skeleton'
import { styled, useTheme } from '@mui/material/styles'

import { userIdSelector } from '@/features/auth/store/reducers/tokens'
import ReportCard from '@/features/eeg-sessions/components/ReportCard'
import { useSearchEEGSessionsQuery } from '@/features/eeg-sessions/services/api'
import BodyContainer from '@/features/ui/components/BodyContainer'
import Header from '@/features/ui/components/Header'
import Heading from '@/features/ui/components/Heading'
import Helmet from '@/features/ui/components/Helmet'
import Pagination from '@/features/ui/components/Pagination'
import { activeRoleSelector } from '@/features/ui/store/reducers/userRole'
import { USER_ROLE_ADMIN, USER_ROLE_EXPLORER, USER_ROLE_PRACTITIONER, USER_ROLE_RESEARCHER } from '@/features/users/store/propTypes/roles'
import { usePaginationParams } from 'features/ui/components/Pagination'

export const createReportHref = '/account/brain-imaging/reports/create'

const StyledFab = styled(Fab)({
  position: 'fixed',
  zIndex: 1,
  left: 0,
  right: 0,
  bottom: 24,
  marginLeft: 'auto',
  marginRight: 'auto',
})

export const PER_PAGE = 10

function AccountReportsView () {
  const intl = useIntl()
  const theme = useTheme()
  const activeRole = useSelector(activeRoleSelector)
  const userId = useSelector(userIdSelector)
  const [paginationParams] = usePaginationParams({ perPage: PER_PAGE })
  
  // Search for EEG sessions for a userId or a practitionerId
  let params = { ...paginationParams }

  switch (activeRole) {
    case USER_ROLE_ADMIN:
    case USER_ROLE_RESEARCHER:
      break
    case USER_ROLE_PRACTITIONER:
      params = { ...params, practitionerId: userId }
      break
    case USER_ROLE_EXPLORER:
    default:
      params = { ...params, userId }
  }

  const { data: { pageCount, data: sessions = [] } = {}, isFetching } = useSearchEEGSessionsQuery(params, { skip: !userId })

  // Set page title
  let title
  switch (activeRole) {
    case USER_ROLE_ADMIN:
    case USER_ROLE_RESEARCHER:
      title = <FormattedMessage defaultMessage="Access all customers' sessions" description='Heading title' />
      break
    case USER_ROLE_PRACTITIONER:
      title = <FormattedMessage defaultMessage="Access all your customers' sessions" description='Heading title' />
      break
    case USER_ROLE_EXPLORER:
    default:
      title = <FormattedMessage defaultMessage='Explore strategies you have adopted over the years' />
  }

  const canEdit = !!~[USER_ROLE_ADMIN, USER_ROLE_PRACTITIONER].indexOf(activeRole)
  const canDelete = !!~[USER_ROLE_ADMIN, USER_ROLE_PRACTITIONER].indexOf(activeRole)
  const withUserDetails = !!~[USER_ROLE_ADMIN, USER_ROLE_PRACTITIONER, USER_ROLE_RESEARCHER].indexOf(activeRole)

  return (
    <React.Fragment>
      <Helmet
        title={intl.formatMessage({ defaultMessage: 'Brain imaging sessions', description: 'Meta title' })} />
      <Header component='h1' variant='h1' narrow
        title={<FormattedMessage defaultMessage="Brain imaging sessions" description='Header title' />} />
      <BodyContainer>
        <Heading component='h2' variant='h3' gutterBottom
          sx={{ [theme.breakpoints.up('lg')]: { maxWidth: '75%' } }}
          title={title} />
        <Grid container spacing={3}>
          {isFetching ? (
            [...Array(4).keys()].map((o, i) => (
              <Grid item key={i} xs={12} sm={6}>
                <Skeleton variant='rectangular' height={120} />
              </Grid>
            ))
          ) : sessions.map((session) => (
            <Grid item key={session.id} xs={12} sm={6}>
              <ReportCard session={session} withUserDetails={withUserDetails}
                canEdit={canEdit} canDelete={canDelete} />
            </Grid>
          ))}
        </Grid>
        {(isFetching || sessions?.length) ? null : (
          <FormattedMessage defaultMessage='This is your space! Your brain imaging report will appear here soon.' />
        )}
        {(isFetching || pageCount <= 1) ? null : (
          <Box sx={{ display: 'flex', justifyContent: 'center', py: 3 }}>
            <Pagination count={pageCount} color='primary' />
          </Box>
        )}
        <StyledFab href={createReportHref} color='secondary'>
          <AddIcon />
        </StyledFab>
      </BodyContainer>
    </React.Fragment>
  )
}

export default AccountReportsView

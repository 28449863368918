import React from 'react'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'

function FileUploadTextField ({ accept, id, onChange, value, ...otherProps }) {
  return (
    <TextField id={id} disabled value={value} InputProps={{
      endAdornment: (
        <InputAdornment position='end'>
          <label htmlFor={`${id}-input`}>
            <input type='file' accept={accept} id={`${id}-input`} style={{ display: 'none' }}
              multiple={false} onChange={onChange} />
            <IconButton color='inherit' component='span'>
              <FileUploadIcon />
            </IconButton>
          </label>
        </InputAdornment>
      )}} {...otherProps} />
  )
}

export default FileUploadTextField

import PropTypes from 'prop-types'
import React from 'react'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import isEmpty from 'lodash/isEmpty'

export const StyledBox = styled(Box)(({ image, theme }) => ({
  position: 'relative',
  height: '100%',
  backgroundColor: 'transparent',
  backgroundImage: `linear-gradient(135deg, ${theme.palette.primary.main} 20%, ${theme.palette.secondary.main} 80%)`,
  ...(!image ? {} : {
    backgroundImage: `url(${image})`,
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
  }),
}))

export const Overlay = styled('div')(({ opacity }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  height: '100%',
  width: '100%',
  backgroundColor: 'black',
  opacity,
  transition: 'background 0.3s, border-radius 0.3s, opacity 0.3s',
}))

function Header ({ buttonProps = {}, component, containerProps = {}, image, minHeight, narrow, overlayProps = {}, subheader, title, variant, ...otherProps }) {
  return (
    <StyledBox image={image} {...otherProps}>
      <Overlay opacity={image ? 0.25 : 0.15} {...overlayProps} />
      <Container {...containerProps} sx={{
          position: 'relative', zIndex: 1,
          py: narrow ? 8 : 16,
          color: 'white', minHeight, textAlign: 'center', whiteSpace: 'pre-wrap',
          display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
          ...(containerProps.sx || {}),
        }}>
        {!title ? null : (
          <Typography component={component} variant={variant} sx={{ color: 'white' }}>
            {title}
          </Typography>
        )}
        {!subheader ? null : (
          <Typography component='div' variant='subtitle1'>
            {subheader}
          </Typography>
        )}
        {isEmpty(buttonProps) ? null : (
          <Button color='inherit' variant='outlined' size={variant === 'h1' ? 'large': 'medium'}
            {...buttonProps} sx={{ mt: variant === 'h1' ? 10 : 5, ...(buttonProps.sx || {}) }} />
        )}
      </Container>
    </StyledBox>
  )
}

Header.propTypes = {
  buttonProps: PropTypes.object,
  component: PropTypes.string.isRequired,
  containerProps: PropTypes.object,
  image: PropTypes.string,
  minHeight: PropTypes.string,
  narrow: PropTypes.bool,
  overlayProps: PropTypes.object,
  subheader: PropTypes.node,
  variant: PropTypes.oneOf(['h1', 'h3']).isRequired,
}

Header.defaultProps = {
  minHeight: '100%',
}

export default Header

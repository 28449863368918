export const backRight = {
  circuits: [
    { nodes: 'O1,O2', score: 100 },
    { nodes: 'O2,PO8', score: 96 },
    { nodes: 'PO8,T6', score: 93 },
    { nodes: 'T6,O2', score: 83 },
    { nodes: 'T6,O1', score: 77 },
    { nodes: 'T6,P4', score: 72 },
    { nodes: 'T6,CP6', score: 55 },
    { nodes: 'T6,CFC6', score: 55 },
    { nodes: 'T6,FTX', score: 52 },
    { nodes: 'T6,T4', score: 47 },
    { nodes: 'PO7,PO8', score: 47 },
    { nodes: 'Fp1,Fp2', score: 45 },
    { nodes: 'Fp1,Fpz', score: 41 },
    { nodes: 'Fp2,Fpz', score: 36 },
    { nodes: 'T6,Fp2', score: 33 },
    { nodes: 'Fp2,F8', score: 33 },
    { nodes: 'F8,FTX', score: 32 },
    { nodes: 'T6,T3', score: 31 },
    { nodes: 'T6,CP2', score: 31 },
    { nodes: 'T6,C4', score: 29 },
    { nodes: 'T6,PO7', score: 28 },
    { nodes: 'PO8,P4', score: 28 },
    { nodes: 'PO8,CP6', score: 27 },
    { nodes: 'PO8,T4', score: 27 },
    { nodes: 'T4,CP6', score: 26 },
    { nodes: 'CP6,P4', score: 26 },
    { nodes: 'CP6,C4', score: 25 },
    { nodes: 'FC6,F4', score: 25 },
    { nodes: 'FC6,FTX', score: 24 },
    { nodes: 'FC6,T4', score: 23 },
    { nodes: 'O1,PO8', score: 23 },
    { nodes: 'PO7,O1', score: 23 },
    { nodes: 'T5,T6', score: 23 },
    { nodes: 'O1,P4', score: 22 },
    { nodes: 'T5,PO7', score: 22 },
    { nodes: 'T3,T5', score: 20 },
    { nodes: 'FT9,T3', score: 20 },
    { nodes: 'F7,FT9', score: 20 },
    { nodes: 'Fp1,F7', score: 20 },
    { nodes: 'O2,Pz', score: 20 },
    { nodes: 'O2,P4', score: 20 },
    { nodes: 'O2,CP6', score: 20 },
    { nodes: 'Pz,P4', score: 19 },
    { nodes: 'Pz,CP2', score: 19 },
    { nodes: 'CP2,P4', score: 19 },
    { nodes: 'Pz,CP6', score: 19 },
    { nodes: 'T6,F8', score: 19 },
    { nodes: 'CP2,CP6', score: 19 },
    { nodes: 'CP6,FTX', score: 18 },
    { nodes: 'CP2,FC2', score: 18 },
    { nodes: 'CP2,C4', score: 18 },
    { nodes: 'FC2,C4', score: 18 },
    { nodes: 'C4,FC6', score: 18 },
    { nodes: 'C4,T4', score: 18 },
    { nodes: 'C4,FTX', score: 17 },
    { nodes: 'F4,FTX', score: 17 },
    { nodes: 'FC6,F8', score: 17 },
    { nodes: 'FC2,F4', score: 16 },
    { nodes: 'CP2,T4', score: 16 },
  ],
}

export default backRight

import React from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@mui/styles'

import { SENSORS_POLYGONS } from '@/features/eeg-sessions/datasets/sensors'
import { analysisShape } from '@/features/eeg-sessions/store/propTypes'
import { toPoints } from './utils'

// WNG: This component currently does not consider sensors being used for a session to display
export function Heatmap ({ analysis: { normalizedScores = {} } = {}, disableDialogs, strokeWidth }) {
  const theme = useTheme()

  let { stroke } = theme.brainCanvas.heatmap

  let elements = []

  // Draw polygons for each defined sensor
  for (const sensorId of Object.keys(SENSORS_POLYGONS)) {
    const { polygon } = SENSORS_POLYGONS[sensorId] || {}
    if (!polygon) continue

    const fill = normalizedScores[sensorId]
      ? `rgba(255, 0, 0, ${0.05 + 0.75 * (normalizedScores[sensorId] / 100)})`
      : 'none'

    elements.push(<polygon key={`${sensorId}-polygon`}
      points={toPoints(polygon.map(({ x, y}) => [x, y]))}
      stroke={stroke} strokeWidth={strokeWidth} fill={fill} />)
  }

  return elements
}

Heatmap.propTypes = {
  analysis: analysisShape,
  disableDialogs: PropTypes.bool,
  strokeWidth: PropTypes.number.isRequired,
}

export default Heatmap

import cloneDeep from 'lodash/cloneDeep'

import { SENSORS_COORDINATES } from './coordinates'
import { SENSORS_MIRRORS } from './mirrors'

export const SPACER = 90

const Cz = SENSORS_COORDINATES.Cz

/**
 * Compute sensors' polygon for mirror sensors
 */
export function mirrorPolygons (config) {
  config = cloneDeep(config)

  for (const sensorId of Object.keys(config)) {
    const sensor = config[sensorId]

    const mirrorId = SENSORS_MIRRORS[sensorId]
    if (!mirrorId) continue

    // Add polygon for mirror sensor
    let polygon = []

    for (const node of sensor.polygon) {
      polygon = [
        ...polygon,
        { x: 500 + (Cz.x - node.x), y: node.y },
      ]
    }

    config[mirrorId] = { polygon }
  }

  return config
}

// NOTE: When drawing heatmaps
// Last polygon's coordinates get connected to the first element
export const SENSORS_POLYGONS = mirrorPolygons({
  'Fp1': {
    polygon: [
      { x: Cz.x - 0.8 * SPACER, y: 250 },
      { x: 300, y: 250 },
      { x: 277, y: 211 },
      { x: 305, y: 186 },
      { x: 336, y: 167 },
      { x: 372, y: 150 },
      { x: 396, y: 141 },
      { x: 424, y: 132 },
      { x: Cz.x - 0.5 * SPACER, y: 230 },
    ],
  },
  'Fpz': {
    polygon: [
      { x: Cz.x - 0.5 * SPACER, y: 230 },
      { x: 424, y: 132 },
      { x: 480, y: 121 },
      { x: 500 + (Cz.x - 480), y: 121 },
      { x: 500 + (Cz.x - 424), y: 132 },
      { x: Cz.x + 0.5 * SPACER, y: 230 },
    ],
  },
  'F7': {
    polygon: [
      { x: 276, y: 342 },
      { x: 234, y: 363 },
      { x: 193, y: 341 },
      { x: 207, y: 308 },
      { x: 230, y: 269 },
      { x: 250, y: 239 },
      { x: 277, y: 211 },
      { x: 300, y: 250 },
    ],
  },
  'F3': {
    polygon: [
      { x: 340, y: 390 },
      { x: 276, y: 342 },
      { x: 300, y: 250 },
      { x: Cz.x - 0.8 * SPACER, y: 250 },
      { x: Cz.x - 0.8 * SPACER, y: 360 },
    ],
  },
  'Fz': {
    polygon: [
      { x: Cz.x - 0.8 * SPACER, y: 360 },
      { x: Cz.x - 0.8 * SPACER, y: 250 },
      { x: Cz.x - 0.5 * SPACER, y: 230 },
      { x: Cz.x + 0.5 * SPACER, y: 230 },
      { x: Cz.x + 0.8 * SPACER, y: 250 },
      { x: Cz.x + 0.8 * SPACER, y: 360 },
      { x: Cz.x + 0.25 * SPACER, y: Cz.y - SPACER },
      { x: Cz.x - 0.25 * SPACER, y: Cz.y - SPACER },
    ],
  },
  'FT9': {
    polygon: [
      { x: 212, y: 464 },
      { x: 160, y: 464 },
      { x: 167, y: 422 },
      { x: 180, y: 377 },
      { x: 193, y: 341 },
      { x: 234, y: 363 },
    ],
  },
  'FC5': {
    polygon: [
      { x: 340, y: 460 },
      { x: 247, y: 495 },
      { x: 212, y: 464 },
      { x: 234, y: 363 },
      { x: 276, y: 342 },
      { x: 340, y: 390 },
    ],
  },
  'FC1': {
    polygon: [
      { x: Cz.x - 0.25 * SPACER, y: Cz.y - SPACER },
      { x: Cz.x - 0.8 * SPACER, y: Cz.y - 0.2 * SPACER },
      { x: 340, y: 460 },
      { x: 340, y: 390 },
      { x: Cz.x - 0.8 * SPACER, y: 360 },
    ],
  },
  'T3': {
    polygon: [
      { x: 247, y: 495 },
      { x: 247, y: 562 },
      { x: 200, y: 638 },
      { x: 150, y: 638 },
      { x: 148, y: 571 },
      { x: 152, y: 528 },
      { x: 160, y: 464 },
      { x: 212, y: 464 },
    ],
  },
  'C3': {
    polygon: [
      { x: Cz.x - 0.8 * SPACER, y: Cz.y + 0.2 * SPACER },
      { x: 340, y: 595 },
      { x: 247, y: 562 },
      { x: 247, y: 495 },
      { x: 340, y: 460 },
      { x: Cz.x - 0.8 * SPACER, y: Cz.y - 0.2 * SPACER },
    ],
  },
  'Cz': {
    polygon : [
      { x: Cz.x - 0.25 * SPACER, y: Cz.y - SPACER }, // top left
      { x: Cz.x + 0.25 * SPACER, y: Cz.y - SPACER }, // then clockwise
      { x: Cz.x + 0.8 * SPACER, y: Cz.y - 0.2 * SPACER },
      { x: Cz.x + 0.8 * SPACER, y: Cz.y + 0.2 * SPACER },
      { x: Cz.x + 0.25 * SPACER, y: Cz.y + SPACER },
      { x: Cz.x - 0.25 * SPACER, y: Cz.y + SPACER },
      { x: Cz.x - 0.8 * SPACER, y: Cz.y + 0.2 * SPACER },
      { x: Cz.x - 0.8 * SPACER, y: Cz.y - 0.2 * SPACER },
    ],
  },
  'CP5': {
    polygon: [
      { x: 340, y: 659 },
      { x: 256, y: 721 },
      { x: 200, y: 638 },
      { x: 247, y: 562 },
      { x: 340, y: 595 },
    ],
  },
  'CP1': {
    polygon: [
      { x: Cz.x - 0.25 * SPACER, y: Cz.y + SPACER },
      { x: Cz.x - 0.8 * SPACER, y: 685 },
      { x: 340, y: 659 },
      { x: 340, y: 595 },
      { x: Cz.x - 0.8 * SPACER, y: Cz.y + 0.2 * SPACER },
    ],
  },
  'T5': {
    polygon: [
      { x: 272, y: 774 },
      { x: 217, y: 828 },
      { x: 200, y: 804 },
      { x: 188, y: 784 },
      { x: 170, y: 745 },
      { x: 158, y: 705 },
      { x: 153, y: 675 },
      { x: 150, y: 637 },
      { x: 200, y: 638 },
      { x: 256, y: 721 },
    ],
  },
  'P3': {
    polygon: [
      { x: Cz.x - 0.8 * SPACER, y: 685 },
      { x: Cz.x - 0.8 * SPACER, y: 785 },
      { x: 355, y: 818 },
      { x: 272, y: 774 },
      { x: 256, y: 721 },
      { x: 340, y: 659 },
    ],
  },
  'Pz': {
    polygon: [
      { x: Cz.x - 0.8 * SPACER, y: 785 },
      { x: Cz.x - 0.8 * SPACER, y: 685 },
      { x: Cz.x - 0.25 * SPACER, y: Cz.y + SPACER },
      { x: Cz.x + 0.25 * SPACER, y: Cz.y + SPACER },
      { x: Cz.x + 0.8 * SPACER, y: 685 },
      { x: Cz.x + 0.8 * SPACER, y: 785 },
      { x: Cz.x, y: 818 },
    ],
  },
  'PO7': {
    polygon: [
      { x: 355, y: 818 },
      { x: 332, y: 901 },
      { x: 293, y: 885 },
      { x: 253, y: 860 },
      { x: 234, y: 845 },
      { x: 217, y: 828 },
      { x: 272, y: 774 },
    ],
  },
  'O1': {
    polygon: [
      { x: Cz.x, y: 818 },
      { x: Cz.x, y: 921 },
      { x: 458, y: 920 },
      { x: 422, y: 918 },
      { x: 377, y: 912 },
      { x: 332, y: 901 },
      { x: 355, y: 818 },
      { x: Cz.x - 0.8 * SPACER, y: 785 },
    ],
  },
})

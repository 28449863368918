import { coreApi } from '@/services/api'

export const EEG_SESSION_TAG = 'EEGSession'

export const eegSessionsApi = coreApi
  .enhanceEndpoints({
    addTagTypes: [EEG_SESSION_TAG],
  })
  .injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
      searchEEGSessions: builder.query({
        query: ({ page, perPage, practitionerId, userId }) => ({
          url: 'eeg-sessions',
          params: { page, perPage, practitionerId, userId },
        }),
        providesTags: (result, error, arg) => error ? [] : (result?.data
          ? [...result.data.map(({ id }) => ({ type: EEG_SESSION_TAG, id }))]
          : [EEG_SESSION_TAG]),
      }),
      addEEGSession: builder.mutation({
        query: ({ doneAt, practitionerId, title, userId }) => ({
          url: 'eeg-sessions',
          method: 'POST',
          body: { doneAt, practitionerId, title, userId },
        }),
        // TODO: Test result.id
        providesTags: (result, error, arg) => error ? [] : [{ type: EEG_SESSION_TAG, id: result.id }],
      }),
      getEEGSession: builder.query({
        query: (id) => `eeg-sessions/${id}`,
        providesTags: (result, error, arg) => error ? [] : [{ type: EEG_SESSION_TAG, id: arg }],
      }),
      editEEGSession: builder.mutation({
        query: ({ id, ...patch }) => ({
          url: `eeg-sessions/${id}`,
          method: 'PUT',
          body: patch,
        }),
        invalidatesTags: (result, error, arg) => error ? [] : [{ type: EEG_SESSION_TAG, id: arg.id }],
      }),
      deleteEEGSession: builder.mutation({
        query: (id) => ({
          url: `eeg-sessions/${id}`,
          method: 'DELETE',
        }),
        invalidatesTags: (result, error, arg) => error ? [] : [{ type: EEG_SESSION_TAG, id: arg }],
      }),
    }),
  })

export const { useAddEEGSessionMutation, useGetEEGSessionQuery, useEditEEGSessionMutation, useDeleteEEGSessionMutation, useSearchEEGSessionsQuery } = eegSessionsApi

import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import Autocomplete from '@mui/material/Autocomplete'
import Checkbox from '@mui/material/Checkbox'
import TextField from '@mui/material/TextField'

import { messages as intlWiringPatterns } from '@/features/eeg-sessions/messages/wiringPatterns.msg'
import { WIRING_PATTERN_IDS } from '@/features/eeg-sessions/store/propTypes/wiringPatterns'

function WiringPatternsAutocomplete ({ textFieldProps = {}, ...otherProps }) {
  const intl = useIntl()

  const getOptionTitle = (id) => intl.formatMessage(intlWiringPatterns.titles[id])

  const renderInput = (params) => (
    <TextField {...params}
      label={<FormattedMessage defaultMessage='Wiring patterns' description='TextField label' />}
      placeholder={intl.formatMessage({ defaultMessage: 'Pattern', description: 'TextField placeholer' })}
      {...textFieldProps} />
  )

  const renderOption = (props, option, { selected }) => (
    <li {...props}>
      <Checkbox checked={selected} style={{ marginRight: 8 }}
        icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
        checkedIcon={<CheckBoxIcon fontSize='small' />} />
        {getOptionTitle(option)}
    </li>
  )

  return (
    <Autocomplete
      options={WIRING_PATTERN_IDS}
      multiple
      disableCloseOnSelect
      limitTags={3}
      getOptionLabel={getOptionTitle}
      renderOption={renderOption}
      renderInput={renderInput}
      {...otherProps}
    />
  )
}

export default WiringPatternsAutocomplete

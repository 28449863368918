import { useSearchParams } from 'react-router-dom'

function usePaginationParams () {
  let [searchParams, setSearchParams] = useSearchParams()
  
  const setPaginationParams = (params) => {
    const newSearchParams = new URLSearchParams(searchParams)

    if (params.hasOwnProperty('page')) {
      if (!params.page || params.page === 1) newSearchParams.delete('page')
      else if (params.page >= 1) newSearchParams.set('page', params.page)
    }

    if (params.hasOwnProperty('perPage')) {
      if (params.perPage) newSearchParams.set('perPage', params.perPage)
      else newSearchParams.delete('perPage')
    }

    setSearchParams(newSearchParams)
  }

  let page = searchParams.get('page') || undefined
  if (page) page = Number.parseInt(page)

  let perPage = searchParams.get('perPage') || undefined
  if (perPage) perPage = Number.parseInt(perPage)
  
  const paginationParams = { page, perPage }
  return [paginationParams, setPaginationParams]
} 

export default usePaginationParams
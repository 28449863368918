import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'

import { messages as intlSensors } from '@/features/eeg-sessions/messages/sensors.msg'
import { b, li } from '@/features/intl/utils/intl'

export const INTL_MESSAGES = Object.keys(intlSensors)

function FormattedSensor ({ id, messages }) {
  // Return ID if message type is unknown
  if (!id || !~INTL_MESSAGES.indexOf(messages)) return null

  if (!intlSensors[messages][id]) {
    switch (messages) {
      case 'inTheWorkplace':
        return <FormattedMessage defaultMessage='Missing description in the workplace for {id}.' values={{ id }} />
      case 'shortDescriptions':
        return <FormattedMessage defaultMessage='Missing short description for {id}.' values={{ id }} />
      case 'titles':
        return <FormattedMessage defaultMessage='Missing title for {id}.' values={{ id }} />
      case 'description':
      default:
        return <FormattedMessage defaultMessage='Missing description for {id}.' values={{ id }} />
    }
  }

  let values
  if (messages === 'inTheWorkplace') values = { b, li }

  return (
    <FormattedMessage {...intlSensors[messages][id]} values={values} />
  )
}

FormattedSensor.propTypes = {
  messages: PropTypes.oneOf(INTL_MESSAGES).isRequired,
  id: PropTypes.string,
}

export default FormattedSensor

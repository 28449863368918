import { defineMessages } from 'react-intl'

const titles = defineMessages({
  expediters: {
    id: 'executiveStyles.titles.expediters',
    defaultMessage: 'Confident Expediters',
  },
  refiners: {
    id: 'executiveStyles.titles.refiners',
    defaultMessage: 'Principled Refiners',
  },
  energizers: {
    id: 'executiveStyles.titles.energizers',
    defaultMessage: 'Lively Energizers',
  },
  experimenters: {
    id: 'executiveStyles.titles.experimenters',
    defaultMessage: 'Reflective Experimenters',
  },
})

const about = defineMessages({
  expediters: {
    id: 'executiveStyles.about.expediters',
    defaultMessage: '<li>Poised or aggressive face, with confident body language and strong, clear words. Shares judgments and actions.</li><li>Moves at a faster pace to decisively initiate interactions. Proactively meets goals. Displays sureness and confidence. Organizes and fixes to get positive results soon. Has an expansive mindset, focusing ambitions ever wider.</li><li>Feels responsible to make the world conform to how it should be. Initiates changes to restore or raise standards. Is comfortable taking charge as needed. Quickly identifies and deploys others\' talents.</li><li>Dislikes laziness or timidity in others. Confronts problems, give reprimands, and advise solutions. Under stress, may seem bossy, impatient or aggressive.</li>',
  },
  refiners: {
    id: 'executiveStyles.about.refiners',
    defaultMessage: '<li>Show mild facial expressions, with self-effacing body language, and specific or corrective words. You seek to set the tone, ideal, truth or norm.</li><li>Move at slower pace to accurately respond to interactions. Clarify what\'s universal, true or worthwhile. Often look quietly receptive but trust own judgments and consistent reasoning. Have a contractive mind-set, focusing interests ever narrower.</li><li>Adhere to a fixed set of personal standards and ideals. Seek perfection and readily critique. Work with a select group of people whose values and thinking align with your own. Often error-free in your area of expertise.</li><li>Dislike pushiness or lack of principles. Prefer to go with the flow, acting when the situation fits your views. May seem moralizing, critical or unmovable.</li>',
  },
  energizers: {
    id: 'executiveStyles.about.energizers',
    defaultMessage: '<li>Show uninhibited facial expressions, with expansive body language, and dramatic or humorous words. Draw out the environment.</li><li>Move at a faster pace to dramatically initiate interactions. Seek out stimuli. Seem random, emergent, and enthusiastic. Attend to the here and now. Have expansive senses, perceiving ever more widely. Actively seek to satisfy an ever-increasing appetite for whatever you find stimulating.</li><li>May fixate on specific desires until boredom or harm. Are comfortable with something when it feels good. Quickly persuades others to participate, trusting everyone\'s talents.</li><li>Dislike discipline and rigidity from others. Instead, you diffuse problems, offer suggestions, and provide inspiration. Can seem hedonistic, distractible or easily off-task.</li>',
  },
  experimenters: {
    id: 'executiveStyles.about.experimenters',
    defaultMessage: '<li>Distinct facial expressions, with vigilant body language, and broad-minded or reflective words. Proceeds with an inner will.</li><li>Moves at a slower pace and selectively responds to interactions. Reflects on data and trusts own perceptions. Often looks preoccupied. Likes some guidelines and reference points while exploring. Has contractive senses, focusing on surely satisfying a few essential needs.</li><li>Adjusts methods and means to respond effectively. Steadily accumulates data and experiences. Shifts ideas and beliefs to make room for what the world presents. Acts as own primary talent source. Works from insights.</li><li>Dislikes interference and questioning. Predicts and avoids problems, seeking to navigate around them. May seem absorbed, uncommunicative or wary.</li>',
  },
})

const needs = defineMessages({
  expediters: {
    id: 'executiveStyles.needs.expediters',
    defaultMessage: '<li><b>Start:</b> Engage on points they are confident, where there\'s leverage to move forward, and/or they are willing to decide with you now.</li><li><b>Gain rapport:</b> Refer to what you respect, plan/s to succeed, and your and their resource sets.</li><li><b>Stay focused on:</b> a winning outcome.</li><li><b>Deepen trust:</b> Help you beat a shared obstacle or fulfill your master plan. Can stand and take charge sometimes.</li><li><b>Avoid:</b> A show of weakness, indecision, talk without action, and irrelevancies.</li><li><b>Help you grow:</b> Use humor, refer to what you respect, and point to tangible winning outcomes.</li>',
  },
  refiners: {
    id: 'executiveStyles.needs.refiners',
    defaultMessage: '<li><b>Start:</b> Engage you in areas of shared expertise, or where your expertise might help, or what they wish to learn from you.</li><li><b>Gain rapport:</b> Speak to your core principles, philosophy and lingo, and favorite practices.</li><li><b>Stay focused on:</b> the outcome that is most consistent with your principles.</li><li><b>Deepen trust:</b> Walk the talk on principles, and can act in your place, with credit. Show they care by sometimes challenging weak points.</li><li><b>Avoid:</b> speaking off the cuff or acting rashly (weigh what they say and do), and expect a stand "on principle" when one is violated.</li><li><b>Help you grow:</b> Use humor, respect your principles, and pitch bigger ideas that honor your existing ones.</li>',
  },
  energizers: {
    id: 'executiveStyles.needs.energizers',
    defaultMessage: '<li><b>Start:</b> Engage in a shared favorite activity (pastime), on topics you can negotiate, and with excitement.</li><li><b>Gain rapport:</b> Refer to the possibilities at hand, what\'s enjoyable, and ways to work together.</li><li><b>Stay focused on:</b> everyone wanting to participate and be included.</li><li><b>Deepen trust:</b> Support you in down times and give you some energy. Bring the fun to you sometimes.</li><li><b>Avoid:</b> Attacking you when you\'re down, rigidity, and ruining enjoyment.</li><li><b>Help you grow:</b> Use humor, stay open to surprises, and wait for the best most natural moment to intervene.</li>',
  },
  experimenters: {
    id: 'executiveStyles.needs.experimenters',
    defaultMessage: '<li><b>Start:</b> Engage their broad goals, their projects or tasks, and reference points (including what to avoid).</li><li><b>Gain rapport:</b> Give data relevant to their projects, issues, or goals with time to reflect and absorb.</li><li><b>Stay focused on:</b> learning (benefitting) from the process of trying new things.</li><li><b>Deepen trust:</b> Stay loyal (no mutiny on the hazardous trip!) and stick with them despite their moods or flaws. Help them by showing them new tools and methods.</li><li><b>Avoid:</b> sensory and data overload, personal criticism, and demanding consistency or specifics too soon.</li><li><b>Help you grow:</b> Use humor, guide them through new experiences, and provide feedback gently.</li>',
  },
})

export const messages = {
  about,
  needs,
  titles,
}

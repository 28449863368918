import React from 'react'
import { useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import AppBar from '@mui/material/AppBar'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import Toolbar from '@mui/material/Toolbar'

import { isLoggedInSelector, userIdSelector } from '@/features/auth/store/reducers/tokens'
import Brand from '@/features/ui/components/Brand'
import DrawerMenu from '@/features/ui/components/DrawerMenu'
import { useGetUserDetailsQuery } from '@/features/users/services/api'
import { noPrint } from '@/utils/print'

function NavigationBar () {
  const [isDrawerOpen, setDrawerOpen] = React.useState(false)
  const isLoggedIn = useSelector(isLoggedInSelector)
  const userId = useSelector(userIdSelector)

  const { data: user = {} } = useGetUserDetailsQuery(userId, { skip: !userId })

  const openDrawer = (e) => { setDrawerOpen(true) }
  const closeDrawer = (e) => { setDrawerOpen(false) }

  return (
    <React.Fragment>
      <AppBar color='inherit' position='static' sx={noPrint}>
        <Toolbar>
          <IconButton size='large' edge='start' color='inherit' sx={{ mr: 2 }} onClick={openDrawer}>
            <MenuIcon />
          </IconButton>
          <div style={{ flexGrow: 1 }}>
            <Brand />
          </div>
          {isLoggedIn ? (
            <Avatar src={user.imageUrl} onClick={openDrawer} sx={{ cursor: 'pointer' }}/>
          ) : (
            <Button href='/login' color='inherit'>
              <FormattedMessage defaultMessage='Log in' description='Toolbar button label' />
            </Button>
          )}
        </Toolbar>
      </AppBar>
      <DrawerMenu anchor='left' open={isDrawerOpen} closeDrawer={closeDrawer} onClose={closeDrawer} />
    </React.Fragment>
  )
}

export default NavigationBar

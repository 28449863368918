import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  open: false,
  circuit: undefined,
}

export const circuitDialogSlice = createSlice({
  name: 'circuitDialog',
  initialState,
  reducers: {
    // Pass false to close, pass a circuit to open
    setOpen: (state, action) => ({
      ...state,
      open: !!action.payload,
      circuit: action.payload || undefined,
    }),
  },
})

export const { setOpen } = circuitDialogSlice.actions

export const openSelector = ((state) => state.eegSessions.circuitDialog.open)
export const circuitSelector = ((state) => state.eegSessions.circuitDialog.circuit)

export default circuitDialogSlice.reducer

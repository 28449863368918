import React from 'react'
import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'

const LinkBehavior = React.forwardRef((props, ref) => {
  const { children, href, external, ...other } = props

  // Render an html anchor with safeguards for external link
  if (external) {
    return (
      <a ref={ref} href={href} target='_blank' rel='nofollow noopener noreferrer' {...other}>
        {children}
      </a>
    )
  }

  // Use HashLink if an hash fragment was detected
  const hasHashFramgent = !!(href && href.includes('#'))
  const Link = hasHashFramgent ? HashLink : RouterLink

  // Map href (MUI) -> to (react-router)
  return <Link data-testid='custom-link'
    smooth={hasHashFramgent || undefined}
    children={children} ref={ref} to={href} {...other} />
})

LinkBehavior.propTypes = {
  href: PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.string]).isRequired,
}

export default LinkBehavior

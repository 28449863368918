export const centerBack = {
  circuits: [
    { nodes: 'O1,O2', score: 100 },
    { nodes: 'Fp1,Fpz', score: 96 },
    { nodes: 'Fpz,Fp2', score: 93 },
    { nodes: 'T3,T4', score: 83 },
    { nodes: 'T3,T6', score: 77 },
    { nodes: 'T5,T4', score: 72 },
    { nodes: 'T3,T6', score: 55 },
    { nodes: 'CP5,P3', score: 55 },
    { nodes: 'T4,T6', score: 52 },
    { nodes: 'T5,T6', score: 47 },
    { nodes: 'PO7,O1', score: 47 },
    { nodes: 'O2,PO8', score: 45 },
    { nodes: 'PO8,T6', score: 41 },
    { nodes: 'T5,PO7', score: 36 },
    { nodes: 'T5,P3', score: 33 },
    { nodes: 'O1,P3', score: 33 },
    { nodes: 'O1,Pz', score: 32 },
    { nodes: 'Pz,O2', score: 31 },
    { nodes: 'O2,P4', score: 31 },
    { nodes: 'Pz,P4', score: 29 },
    { nodes: 'P4,T6', score: 28 },
    { nodes: 'P4,CP2', score: 28 },
    { nodes: 'P4,CP6', score: 27 },
    { nodes: 'P3,P1', score: 27 },
    { nodes: 'CP6,FC6', score: 26 },
    { nodes: 'T4,FTX', score: 26 },
    { nodes: 'CP5,T3', score: 25 },
    { nodes: 'CP5,FC5', score: 25 },
    { nodes: 'T3,FC5', score: 24 },
    { nodes: 'FT9,T3', score: 23 },
    { nodes: 'PO7,P3', score: 23 },
    { nodes: 'PO8,P4', score: 23 },
    { nodes: 'P3,Pz', score: 23 },
    { nodes: 'Pz,C3', score: 22 },
    { nodes: 'Pz,Cz', score: 22 },
    { nodes: 'CP6,FC2', score: 20 },
    { nodes: 'C3,FC5', score: 20 },
    { nodes: 'FC5,F3', score: 20 },
    { nodes: 'FC5,F7', score: 20 },
    { nodes: 'F7,FT9', score: 20 },
    { nodes: 'F7,T3', score: 20 },
    { nodes: 'F3,FC1', score: 20 },
    { nodes: 'C4,FC6', score: 19 },
    { nodes: 'F4,FC6', score: 19 },
    { nodes: 'F3,F4', score: 19 },
    { nodes: 'Fp1,F7', score: 19 },
    { nodes: 'Fp1,Fp2', score: 19 },
    { nodes: 'Fp2,F8', score: 19 },
    { nodes: 'F8,FTX', score: 18 },
    { nodes: 'F8,FC6', score: 18 },
  ],
}

export default centerBack

import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { useTheme } from '@mui/styles'

// import processCircuits from './processCircuits'
import { isDiamond, isStarburst, getDiamondCenter, getStarburstCenter, getStarburstLines, getDiamondLines } from './utils'
import { SENSORS_COORDINATES } from '@/features/eeg-sessions/datasets/sensors'
import { setOpen } from '@/features/eeg-sessions/store/reducers/circuitDialog'
import { analysisShape, sensorsShape } from '@/features/eeg-sessions/store/propTypes'
import { WIRING_TIERS_QUANTITIES } from '@/features/eeg-sessions/store/propTypes/wiring'
import { parseNodes } from '@/features/eeg-sessions/utils/analyses'

export function Wiring ({ analysis: { circuits } = {}, disableDialogs, sensors = [], strokeWidth }) {
  const dispatch = useDispatch()
  const theme = useTheme()

  let elements = []
  if (!circuits) return elements

  // circuits = processCircuits(circuits)

  let diamondsFrom = []
  const cursor = disableDialogs ? undefined : { cursor: 'pointer' }

  // Draw circuits per tier based on color codes
  let offset = 0
  for (let t = 0; t < WIRING_TIERS_QUANTITIES.length; t++){
    const quantity = WIRING_TIERS_QUANTITIES[t]
    const { default: color, diamonds: diamondColors, starbursts: starburstColors } = theme.brainCanvas.wiring[t]

    let tierElements = []
    let starbursts = []
    let diamonds = []
    let starburstCount = 0
    let diamondCount = 0

    // Draw for a specific quantity
    for (let q = offset; q < (offset + quantity); q++) {
      const circuit = circuits[q]
      if (!circuit) continue

      const nodes = parseNodes(circuit)

      const handleClick = disableDialogs ? undefined : (e) => {
        e.stopPropagation()
        dispatch(setOpen(circuit))
      }

      if (isStarburst(nodes)) {
        const stroke = starburstColors[starburstCount % starburstColors.length]
        const from = getStarburstCenter(nodes, SENSORS_COORDINATES)
        const lines = getStarburstLines(nodes, { circuitId: q, cursor, from, handleClick, sensorsCoordinates: SENSORS_COORDINATES, stroke, strokeWidth })
        starbursts = [...starbursts, ...lines]
        starburstCount++
      } else if (isDiamond(nodes)) {
        const stroke = diamondColors[diamondCount % diamondColors.length]
        const { from, existing } = getDiamondCenter(nodes, SENSORS_COORDINATES, { existing: diamondsFrom })
        diamondsFrom = existing
        const lines = getDiamondLines(nodes, { circuitId: q, cursor, from, handleClick, sensorsCoordinates: SENSORS_COORDINATES, stroke, strokeWidth })
        diamonds = [...diamonds, ...lines]
        diamondCount++
      } else {
        const stroke = color
        const lines = getDiamondLines(nodes, { circuitId: q, cursor, handleClick, sensorsCoordinates: SENSORS_COORDINATES, stroke, strokeWidth })
        tierElements = [...tierElements, ...lines]
      }
    }

    // Draw regular links on top of diamonds on top of starbursts for each tier
    elements = [...elements, ...tierElements, ...diamonds, ...starbursts]

    offset += quantity
  }

  return elements.reverse()
}

Wiring.propTypes = {
  analysis: analysisShape,
  disableDialogs: PropTypes.bool,
  sensors: sensorsShape,
  strokeWidth: PropTypes.number.isRequired,
}

export default Wiring

import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector, useDispatch } from 'react-redux'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

import WiringPatternDescription from '@/features/eeg-sessions/components/WiringPatternDescription'
import { openSelector, patternSelector, setOpen } from '@/features/eeg-sessions/store/reducers/wiringPatternDialog'
import { messages as intlWiringPatterns } from '@/features/eeg-sessions/messages/wiringPatterns.msg'

function WiringPatternDialog() {
  const open = useSelector(openSelector)
  const id = useSelector(patternSelector)

  const dispatch = useDispatch()
  const handleClose = () => { dispatch(setOpen(false)) }

  const descriptionElementRef = React.useRef(null)
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef
      if (descriptionElement !== null) {
        descriptionElement.focus()
      }
    }
  }, [open])

  return (
    <Dialog open={open} onClose={handleClose} scroll='paper'
      aria-labelledby="wiring-pattern-info-dialog-title"
      aria-describedby="wiring-pattern-info-dialog-description">
      <DialogTitle id="wiring-pattern-info-dialog-title">
        {(id in intlWiringPatterns.titles) && (
          <FormattedMessage defaultMessage='{bias} Bias' description='Bias variable is replaced to display Left Bias, Right Bias, etc.' values={{
            bias: <FormattedMessage {...intlWiringPatterns.titles[id]} />
          }} />
        )}
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText id="wiring-pattern-info-dialog-description"
          ref={descriptionElementRef} tabIndex={-1}>
          <WiringPatternDescription id={id} direction='column' />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>
          <FormattedMessage defaultMessage='Got it' />
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default WiringPatternDialog

import React from 'react'
import { injectIntl } from 'react-intl'
import { useTheme } from '@mui/material/styles'

import { LINE_BREAK, SENSORS_TIPS } from '@/features/eeg-sessions/datasets/sensors/tips'
import { sensorsShape } from '@/features/eeg-sessions/store/propTypes'

export function SensorsTips ({ intl, sensors = [] }) {
  const theme = useTheme()

  let elements = []
  if (!sensors.length) return []

  for (const tipId of Object.keys(SENSORS_TIPS)) {
    const tip = SENSORS_TIPS[tipId]
    if (!sensors.includes(tip.sensorId)) continue

    elements = [
      ...elements,
      <text key={`text-${tip.id}`} x={tip.x} y={tip.y}
        fill={tip.inner ? 'black' : theme.palette.text.primary} textAnchor={tip.textAnchor}
        fontSize={15} fontWeight='bold'>
        {intl.formatMessage(SENSORS_TIPS[tipId]).split(LINE_BREAK).map((line, i) => (
          <tspan key={i} x={tip.x} dy={i ? '1.2em' : 0}>
            {line}
          </tspan>
        ))}
      </text>,
    ]
  }

  return elements
}

SensorsTips.propTypes = {
  sensors: sensorsShape,
}

export default injectIntl(SensorsTips)

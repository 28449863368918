import jwt from 'jsonwebtoken'

import { USER_ROLE_ADMIN, USER_ROLE_EXPLORER, USER_ROLE_PRACTITIONER, USER_ROLE_RESEARCHER } from '@/features/users/store/propTypes/roles'

export function getUserProps (accessToken, { debug } = {}) {
  const decodedToken = jwt.decode(accessToken) || {}

  if (debug) {
    return {
      roles: {
        [USER_ROLE_ADMIN]: false,
        [USER_ROLE_EXPLORER]: true,
        [USER_ROLE_PRACTITIONER]: true,
        [USER_ROLE_RESEARCHER]: true,
      },
      userId: 'QEyF73IJC8Uq92luf5O5DJ84x1n2',
    }
  }

  return {
    roles: {
      [USER_ROLE_ADMIN]: !!decodedToken[USER_ROLE_ADMIN],
      [USER_ROLE_EXPLORER]: true, // NOTE: This is injected to simplify implementation logic throughout the frontend app
      [USER_ROLE_PRACTITIONER]: !!decodedToken[USER_ROLE_PRACTITIONER],
      [USER_ROLE_RESEARCHER]: !!decodedToken[USER_ROLE_RESEARCHER],
    },
    userId: decodedToken['user_id'],
  }
}

export const pillar = {
  circuits: [
    { nodes: 'Fp1,O1', score: 100 },
    { nodes: 'Fp2,O2', score: 96 },
    { nodes: 'Fp1,Fz', score: 93 },
    { nodes: 'Fp2,Fz', score: 83 },
    { nodes: 'F3,Fz', score: 77 },
    { nodes: 'F4,Fz', score: 72 },
    { nodes: 'F3,Pz', score: 55 },
    { nodes: 'F4,Pz', score: 55 },
    { nodes: 'Fz,O1', score: 52 },
    { nodes: 'Fz,O2', score: 47 },
    { nodes: 'Fp1,Fp2', score: 47 },
    { nodes: 'Fp1,O2', score: 45 },
    { nodes: 'Fp2,O1', score: 41 },
    { nodes: 'F3,C4', score: 36 },
    { nodes: 'F4,C3', score: 33 },
    { nodes: 'F3,C4', score: 33 },
    { nodes: 'F4,P4', score: 32 },
    { nodes: 'F3,P3', score: 31 },
    { nodes: 'C3,T3', score: 31 },
    { nodes: 'C4,T4', score: 29 },
    { nodes: 'C3,Cz', score: 28 },
    { nodes: 'C4,Cz', score: 28 },
    { nodes: 'Fz,Cz', score: 27 },
    { nodes: 'C4,P4', score: 26 },
    { nodes: 'P3,Pz', score: 26 },
    { nodes: 'P4,Pz', score: 25 },
    { nodes: 'P3,O2', score: 25 },
    { nodes: 'P4,O1', score: 24 },
    { nodes: 'T3,Pz', score: 23 },
    { nodes: 'T4,Pz', score: 23 },
    { nodes: 'F3,C3', score: 27 },
    { nodes: 'F4,C4', score: 23 },
    { nodes: 'Fp1,F4', score: 23 },
    { nodes: 'Fp2,F3', score: 22 },
    { nodes: 'Fpz,F3', score: 22 },
    { nodes: 'Fpz,F4', score: 20 },
    { nodes: 'F7,F4', score: 20 },
    { nodes: 'F8,F3', score: 20 },
    { nodes: 'F3,FC5', score: 20 },
    { nodes: 'F4,FC6', score: 20 },
    { nodes: 'F7,F3', score: 20 },
    { nodes: 'F8,F4', score: 20 },
    { nodes: 'Fz,T5', score: 19 },
    { nodes: 'Fz,T6', score: 19 },
    { nodes: 'T5,O2', score: 19 },
    { nodes: 'T6,O1', score: 19 },
    { nodes: 'Pz,O1', score: 19 },
    { nodes: 'Pz,O2', score: 19 },
    { nodes: 'T5,P3', score: 19 },
    { nodes: 'T6,P4', score: 19 },
    { nodes: 'C3,CP5', score: 19 },
    { nodes: 'C4,CP6', score: 19 },
    { nodes: 'P3,CP5', score: 19 },
    { nodes: 'P4,CP6', score: 19 },
    { nodes: 'C3,CP1', score: 19 },
    { nodes: 'C4,CP2', score: 19 },
  ],
}

export default pillar

import { getMediaPictureSrc } from '@/utils/mediaStorage'

const userDetails = {
  email: '',
  firstName: 'Jessica',
  id: '',
  imageUrl: getMediaPictureSrc('/misc/report-example/avatar-jessica-dickes.jpg?alt=media&token=5cb8f875-2e96-46e9-9a8a-3dab2a0e0d3c'),
  lastName: 'Dickes',
}

export default userDetails

import PropTypes from 'prop-types'
import React from 'react'
import { FormattedNumber } from 'react-intl'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/styles'

const PreDiscountPrice = styled(Box)(({ discount }) => ({
  fontSize: '2rem',
  fontWeight: 300,
  opacity: .75,
  textDecoration: 'line-through',
  visibility: discount ? 'visible' : 'hidden',
}))

const Price = styled(Box)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '3rem',
  fontWeight: 'bold',
  lineHeight: '3rem',
}))

function PriceTablePrice ({ currency, discount = 0, price, pricePer }) {
  return (
    <Stack alignItems='center'>
      <PreDiscountPrice discount={discount}>
        <FormattedNumber value={price}
          minimumFractionDigits={0} maximumFractionDigits={0}
          style={`currency`} currency={currency} />
      </PreDiscountPrice>
      <Price>
        <FormattedNumber value={price - discount}
          minimumFractionDigits={0} maximumFractionDigits={0}
          style={`currency`} currency={currency} />
      </Price>
      {pricePer}
    </Stack>
  )
}

PriceTablePrice.propTypes = {
  currency: PropTypes.string,
  discount: PropTypes.number,
  price: PropTypes.number,
  pricePer: PropTypes.element,
}

PriceTablePrice.defaultProps = {
  currency: 'EUR',
}

export default PriceTablePrice

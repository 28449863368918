import throttle from 'lodash/throttle'
import without from 'lodash/without'
import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { scroller, Events } from 'react-scroll'
import Tab from '@mui/material/Tab'

import { messages } from './ReportTabs.msg'
import NavigationTabs from '@/features/ui/components/NavigationTabs'

const TAB_IDS = Object.keys(messages.tabs)

const OFFSET = 120

let _scrolling = false

class ReportTabs extends React.Component {
  constructor (props) {
    super(props)
    this.state = { value: TAB_IDS[0] }
  }

  setValue = (value) => {
    if (value !== this.state.value) this.setState({ ...this.state, value })
  }

  handleChange = (e, value) => {
    this.setValue(value)

    scroller.scrollTo(value, { duration: 300, offset: -OFFSET, smooth: true })
  }

  handleScroll = throttle(() => {
    if (_scrolling) return

    const tabIds = TAB_IDS

    let id
    for (let i = tabIds.length - 1; i >= 0; i--) {
      id = tabIds[i]
      const element = document.getElementById(id)
      if (!element) return

      const top = element.getBoundingClientRect().top
      if (top - OFFSET < 5) {
        this.setValue(id)
        return
      }
    }
  }, 200)

  componentDidMount () {
    document.addEventListener('scroll', this.handleScroll)
    this.handleScroll()

    Events.scrollEvent.register('begin', function(to, element) {
      _scrolling = true
    })

    Events.scrollEvent.register('end', function(to, element) {
      setTimeout(() => { _scrolling = false }, 750)
    })
  }

  componentWillUnmount () {
    document.removeEventListener('scroll', this.handleScroll)
  }

  render () {
    const tabs = Array.isArray(this.props.omit)
      ? without(TAB_IDS,  ...this.props.omit)
      : without(TAB_IDS, this.props.omit)
      
    return (
      <NavigationTabs value={this.state.value} onChange={this.handleChange}>
        {tabs.map((id) => (
          <Tab key={id} value={id} label={<FormattedMessage {...messages.tabs[id]} />} />
        ))}
      </NavigationTabs>
    )
  }
}

ReportTabs.propTypes = {
  omit: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
}

export default ReportTabs

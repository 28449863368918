import { defineMessages } from 'react-intl'

import { 
  EEG_ANALYSIS_VARIANT_HIGH_FREQ,
  EEG_ANALYSIS_VARIANT_LOW_FREQ,
  EEG_ANALYSIS_VARIANT_MIXED,
} from '@/features/eeg-sessions/store/propTypes/analyses'

export const shortDescriptions = defineMessages({
  [EEG_ANALYSIS_VARIANT_HIGH_FREQ]: {
    id: 'analysisVariant.shortDescriptions.highFreq',
    defaultMessage: 'More conscious and specialized, often with more auditory and less executive activity.',
  },
  [EEG_ANALYSIS_VARIANT_LOW_FREQ]: {
    id: 'analysisVariant.shortDescriptions.lowFreq',
    defaultMessage: 'More subconscious and general, often with more body-kinesthetic and executive activity.',
  },
  [EEG_ANALYSIS_VARIANT_MIXED]: {
    id: 'analysisVariant.shortDescriptions.mixed',
    defaultMessage: 'A mix of all brain wiring.',
  },
})

export const titles = defineMessages({
  [EEG_ANALYSIS_VARIANT_HIGH_FREQ]: {
    id: 'analysisVariant.titles.highFreq',
    defaultMessage: 'Fast challenge mode',
  },
  [EEG_ANALYSIS_VARIANT_LOW_FREQ]: {
    id: 'analysisVariant.titles.lowFreq',
    defaultMessage: 'Slow automatic mode',
  },
  [EEG_ANALYSIS_VARIANT_MIXED]: {
    id: 'analysisVariant.titles.mixed',
    defaultMessage: 'Combined modes',
  },
})

export const messages = {
  shortDescriptions,
  titles,
}

import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'

import { displaySelector, setSession, setDisplay } from '@/features/eeg-sessions/store/reducers/playgroundSettings'
import { parseCircuitsTextFile } from '@/features/eeg-sessions/utils/sessions'
import { onFileChange } from '@/utils/files'

const Input = styled('input')({ display: 'none' })

function PlaygroundSettings () {
  const display = useSelector(displaySelector)
  const handleDisplayChange = (event) => dispatch(setDisplay(event.target.value))

  const dispatch = useDispatch()
  const handleFileChange = onFileChange((file) => (event) => {
    if (!file) return

    const session = parseCircuitsTextFile(file.name, event.target.result)

    return dispatch(setSession({ filename: file.name, session }))
  })

  return (
    <Paper variant='outlined' sx={{ p: 2 }}>
      <Stack direction={{ xs: 'column', sm: 'row' }} alignItems='center' justifyContent='space-between' spacing={2}>
        {/* Display radio buttons */}
        <FormControl component='fieldset'>
         <FormLabel component='legend'>
           <FormattedMessage defaultMessage='Display' />
         </FormLabel>
         <RadioGroup row aria-label='display' name='display-radio-buttons'
           value={display} onChange={handleDisplayChange}>
           <FormControlLabel value='wiring' control={<Radio />}
            label={<FormattedMessage defaultMessage='Wiring' />} />
           <FormControlLabel value='heatmap' control={<Radio />}
            label={<FormattedMessage defaultMessage='Heatmap' />} />
         </RadioGroup>
       </FormControl>
       {/* Upload button */}
       <label htmlFor='upload-button-file'>
         <Input id='upload-button-file' multiple={false} type='file' onChange={handleFileChange} />
         <Button variant='contained' component='span'>
           <FormattedMessage defaultMessage='Upload' />
         </Button>
       </label>
     </Stack>
   </Paper>
  )
}

export default PlaygroundSettings

import { defineMessages } from 'react-intl'

// WNG: Do not use intlSensors messages
// Use FormattedSensor component instead which handles missing descriptors

const titles = defineMessages({
  'Fp1': {
    id: 'sensors.titles.Fp1',
    defaultMessage: 'Focused Judge',
  },
  'Fpz': {
    id: 'sensors.titles.Fpz',
    defaultMessage: 'Self Manager',
  },
  'Fp2': {
    id: 'sensors.titles.Fp2',
    defaultMessage: 'Curious Explorer',
  },
  'F7': {
    id: 'sensors.titles.F7',
    defaultMessage: 'Quick Analogizer',
  },
  'F3': {
    id: 'sensors.titles.F3',
    defaultMessage: 'Deductive Analyst',
  },
  'Fz': {
    id: 'sensors.titles.Fz',
    defaultMessage: 'Inductive Scholar',
  },
  'F4': {
    id: 'sensors.titles.F4',
    defaultMessage: 'Witty Classifier',
  },
  'F8': {
    id: 'sensors.titles.F8',
    defaultMessage: 'Grounded Believer',
  },
  'FT9': {
    id: 'sensors.titles.FT9',
    defaultMessage: 'Meta Analyst',
  },
  'FC5': {
    id: 'sensors.titles.FC5',
    defaultMessage: 'Efficiency Ace',
  },
  'FC1': {
    id: 'sensors.titles.FC1',
    defaultMessage: 'Talkative Planner',
  },
  'FC2': {
    id: 'sensors.titles.FC2',
    defaultMessage: 'Flexible Experimenter',
  },
  'FC6': {
    id: 'sensors.titles.FC6',
    defaultMessage: 'Character Adept',
  },
  'FTX': {
    id: 'sensors.titles.FTX',
    defaultMessage: 'Sure Clarifier',
  },
  'T3': {
    id: 'sensors.titles.T3',
    defaultMessage: 'Precise Speaker',
  },
  'C3': {
    id: 'sensors.titles.C3',
    defaultMessage: 'Scripted Guide',
  },
  'Cz': {
    id: 'sensors.titles.Cz',
    defaultMessage: 'Driven Dynamo',
  },
  'C4': {
    id: 'sensors.titles.C4',
    defaultMessage: 'Flowing Artist',
  },
  'T4': {
    id: 'sensors.titles.T4',
    defaultMessage: 'Emotive Listener',
  },
  'CP5': {
    id: 'sensors.titles.CP5',
    defaultMessage: 'Academic Decathlete',
  },
  'CP1': {
    id: 'sensors.titles.CP1',
    defaultMessage: 'Technical Mimic',
  },
  'CP2': {
    id: 'sensors.titles.CP2',
    defaultMessage: 'Intuitive Composer',
  },
  'CP6': {
    id: 'sensors.titles.CP6',
    defaultMessage: 'Harmonious Self',
  },
  'T5': {
    id: 'sensors.titles.T5',
    defaultMessage: 'Sensitive Adjuster',
  },
  'P3': {
    id: 'sensors.titles.P3',
    defaultMessage: 'Tactical Identifier',
  },
  'Pz': {
    id: 'sensors.titles.Pz',
    defaultMessage: 'Observant Technician',
  },
  'P4': {
    id: 'sensors.titles.P4',
    defaultMessage: 'System Balancer',
  },
  'T6': {
    id: 'sensors.titles.T6',
    defaultMessage: 'Insightful Futurist',
  },
  'PO7': {
    id: 'sensors.titles.PO7',
    defaultMessage: 'Quality Controller',
  },
  'O1': {
    id: 'sensors.titles.O1',
    defaultMessage: 'Visual Engineer',
  },
  'O2': {
    id: 'sensors.titles.O2',
    defaultMessage: 'Abstract Impressionist',
  },
  'PO8': {
    id: 'sensors.titles.PO8',
    defaultMessage: 'Visual Choreographer',
  },
})

const shortDescriptions = defineMessages({
  'Fp1': {
    id: 'sensors.shortDescriptions.Fp1',
    defaultMessage: 'Focus, decide, explain, refine, fix, stay positive.',
  },
  'Fpz': {
    id: 'sensors.shortDescriptions.Fpz',
    defaultMessage: 'Self-EQ: Know and manage own thoughts and emotions.',
  },
  'Fp2': {
    id: 'sensors.shortDescriptions.Fp2',
    defaultMessage: 'Explore, brainstorm, play, introspect, reflect, process.',
  },
  'F7': {
    id: 'sensors.shortDescriptions.F7',
    defaultMessage: 'Mirror others, watch to learn, analogize, and imagine.',
  },
  'F3': {
    id: 'sensors.shortDescriptions.F3',
    defaultMessage: 'Derive logical, step-wise solutions using language.',
  },
  'Fz': {
    id: 'sensors.shortDescriptions.Fz',
    defaultMessage: 'Infer, hypothesize, predict, generalize, interpret.',
  },
  'F4': {
    id: 'sensors.shortDescriptions.F4',
    defaultMessage: 'Categorize and define, use metaphors, make jokes.',
  },
  'F8': {
    id: 'sensors.shortDescriptions.F8',
    defaultMessage: 'Emphasize beliefs, stay modest, and recall literal details.',
  },
  'FT9': {
    id: 'sensors.shortDescriptions.FT9',
    defaultMessage: 'Monitor and analyze speech for truth and accuracy.',
  },
  'FC5': {
    id: 'sensors.shortDescriptions.FC5',
    defaultMessage: 'Rapid and efficient responses to completing tasks.',
  },
  'FC1': {
    id: 'sensors.shortDescriptions.FC1',
    defaultMessage: 'Planful learning, self-talk to think, linear thinker.',
  },
  'FC2': {
    id: 'sensors.shortDescriptions.FC2',
    defaultMessage: 'Exploratory learner, try options, adapt, stay flexible.',
  },
  'FC6': {
    id: 'sensors.shortDescriptions.FC6',
    defaultMessage: 'Understand emotions in a social context, Dramatic style.',
  },
  'FTX': {
    id: 'sensors.shortDescriptions.FTX',
    defaultMessage: 'Identify structure, seek familiarity, drive to closure.',
  },
  'T3': {
    id: 'sensors.shortDescriptions.T3',
    defaultMessage: 'Hear content, parse words, and use correct grammar.',
  },
  'C3': {
    id: 'sensors.shortDescriptions.C3',
    defaultMessage: 'Follow steps, apply facts/figures, and use right hand.',
  },
  'Cz': {
    id: 'sensors.shortDescriptions.Cz',
    defaultMessage: 'Get energized, stay calm and flexible, express with body.',
  },
  'C4': {
    id: 'sensors.shortDescriptions.C4',
    defaultMessage: 'Move with rhythm, recall beauty, and draw artfully.',
  },
  'T4': {
    id: 'sensors.shortDescriptions.T4',
    defaultMessage: 'Hear voice tones/melodies, detect intent, and weigh ethics.',
  },
  'CP5': {
    id: 'sensors.shortDescriptions.CP5',
    defaultMessage: 'Focus on meeting external standards for performance.',
  },
  'CP1': {
    id: 'sensors.shortDescriptions.CP1',
    defaultMessage: 'Imitate actions, use precision techniques with tools.',
  },
  'CP2': {
    id: 'sensors.shortDescriptions.CP2',
    defaultMessage: 'Weigh meaning of whole scene, design appeal, random search',
  },
  'CP6': {
    id: 'sensors.shortDescriptions.CP6',
    defaultMessage: 'Focus on feeling inner harmony and personal satisfaction.',
  },
  'T5': {
    id: 'sensors.shortDescriptions.T5',
    defaultMessage: 'Notice social feedback, analyze faces, and learn facts.',
  },
  'P3': {
    id: 'sensors.shortDescriptions.P3',
    defaultMessage: 'Track movement, hold boundaries, notice threats. Read quickly, do arithmetic, identify objects.',
  },
  'Pz': {
    id: 'sensors.shortDescriptions.Pz',
    defaultMessage: 'Figure out and mentally simulate how something works.',
  },
  'P4': {
    id: 'sensors.shortDescriptions.P4',
    defaultMessage: 'Feel body sensations and maybe feel others\' emotions. Weight many factors, risks and uncertainties at once.',
  },
  'T6': {
    id: 'sensors.shortDescriptions.T6',
    defaultMessage: 'Notice symbols, foresee the future, and recall faces.',
  },
  'PO7': {
    id: 'sensors.shortDescriptions.PO7',
    defaultMessage: 'Check visual criteria, quality control, methodical search.',
  },
  'O1': {
    id: 'sensors.shortDescriptions.O1',
    defaultMessage: 'See construction and mentally manipulate objects.',
  },
  'O2': {
    id: 'sensors.shortDescriptions.O2',
    defaultMessage: 'See artistic themes and attend to body language.',
  },
  'PO8': {
    id: 'sensors.shortDescriptions.PO8',
    defaultMessage: 'Body memories, compose for style and creative impact.',
  },
})

const descriptions = defineMessages({
  'Fp1': {
    id: 'sensors.descriptions.Fp1',
    defaultMessage: 'Stay focused. Make decisions and select among options. Be results-oriented and sharp. Screen out distractions and criticism. Evaluate situations according to a principle. Notice and correct errors. Clarify needs, goals, and ideas. Get organized. Show confidence. With overuse, may be rigid and closed to input.',
  },
  'Fpz': {
    id: 'sensors.descriptions.Fpz',
    defaultMessage: 'Act responsibly and appropriately. Balance head and heart, set realistic expectations, and inhibit impulses. Juggle and prioritize multiple ongoing tasks. Delay quick gratification for later benefit. Self-motivate. Act with social grace and moral fortitude. With overuse, may not feel free to let loose.',
  },
  'Fp2': {
    id: 'sensors.descriptions.Fp2',
    defaultMessage: 'Stay open to new data and experiences. Seek stimulating ideas and activities. Engage in a creative process. Mix and match. Navigate a situation and know when all ideas are in. Reflect on new data, delving into criticism for self-awareness. Show natural, honest expressions. With overuse, may get off-task.',
  },
  'F7': {
    id: 'sensors.descriptions.F7',
    defaultMessage: 'Think using analogies. Notice what fits a situation and make inferences about it. Pick up skills by observing others. Mirror others\' behavior to build rapport. Imagine someone else\'s situation, maybe with empathy. Visualize another place or time, maybe fancifully. With overuse, everything may feel relative and uncertain.',
  },
  'F3': {
    id: 'sensors.descriptions.F3',
    defaultMessage: 'Mentally plan ahead a sequence of actions to take. Follow a chain of reasoning. Backtrack to correct thinking due to logic errors. Read or listen with an objective mindset, working only from what is given. Wordsmith precisely. With overuse, may get legalistic and be too literal-minded.',
  },
  'Fz': {
    id: 'sensors.descriptions.Fz',
    defaultMessage: 'Make a best guess. Given what\'s known or likely, come up with a hypothesis, infer a conclusion, offer a prediction, pose a question, make a generalization, or interpret what\'s happening. Use talk to influence others and for self-control. With overuse, we may confuse theories with facts.',
  },
  'F4': {
    id: 'sensors.descriptions.F4',
    defaultMessage: 'Float with abstract ideas. Accurately place concepts by testing them against many categories at once to find a best-fit. Form compelling metaphors, where the parts of parallel ideas match up. Link meanings and emotions to words. Get puns and make jokes. With overuse, others may not "get" our ideas.',
  },
  'F8': {
    id: 'sensors.descriptions.F8',
    defaultMessage: 'Hold to absolutes. Identify our beliefs, what is true regardless of context. Evaluate people, activities, and values in terms of like or dislike. Remain modest. Not embellish as we recall details with high accuracy. With overuse, may strongly resist whatever crosses what\'s personally important.',
  },
  'FT9': {
    id: 'sensors.descriptions.FT9',
    defaultMessage: 'Closely relate what’s said to what you know. Improve communication by crafting a better analogy, noticing when a speaker mentions an exception, or compare what is said versus evidence of what’s seen. Sort truthful statements from lies. Overuse can breed suspicion.',
  },
  'FC5': {
    id: 'sensors.descriptions.FC5',
    defaultMessage: 'Excel at doing complex tasks. Speak and act precisely and efficiently according to a set of rules. Monitor yourself and say "no" to impulses, emotional appeal, and mental clutter. Rapidly organize ideas into a fluent presentation. With overuse, may come off as unfeeling, focused only on utility.',
  },
  'FC1': {
    id: 'sensors.descriptions.FC1',
    defaultMessage: 'Work through options for action for what you will say and do next or off in the future. As part of that, engage in self-talk, or speak under your breath or aloud while naming objects, reasons, steps, etc. as part of a step-by-step plan. Use speech muscles. With overuse, may over-plan.',
  },
  'FC2': {
    id: 'sensors.descriptions.FC2',
    defaultMessage: 'Understand by doing. This is experiential: try several things, notice whether you got a desirable result, adapt from mistakes, and so on. Stay flexible. Actions and ideas have a dramatic, melodic, rhythmic, or daring style. With overuse, may have a hard time with plans.',
  },
  'FC6': {
    id: 'sensors.descriptions.FC6',
    defaultMessage: 'Get comfortable with emotional complexity. Name shades of feeling. Monitor one\'s own and others\' feelings. Know how words and actions will impact people emotionally. Speak dramatically. Like art, music, writing and such to be evocative. With overuse, may come off as melodramatic or explosive.',
  },
  'FTX': {
    id: 'sensors.descriptions.FTX',
    defaultMessage: 'Identify with what is familiar. When presented with an object or idea, do you recognize its structure—its design, or arrangement? Knowing the answer helps you move to closure with an opinion or judgment. Overuse can show as intolerance of uncertainty or ambiguity.',
  },
  'T3': {
    id: 'sensors.descriptions.T3',
    defaultMessage: 'Good at speaking. Focus on word content and usage, on precisely what\'s said. Distinguish when use of one word over another significantly alters a meaning. Talk to manage socially awkward or painful situations. If trained, hear music in a technical way. With overuse, may get verbose.',
  },
  'C3': {
    id: 'sensors.descriptions.C3',
    defaultMessage: 'Notice and follow scripts, formulas, and guides. Draw accurate charts and diagrams, take precise measures, and color inside the lines. Memorize and execute a pattern of moves (dance steps, etc.). Accurately recall facts and figures. With overuse, may seem robotic or feel stuck in a box.',
  },
  'Cz': {
    id: 'sensors.descriptions.Cz',
    defaultMessage: 'Highly physically or mentally active. Strongly motivated toward success, often in multiple interests, able to coordinate many elements and bring them together. Sensitive to sensory input, often with fine motor skills. Move energetically. With overuse, may be anxious or hyper-active.',
  },
  'C4': {
    id: 'sensors.descriptions.C4',
    defaultMessage: 'Express emotions truthfully through the body. Draw, paint, sculpt, or otherwise excel at artistic endeavors, making forms come alive. Recall beauty. Enjoy a natural, casual grace. Move with rhythm. Easily improvise enticing moves. With overuse, may be poor on facts and measures.',
  },
  'T4': {
    id: 'sensors.descriptions.T4',
    defaultMessage: 'Hear melody, voice tone, and intent. Detect inauthenticity. Notice when something "resonates" personally or when a person\'s voice doesn\'t match their words, betraying phoniness. Factor in ethics and notice potential violations. With overuse, what\'s "really heard" may be irritating.',
  },
  'CP5': {
    id: 'sensors.descriptions.CP5',
    defaultMessage: 'Easily integrate all kinds of information for outward success. Verbally creative with high reading comprehension. Skilled at logic and calculations. And also have fine body control for dance, athletics or such. Focus on external standards. Overuse may lead to lack of self-reflection.',
  },
  'CP1': {
    id: 'sensors.descriptions.CP1',
    defaultMessage: 'Learn by observing and imitating others for an action or task. Easily recall and apply techniques with precision tools. Pin-point and control your body, seeing and feeling how muscles and such function. Work through pain to achieve a result. May over-rely on copying others.',
  },
  'CP2': {
    id: 'sensors.descriptions.CP2',
    defaultMessage: 'Learn from an intuitive or empathic sense or a pallet of emotional memories. Bring together diverse elements for an action or task. Compose based on what feels right creatively overall. Focus on style and emotional impact over technical accuracy. May over-rely on feeling motivated.',
  },
  'CP6': {
    id: 'sensors.descriptions.CP6',
    defaultMessage: 'Weigh internal values, intent, and preferences to get personal satisfaction. Self-reflect with patience. Spend time comparing, contrasting, and considering situations, such as selecting from among products or ideas based on a favorite brand or belief. Overuse may impair objectivity.',
  },
  'T5': {
    id: 'sensors.descriptions.T5',
    defaultMessage: 'Attend to visual details, adjusting them to be precise. Analyze others\' facial expressions. Notice how people respond to us and then adjust our behavior to aid, conform, appease or be romantic. Commit facts to memory for later use. With overuse, may seem picky or feel awkward or embarrassed.',
  },
  'P3': {
    id: 'sensors.descriptions.P3',
    defaultMessage: 'Quickly notice and identify objects, particularly "oddballs" in the environment. Are alert to challenges, threats, and personal space. Think tactically. Label and compute moves. Act in a team or a pack. Integrate vision and motion. With overuse, may be overly self-protective.',
  },
  'Pz': {
    id: 'sensors.descriptions.Pz',
    defaultMessage: 'Understand how things work. Pull together what we can see and touch, and how elements move around, to build a practical, working understanding. It\'s like we construct and run a machine in our mind\'s eye. Mostly non-verbal. With overuse, we may get stuck figuring out everything.',
  },
  'P4': {
    id: 'sensors.descriptions.P4',
    defaultMessage: 'Weigh many factors at once: pros and cons, highs and lows, risks and uncertainties, viewpoints and missing pieces. Get an overall impression, such as a group\'s potential, someone\'s mindset, or your own well-being. Locate balance, leverage, or an "aha" insight. With overuse, may miss key details.',
  },
  'T6': {
    id: 'sensors.descriptions.T6',
    defaultMessage: 'Notice patterns. See how events connect. Link now to your life history and foresee what will come next. Interpret a face or image within a group or context, sensing what\'s going on. Notice symbols and their hidden meaning. With overuse, may worry a lot or just "know" things but cannot say why.',
  },
  'PO7': {
    id: 'sensors.descriptions.PO7',
    defaultMessage: 'Observe in an active, detailed way for quality control. Focus on visual criteria such as specific features, shapes, and such to interpret, analyze, and correct them. Make logical inferences from visual data. Search in a methodical way. Overuse may lead to seeing only what you’re looking for.',
  },
  'O1': {
    id: 'sensors.descriptions.O1',
    defaultMessage: 'Focus on visual details. Mentally manipulate objects. Visualize and manipulate a 3-D object in our mind\'s eye, to rotate, disassemble or rearrange it. Read a chart or diagram. Analyze spaces like an engineer. Navigate a complex user interface. With overuse, may only see parts and miss the whole.',
  },
  'O2': {
    id: 'sensors.descriptions.O2',
    defaultMessage: 'Delight in visual beauty. View the richness of a photograph or painting. Notice body language and gain a visual impression of a person. Sense how shapes, colors, textures, shapes, and perspectives fit aesthetically. See visual themes. Notice life\'s innate beauty. With overuse, may be "allergic" to ugliness.',
  },
  'PO8': {
    id: 'sensors.descriptions.PO8',
    defaultMessage: 'Observe in a broad, holistic way, weighing the meanings of a whole scene. Notice how things move in patterns from bird flocks to dance moves. Design to appeal to psychology and emotions. Search in a random way, open to surprise. Overuse may mean missing details.',
  },
})

const inTheWorkplace = defineMessages({
  'Fp1': {
    id: 'sensors.inTheWorkplace.Fp1',
    defaultMessage: '<li>Easily make decisions or judgments.</li><li>Tend to set outcomes, ignore distractions, and move ahead with a positive winning attitude.</li><li>Ask, "What\'s the goal?" and "How can we focus for success?"</li><li>Are likely to look confident and tend to take charge or set standards.</li><li>Respond well to leadership opportunities.</li><li>Prefer a workplace that\'s on-the-ball with objectives, strategies, and resources to succeed.</li>',
  },
  'Fpz': {
    id: 'sensors.inTheWorkplace.Fpz',
    defaultMessage: '<li>Easily maintain inner and outer balance.</li><li>Tend to prioritize, stay realistic, and self-manage to fulfill even the difficult tasks.</li><li>Ask, "What needs attention?" and "How can we better handle all of this activity?"</li><li>Are likely to stay sensible and sociable with self-control.</li><li>Respond well when having to quickly integrate new information into decision-making.</li><li>Prefer a workplace that allows autonomy, with multiple opportunities, managing themselves while inspiring and cooperating with others.</li>',
  },
  'Fp2': {
    id: 'sensors.inTheWorkplace.Fp2',
    defaultMessage: '<li>Easily take in lots of data and options.</li><li>Tend to brainstorm and explore, seeking more input, discussion, and reflection.</li><li>Ask, "What do you perceive?" and "What questions or ideas do you have?"</li><li>Are likely creative with original ideas.</li><li>Respond well to change, and may be seen as innovative or skilled when goals and situations are unclear.</li><li>Prefer a workplace with a range of tools and opportunities to discover and experiment.</li>',
  },
  'F7': {
    id: 'sensors.inTheWorkplace.F7',
    defaultMessage: '<li>Easily handle a variety of people and situations.</li><li>Tend to learn quickly when shown what to do and usually get along well with others.</li><li>Ask "What if...?" and "What\'s another situation where that applies?"</li><li>Are likely to ask questions or play devil\'s advocate, and can juggle may things in your head at once.</li><li>Respond well to analogies, examples, case studies, role models, and demonstrations.</li><li>Prefer a workplace that changes from day to day, and asks you to adapt.</li>',
  },
  'F3': {
    id: 'sensors.inTheWorkplace.F3',
    defaultMessage: '<li>Easily follow step-by-step plans.</li><li>Tend to focus on accurate, clear reasons based on data.</li><li>Ask, "If that\'s true, what else is logically true?" and "What will come next?"</li><li>Are likely a concrete thinker, and may be seen as technical, literal-minded, or highly logical.</li><li>Respond well to written instructions with time for analysis, planning, checking, and correcting.</li><li>Prefer a workplace that\'s steady and predictable.</li>',
  },
  'Fz': {
    id: 'sensors.inTheWorkplace.Fz',
    defaultMessage: '<li>Easily explore new or unclear problems.</li><li>Tend to form hypotheses and make predictions based on data.</li><li>Ask, "What can we infer?" and "Maybe this is what this means?"</li><li>Are likely to ask a lot of questions, and may be seen as skeptical but also curious.</li><li>Respond well to the scientific process, with time to gather data, analyze, interpret, predict, and make suggestions.</li><li>Prefer a workplace that affords thoughtful discussion.</li>',
  },
  'F4': {
    id: 'sensors.inTheWorkplace.F4',
    defaultMessage: '<li>Easily follow abstract ideas.</li><li>Tend to use metaphors and think conceptually, often making big leaps in understanding.</li><li>Ask, "What\'s the main concept?" and "What\'s the metaphor for...?"</li><li>Are likely an abstract thinker, and may be seen as intellectual or quick with humor.</li><li>Respond well to conceptual models, and comfortable when words and ideas have multiple, ambiguous, and even opposing meanings.</li><li>Prefer a workplace full of creative banter and ideas.</li>',
  },
  'F8': {
    id: 'sensors.inTheWorkplace.F8',
    defaultMessage: '<li>Easily identify and stick to what\'s important.</li><li>Tend to be devoted to your ideals, with a solid senses of your own and others\' identity and values.</li><li>Ask "What do you believe?" and "What principle or value applies to this?"</li><li>Are likely modest about plans and ideas, and may be seen as grounded, loyal, and consistent.</li><li>Respond well when you\'re free to do what\'s personally worthwhile.</li><li>Prefer a workplace where others are honest with integrity and good character.</li>',
  },
  'FT9': {
    id: 'sensors.inTheWorkplace.FT9',
    defaultMessage: '<li>Easily notice others\' misstatements (intended or not).</li><li>Tend to mentally double-check what people are saying against what you know and want to clarify or correct that.</li><li>Ask "What is the evidence for this?" and "How can we get things in better alignment?"</li><li>Are likely to strive for clearer, more honest and accurate communication.</li><li>Respond well when data, analysis, policies, and presentation all align.</li><li>Prefer a workplace that encourages accurate and honest debate.</li>',
  },
  'FC5': {
    id: 'sensors.inTheWorkplace.FC5',
    defaultMessage: '<li>Easily focus on complex, detailed tasks.</li><li>Tend to be fast and efficient, and look for short-cuts and ways to improve.</li><li>Ask, "Can this be more efficient?", and "How has performance improved?"</li><li>Are likely skilled with your hands.</li><li>Respond well to rewards, and may be seen as a high performer.</li><li>Prefer a workplace where you can focus on one thing at a time, with few distractions.</li>',
  },
  'FC1': {
    id: 'sensors.inTheWorkplace.FC1',
    defaultMessage: '',
  },
  'FC2': {
    id: 'sensors.inTheWorkplace.FC2',
    defaultMessage: '',
  },
  'FC6': {
    id: 'sensors.inTheWorkplace.FC6',
    defaultMessage: '<li>Easily understand people\'s emotions.</li><li>Tend to use dramatic language, tell stories, and refer to human relationships.</li><li>Ask, "How did he/she feel?" and "What emotion moved him/her to do that?"</li><li>Are likely to notice who works well together, or not, and how people are feeling about each other.</li><li>Respond well to sharing personal information and getting to know others\' likes and dislikes, families, and so forth.</li><li>Prefer a workplace that\'s social and friendly.</li>',
  },
  'FTX': {
    id: 'sensors.inTheWorkplace.FTX',
    defaultMessage: '<li>Easily make comparisons.</li><li>Tend to refer to what you are certain about and hold to what is familiar, settled, and appropriate for someone\'s role.</li><li>Ask "How can we make a solid decision?" and "Can we reduce risk and uncertainty here?"</li><li>Are likely to point to what is established and sure, or conversely, potential dangers, such as the risks of a proposed change.</li><li>Respond well to recognized and respected persons, hierarchies, and processes.</li><li>Prefer a workplace that is stable and comfortable.</li>',
  },
  'T3': {
    id: 'sensors.inTheWorkplace.T3',
    defaultMessage: '<li>Easily speak what\'s on your mind.</li><li>Tend to talk a bit, with a focus on summarizing and explaining things using the right words.</li><li>Ask, "Can you tell me the key idea?" and "How can we speak more effectively?"</li><li>Are likely a good speaker or teacher, even when improvising.</li><li>Respond well to content, debating, asking and answering questions, using the right words, and stating facts.</li><li>Prefer a workplace that allows you to talk.</li>',
  },
  'C3': {
    id: 'sensors.inTheWorkplace.C3',
    defaultMessage: '<li>Easily work with outlines, fact sheets, formulas, scripts, blueprints, and templates.</li><li>Tend to use tools for getting work done, with a focus on following steps and will come up with how-to procedures if none are present.</li><li>Ask, “Is there a formula for this?” and “How can we break the actions into steps?”</li><li>Are likely to ask for time to review instructions, get prepared, walk out or diagram what’s needed, recall relevant facts, practice by rote, and confirm accuracy.</li><li>Respond well  to complex procedures and how to represent them using flowcharts or blueprints.</li><li>Prefer a workplace that is well-organized and efficient.</li>',
  },
  'Cz': {
    id: 'sensors.inTheWorkplace.Cz',
    defaultMessage: '<li>Self-motivated, applying a lot of energy.</li><li>Tend to leverage confidence, interests, and position.</li><li>Ask, “What\'s needed to do this for real?” and “What resources are at hand?”</li><li>Benefit to have a lab or workshop for after-hours projects.</li><li>Respond well to serious, challenging applications as a team-member, or often with an opportunity as a team-leader.</li><li>Prefer a workplace that affords easy, agile use of resources.</li>',
  },
  'C4': {
    id: 'sensors.inTheWorkplace.C4',
    defaultMessage: '<li>Easily handle artistic challenges to produce moving beauty.</li><li>Tend to use tools for artistic creation, either using your hands to sketch out thoughts or your body to express yourself, such as through dance.</li><li>Ask, “Can you illustrate that?” and “What ideas and emotions can this convey?”</li><li>Are likely to show interaction, emotion, and ideas through artwork or movement, drawing upon favorite artistic styles and subject matter.</li><li>Respond well when able to explore and create in visual, manual, or performing arts.</li><li>Prefer a workplace that allows free flow of creative expression.</li>',
  },
  'T4': {
    id: 'sensors.inTheWorkplace.T4',
    defaultMessage: '<li>Easily hear what others are really saying.</li><li>Tend to focus on people\'s intentions and ethics.</li><li>Ask, "How does that sound?" and "What did the person leave unspoken?"</li><li>Are likely a good listener, quickly hearing people\'s moods, wants, needs, and values, and may be skilled at persuading others or musically inclined.</li><li>Respond well to a positive and honest tone of voice.</li><li>Prefer a workplace with music, nature sounds, funny noises, and when needed, golden silence.</li>',
  },
  'CP5': {
    id: 'sensors.inTheWorkplace.CP5',
    defaultMessage: '<li>Easily attend to external measures for success.</li><li>Tend to focus on the most popular or well-received ways of doing things. In your work, you use the terminology, imagery, methods, etc. expected by others.</li><li>Ask, "What counts as success?" and "What does it take to get a \'A\' grade on this?"</li><li>Are likely to do best when given external measures, best practices guidelines, etc.</li><li>Respond well to feedback and validation by respected peers and authority figures.</li><li>Prefer a workplace that rewards well-rounded, polished performance.</li>',
  },
  'CP1': {
    id: 'sensors.inTheWorkplace.CP1',
    defaultMessage: '',
  },
  'CP2': {
    id: 'sensors.inTheWorkplace.CP2',
    defaultMessage: '',
  },
  'CP6': {
    id: 'sensors.inTheWorkplace.CP6',
    defaultMessage: '<li>Easily attend to your inner compass or inner drummer.</li><li>Tend to focus on the most personally satisfying method, style or result, whether or not that fits official procedures or others\' standards.</li><li>Ask, "How does this fit for me?" and "How does this go with everything else I\'m doing?"</li><li>Are likely to deliver your best results when you are in touch with and work out what appeals most to you, what "clicks" or "gels" internally.</li><li>Respond well when given the time and space to do things their own way.</li><li>Prefer a workplace that is relaxed, free-flowing and allows for self-expression.</li>',
  },
  'T5': {
    id: 'sensors.inTheWorkplace.T5',
    defaultMessage: '<li>Easily attend to facts, rules, and details.</li><li>Tend to take your time to consider other\'s reactions and adjust things to be just right.</li><li>Ask, "How do others react?" and "What details can we adjust to improve this?"</li><li>Are likely to accommodate others\' input.</li><li>Respond well to positive face-to-face feedback, and may be seen as sensitive or precise.</li><li>Prefer a workplace that\'s safe, where people are careful and considerate.</li>',
  },
  'P3': {
    id: 'sensors.inTheWorkplace.P3',
    defaultMessage: '<li>Easily reply to a challenge.</li><li>Tend to quickly notice and respond to things that stick out as interesting or unusual.</li><li>Ask, "Who is with me on this?" and "What make or model is this?"</li><li>Are likely to know lots of models, brands, etc.--all the variety in a category of things.</li><li>Respond well to competition, especially when there are concrete obstacles, clear measures or rules, and tangible rewards.</li><li>Prefer a workplace or industry that functions like a team sport.</li>',
  },
  'Pz': {
    id: 'sensors.inTheWorkplace.Pz',
    defaultMessage: '<li>Easily understand how a process works when able to sketch or walk through it.</li><li>Tend to think in a visual-spatial way, understanding situations as complex systems.</li><li>Ask, “How does it work"?”" and “What occurs when you work it like this or that?”</li><li>Focus on hands-on tool use, trying various parameters (settings) to locate the best performance. May take things apart.</li><li>Respond well to machines, models, and computer-based simulations where its possible to tinker with and move parts.</li><li>Prefer a workplace that allows free use of tools and resources.</li>',
  },
  'P4': {
    id: 'sensors.inTheWorkplace.P4',
    defaultMessage: '<li>Easily handle complex situations.</li><li>Tend to explore many inputs, perspectives, and variables, viewing everything as a system.</li><li>Ask, "What are the variables in play?" and "Where is a leverage point?"</li><li>Are likely to consider multiple viewpoints, get input from others, and aim to think outside the box to find a best solution.</li><li>Respond well to opportunities for creative problem solving.</li><li>Prefer a workplace that focuses on well-being as much as the work.</li>',
  },
  'T6': {
    id: 'sensors.inTheWorkplace.T6',
    defaultMessage: '<li>Easily notice patterns in visual data.</li><li>Tend to think about the future and what opportunities or challenges are coming next.</li><li>Ask, "What patterns to do you see?" and "What will happen in the future?"</li><li>Likely notice how past and future relate, and how everything works as a system.</li><li>Respond well to timelines, symbols, icons, totems, and bulletin boards--whatever allows you to represent everything at once.</li><li>Prefer a workplace that helps people develop their potential.</li>',
  },
  'PO7': {
    id: 'sensors.inTheWorkplace.PO7',
    defaultMessage: '<li>Easily notice mistakes, especially in how things look.</li><li>Tend to have specific criteria for how things should look and flow according to a process you have in mind.</li><li>Ask, "Can we do better?" and " What does this data (or chart or graph) say to help us improve going forward?"</li><li>Are likely to focus on quality and suggest logical improvements to raise or control quality.</li><li>Are observant and actively seek out what you need.</li><li>Prefer a workplace that focuses on quality with openness on how to keep growing and improving effectiveness.</li>',
  },
  'O1': {
    id: 'sensors.inTheWorkplace.O1',
    defaultMessage: '<li>Easily focus in on complex visual details.</li><li>Tend to take something apart in your mind\'s eye, tracking how things fit and work together.</li><li>Ask, "What do you see?" and "How does it function?"</li><li>Are likely to observe, take apart, and solve problems like an engineer would.</li><li>Respond well to flow charts, bar graphs, diagrams, maps, 3d-models, and blueprints.</li><li>Prefer a workplace that\'s functional, with open spaces and practical designs.</li>',
  },
  'O2': {
    id: 'sensors.inTheWorkplace.O2',
    defaultMessage: '<li>Easily make an appealing first impression.</li><li>Tend to make presentations, products, workspaces, etc look smart and visually engaging, if not exciting and highly motivating.</li><li>Ask, "Is it attractive?" and "What themes or styles do you see?"</li><li>Are likely a perceptive judge of character based on body language, and may be skilled at counseling, negotiating or diagnosing.</li><li>Respond well to beauty, and may be seen as artistic.</li><li>Prefer a workplace with art, natural vistas, visual media, and elegant design.</li>',
  },
  'PO8': {
    id: 'sensors.inTheWorkplace.PO8',
    defaultMessage: '<li>Easily notice how things move.</li><li>Tend to focus on the meaning of a situation, if it\'s going well, in a comfortable or impressive way.</li><li>Ask, "Is it going well?" and "Can we look at this in a holistic way?"</li><li>Are likely to focus on design, style and emotional or psychological impact of an idea, a presentation or a product.</li><li>Are observant and remain open to everything that comes to them.</li><li>Prefer a workplace that is relaxed, flexible, open and where people can work at their own pace depending on inspiration and insights.</li>',
  },
})

export const messages = {
  descriptions,
  inTheWorkplace,
  shortDescriptions,
  titles,
}

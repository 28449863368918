import React from 'react'
import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'
import Tabs from '@mui/material/Tabs'

import { noPrint } from '@/utils/print'

const tabsStyle = {
  position: 'sticky',
  top: 0,
  zIndex: 9,
  ...noPrint,
}

// TODO: Move scroll logic from ReportTabs to NavigationTabs
export function NavigationTabs ({ children, onChange, value, ...otherProps }) {
  return (
    <Paper square elevation={1} {...otherProps} sx={{ ...tabsStyle, ...otherProps.sx }}>
      <Container sx={{ overflowX: 'auto'}}>
        <Tabs variant='scrollable' scrollButtons='auto' aria-label='report-tabs'
          value={value} onChange={onChange}>
          {children}
        </Tabs>
      </Container>
    </Paper>
  )
}

export default NavigationTabs

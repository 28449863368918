import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import LoadingButton from '@mui/lab/LoadingButton'
import Alert from '@mui/material/Alert'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'

import { userIdSelector } from '@/features/auth/store/reducers/tokens'
import { useAddEEGSessionMutation } from '@/features/eeg-sessions/services/api'
import DateTimePicker from '@/features/forms/components/DateTimePicker'
import BodyContainer from '@/features/ui/components/BodyContainer'
import Header from '@/features/ui/components/Header'
import Helmet from '@/features/ui/components/Helmet'
import { activeRoleSelector } from '@/features/ui/store/reducers/userRole'
import UserAutocomplete from '@/features/users/components/UserAutocomplete'
import { useGetUserDetailsQuery } from '@/features/users/services/api'
import { USER_ROLE_ADMIN } from '@/features/users/store/propTypes/roles'

const textFieldProps = {
  variant: 'outlined',
  margin: 'dense',
  fullWidth: true,
}

function CreateReportView () {
  const intl = useIntl()
  const navigate = useNavigate()
  const activeRole = useSelector(activeRoleSelector)

  // NOTE: Not available for users
  const practitionerId = useSelector(userIdSelector)
  const { data: practitionerDetails } = useGetUserDetailsQuery(practitionerId, { skip: !practitionerId })

  const [addEEGSession, { isLoading }] = useAddEEGSessionMutation()

  const [doneAt, setDoneAt] = React.useState(new Date())
  const [errors, setErrors] = React.useState({})
  const [practitioner, setPractitioner] = React.useState(null)
  const [title, setTitle] = React.useState('')
  const [subject, setSubject] = React.useState(null)

  React.useEffect(() => {
    if (!practitionerDetails) return
    setPractitioner(practitionerDetails)
  }, [practitionerDetails])

  const handleChange = (set) => (e) => { set(e.target.value) }

  const handleClick = () => {
    return addEEGSession({
      doneAt,
      practitionerId: practitioner ? practitioner.id : undefined,
      title,
      userId: subject ? subject.id : undefined,
    })
      .unwrap()
      .then((session) => navigate(`/account/brain-imaging/reports/${session.id}/edit`))
      .catch(({ status, data }) => {
        const errors = {}
        // TODO: Handle form validation errors
        errors.general = <FormattedMessage defaultMessage='Internal error' />
        setErrors(errors)
      })
  }

  const headerTitle = intl.formatMessage({ defaultMessage: 'Add a session', description: 'Header title' })

  return (
    <React.Fragment>
      <Helmet
        title={headerTitle} />
      <Header component='h1' variant='h1' narrow
        title={headerTitle} />
      <BodyContainer>
        <form noValidate>
          <Card variant='outlined'>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <TextField required id='title' name='title' {...textFieldProps}
                    value={title} onChange={handleChange(setTitle)}
                    placeholder={intl.formatMessage({ defaultMessage: '10 daily activities' })}
                    label={<FormattedMessage defaultMessage='Title' />} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DateTimePicker required id='doneAt' name='doneAt' textFieldProps={textFieldProps}
                    value={doneAt} onChange={(value) => { setDoneAt(value ? value : null) }}
                    label={<FormattedMessage defaultMessage='Session date' />} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <UserAutocomplete required disabled={activeRole !== USER_ROLE_ADMIN} value={practitioner} onChange={(e, option) => { setPractitioner(option || null) }}
                    textFieldProps={{ ...textFieldProps, label: <FormattedMessage defaultMessage='Practitioner' /> }} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <UserAutocomplete value={subject} onChange={(e, option) => { setSubject(option || null)}}
                    textFieldProps={{ ...textFieldProps, label: <FormattedMessage defaultMessage='Subject' /> }} />
                </Grid>
              </Grid>
              {errors.general && (
                <Alert severity='error' sx={{ my: 3 }}>
                  {errors.general}
                </Alert>
              )}
            </CardContent>
            <CardActions>
              <LoadingButton type='submit' size='large'
                onClick={handleClick} disabled={!title || !practitioner || !doneAt} loading={isLoading}>
                <FormattedMessage defaultMessage='Add' description='Card action' />
              </LoadingButton>
            </CardActions>
          </Card>
        </form>
      </BodyContainer>
    </React.Fragment>
  )
}

export default CreateReportView

import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import Box from '@mui/material/Box'

import Benefits from './Benefits'
import Services from './Services'
import { br } from '@/features/intl/utils/intl'
import BodyContainer from '@/features/ui/components/BodyContainer'
import Heading from '@/features/ui/components/Heading'
import Header from '@/features/ui/components/Header'
import TextMediaContainer from '@/features/ui/components/TextMediaContainer'
import { getMediaPictureSrc } from '@/utils/mediaStorage'

function LandingView () {
  const intl = useIntl()

  return (
    <React.Fragment>
      <Header component='h1' variant='h1' minHeight='100vh'
        title={<FormattedMessage defaultMessage='Explore{br}your differences' description='LandingView header title' values={{ br }} />}
        subheader={<FormattedMessage defaultMessage='We help people explore, understand and accept differences to build trust and find balance.' description='LandingView header subheader' />}
        buttonProps={{
          children: <FormattedMessage defaultMessage="Let's explore" description='LandingView header button' />,
          href: '#services',
        }} />
      <Box id='services' sx={{ my: 0.5 }}>
        <Services />
      </Box>
      {/* Section: Safe space */}
      <TextMediaContainer component='h3'
        subheader={<FormattedMessage defaultMessage='We offer people' description='Heading subheader' />}
        title={<FormattedMessage defaultMessage='A safe space to experience and share freely' description='Heading title' />}
        text={<FormattedMessage defaultMessage='We offer people a safe space to experience and share freely to work on both personal and collective balance.{br}{br}Today we take individuals, teams and executives on an exciting journey to explore, understand and accept themselves with novel body and mind experiences.' values={{ br }} />}
        imageProps={{
          alt: intl.formatMessage({ defaultMessage: 'Safe spaces', description: 'Alt text' }),
          src: getMediaPictureSrc('/misc/landing/safe-space-frames-768x768.png?alt=media&token=6483bf37-02c6-4b55-98b9-f7640c1a5e6d'),
        }}
        buttonProps={{
          children: <FormattedMessage defaultMessage='Get in touch 👋' description='Button label' />,
          href: '/contact-us',
        }} />
      {/* Section: areas we help: stress, communication, growth (leadership, alchemy, ...)? (cf. slide deck) */}
      <BodyContainer>
        <Heading component='h2' variant='h2' gutterBottom
          title={<FormattedMessage defaultMessage='Areas we help' description='Heading title' />} />
        <Benefits />
      </BodyContainer>
      {/* Section: We develop an academy */}
      <TextMediaContainer component='h3' variant='text-right'
        subheader={<FormattedMessage defaultMessage='With an international community' description='Heading subheader' />}
        title={<FormattedMessage defaultMessage='We develop an academy' description='Heading title' />}
        text={<FormattedMessage defaultMessage='Working with innovative tools, we build a web platform to gather data, research accurate and accessible solutions to assess ourselves, provide curated content and access to certified coaches and practitioners to dig in.' />}
        imageProps={{
          alt: intl.formatMessage({ defaultMessage: 'Business academy frames', description: 'Alt text' }),
          src: getMediaPictureSrc('/misc/landing/academy-frames-768x768.png?alt=media&token=97cf8389-4e6b-4d7e-823c-58ab4d4d6376'),
        }}
        buttonProps={{
          children: <FormattedMessage defaultMessage='Join us' description='Button label' />,
          href: '/contact-us',
        }} />
    </React.Fragment>
  )
}

export default LandingView

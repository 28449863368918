import { SENSORS_MIRRORS } from '@/features/eeg-sessions/datasets/sensors'
/**
 * Retrieve sensors based on analysis data
 *
 * @param {EEGAnalysis} analysis
 * @return {{Array.<string>}} List of sensor ids
 */
export function getSensorsFromAnalysis (analysis) {
  const { circuits, normalizedScores } = analysis || {}

  if (normalizedScores) return Object.keys(normalizedScores)
  else if (circuits) {
    // TODO: Retrieve sensors from circuits data
  }
}

// WNG: This logic assumes left and central brain sensors only are defined
export const RIGHT_BRAIN_SENSORS = Object.keys(SENSORS_MIRRORS).map((id) => SENSORS_MIRRORS[id])
export function isSensorRightBrain (sensorId) {
  return RIGHT_BRAIN_SENSORS.includes(sensorId)
}

// WNG: This logic assumes left and central brain sensors only are defined
export const LEFT_BRAIN_SENSORS = Object.keys(SENSORS_MIRRORS)
export function isSensorLeftBrain (sensorId) {
  return LEFT_BRAIN_SENSORS.includes(sensorId)
}

export const SensorIdEnum = {
  AF3: 'AF3',
  AF4: 'AF4',
  AF7: 'AF7',
  AF8: 'AF8',
  AFZ: 'Afz',
  C1: 'C1',
  C2: 'C2',
  C3: 'C3',
  C4: 'C4',
  C5: 'C5',
  C6: 'C6',
  CP1: 'CP1',
  CP2: 'CP2',
  CP3: 'CP3',
  CP4: 'CP4',
  CP5: 'CP5',
  CP6: 'CP6',
  CPZ: 'CPz',
  CZ: 'Cz',
  F1: 'F1',
  F2: 'F2',
  F3: 'F3',
  F4: 'F4',
  F5: 'F5',
  F6: 'F6',
  F7: 'F7',
  F8: 'F8',
  F9: 'F9',
  F10: 'F10',
  FC1: 'FC1',
  FC2: 'FC2',
  FC3: 'FC3',
  FC4: 'FC4',
  FC5: 'FC5',
  FC6: 'FC6',
  FCZ: 'FCz',
  FP1: 'Fp1',
  FP2: 'Fp2',
  FPZ: 'Fpz',
  FT7: 'FT7',
  FT8: 'FT8',
  FT9: 'FT9',
  FT10: 'FT10', // Emotiv
  FTX: 'FTX', // Radiance
  FZ: 'Fz',
  IZ: 'Iz',
  O1: 'O1',
  O2: 'O2',
  O9: 'O9',
  O10: 'O10',
  OZ: 'Oz',
  P1: 'P1',
  P2: 'P2',
  P3: 'P3',
  P4: 'P4',
  P5: 'P5',
  P6: 'P6',
  P7: 'P7',
  P8: 'P8',
  P9: 'P9',
  P10: 'P10',
  PO3: 'PO3',
  PO4: 'PO4',
  PO7: 'PO7',
  PO8: 'PO8',
  PO9: 'PO9',
  POZ: 'POZ',
  PZ: 'Pz',
  T3: 'T3', // Radiance
  T4: 'T4', // Radiance
  T5: 'T5', // Radiance
  T6: 'T6', // Radiance
  T7: 'T7', // Emotiv
  T8: 'T8', // Emotiv
  TP7: 'TP7',
  TP8: 'TP8',
  TP9: 'TP9',
  TP10: 'TP10',
}

export const SENSOR_IDS = Object.values(SensorIdEnum)

/**
 * Map Emotiv IDs to radiance and brain map IDs.
 * TODO: Remove this mapping once all brain regions have descriptions and polygons
 * @param {!SensorEnumId} sensorId 
 * @returns {!SensorEnumId}
 */
export function toRadianceSensorId (sensorId) {
  // TODO:
  switch (sensorId) {
    case SensorIdEnum.FT10: return SensorIdEnum.FTX;
    case SensorIdEnum.P7: return SensorIdEnum.T5;
    case SensorIdEnum.P8: return SensorIdEnum.T6;
    case SensorIdEnum.T7: return SensorIdEnum.T3;
    case SensorIdEnum.T8: return SensorIdEnum.T4;
    default: return sensorId;
  }
}
import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'

import { br } from '@/features/intl/utils/intl'
import { userDetailsShape } from '@/features/users/store/propTypes'
import { getMediaPictureSrc } from '@/utils/mediaStorage'

const SRC_AVATAR_DARIO_NARDI = getMediaPictureSrc(
  '/misc/report-example/avatar-dario-nardi.jpg?alt=media&token=f681f2aa-2385-46a0-9cc3-cfc3bddf609a'
)

function IntroductionSection({ title, userDetails }) {
  const theme = useTheme()

  return (
    <React.Fragment>
      {!(title && userDetails) ? null : (
        <React.Fragment>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            alignItems="center"
            justifyContent="center"
            spacing={3}
            sx={{ mb: 8 }}
          >
            <Avatar
              src={userDetails.imageUrl}
              variant="rounded"
              sx={{
                width: 220,
                height: 220,
                border: `3px solid ${theme.palette.primary.light}`,
              }}
            />
            <Stack alignItems="center">
              <Typography component="div" variant="h1">
                <FormattedMessage
                  defaultMessage="{firstName}{br}{lastName}"
                  values={{
                    br,
                    firstName: userDetails.firstName,
                    lastName: userDetails.lastName,
                  }}
                />
              </Typography>
              <Typography component="div" variant="body1">
                <Box sx={{ maxWidth: '22em', textAlign: 'center' }}>
                  {title}
                </Box>
              </Typography>
            </Stack>
          </Stack>
        </React.Fragment>
      )}
      <FormattedMessage defaultMessage="Thank you for participating in a neuro-assessment session. This report provides detailed feedback based on your session. You will learn about your cognitive skills and neural wiring. Together, these illuminate your personality." />
      <Box sx={{ mt: 5 }}>
        {/* What's inside */}
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography component="h3" variant="h4">
              <FormattedMessage defaultMessage="Exploring what's inside" />
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormattedMessage
              defaultMessage="This report summarizes your neocortex activity in various ways. The neocortex is that big outer layer of the brain that is quintessentially human and the seat of conscious thought. As you may guess, the neocortex helps us handle numerous facets of our everyday life experience.{br}{br}EEG technology divides the neocortex into regions. EEG stands for electro-encephalo-gram. During your session, we used EEG to monitor these regions as you tried various activities. Textbooks, peer reviewed research, and numerous face-to-face sessions validate these regions. The EEG shows when more electricity flows in a region. More activity occurs when you are motivated and/or competent to do the skills handled by that region. Most importantly, when regions change together, we learn they are wired together. The result is a portrait of your brain's likely wiring.{br}{br}Despite the accuracy of an EEG session, your results may vary somewhat from one sitting to the next. Context and technology make a difference. Your results are a serious, scientific step to understand yourself but not a final word or assured to be true always or in greater detail. Nor is any medical diagnosis offered or implied."
              values={{ br }}
            />
          </AccordionDetails>
        </Accordion>
        {/* Ethics */}
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography component="h3" variant="h4">
              <FormattedMessage defaultMessage="Ethics" />
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormattedMessage defaultMessage="Your results are kept anonymous and confidential unless you indicate otherwise. Also, your results are not compared or normed against any general or specific population, although typical ranges are mentioned in one place as an optional reference point. and broadly speaking, know that one set of results is better or worse than any other, as the brain is a general-purpose organ that can be wired many different ways to meet a diversity of human needs." />
          </AccordionDetails>
        </Accordion>
        {/* Research & copyright */}
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography component="h3" variant="h4">
              <FormattedMessage defaultMessage="Research & copyright" />
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormattedMessage
              defaultMessage="This report is based on 15 years of hands-on research from {researcher} that links personality, brain waves, skills, creative flow, and emotional responses.{br}{br}No portion of this publication may be reproduced, distributed, stored in a retrieval system, or transmitted in any form or by any means, including electronic, mechanical, photocopying, recording, or otherwise, without the prior written permission of the publisher."
              values={{
                br,
                researcher: (
                  <Link
                    external
                    underline="none"
                    href="https://www.linkedin.com/in/dario-nardi-a94a1a1/"
                  >
                    <FormattedMessage defaultMessage="Dario Nardi" />
                  </Link>
                ),
              }}
            />
          </AccordionDetails>
          <AccordionDetails>
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              alignItems="center"
              justifyContent="center"
              spacing={3}
            >
              <Avatar
                src={SRC_AVATAR_DARIO_NARDI}
                variant="rounded"
                sx={{
                  width: 80,
                  height: 80,
                  border: `2px solid ${theme.palette.primary.light}`,
                }}
              />
              <Stack alignItems="center">
                <Typography component="div" variant="h4">
                  <FormattedMessage defaultMessage="Dario Nardi" />
                </Typography>
                <Typography component="div" variant="body1">
                  <FormattedMessage defaultMessage="Researcher & Author" />
                </Typography>
              </Stack>
            </Stack>
          </AccordionDetails>
          <AccordionDetails>
            <FormattedMessage
              defaultMessage="👉 You will find more of Dario Nardi's research and publications on {marketplace}. 🙌"
              values={{
                marketplace: (
                  <Link
                    external
                    underline="none"
                    href="https://radiancehouse.sellfy.store/"
                  >
                    <FormattedMessage defaultMessage="Sellfy" />
                  </Link>
                ),
              }}
            />
          </AccordionDetails>
        </Accordion>
      </Box>
    </React.Fragment>
  )
}

IntroductionSection.propTypes = {
  title: PropTypes.string,
  userDetails: userDetailsShape,
}

export default IntroductionSection

import React from 'react'
import PropTypes from 'prop-types'

import Head from './Head'
import Heatmap from './Heatmap'
import Sensors from './Sensors'
import SensorsTips from './SensorsTips'
import Wiring from './Wiring'
import { analysisShape, sensorsShape } from '@/features/eeg-sessions/store/propTypes'

const STROKE_WIDTH = 4

const containerStyle = { userSelect: 'none', display: 'inline-block', position: 'relative', width: '100%', paddingBottom: '100%', verticalAlign: 'middle', overflow: 'hidden' }
const svgStyle = { display: 'inline-block', position: 'absolute', top: 0, left: 0 }

function BrainCanvas ({ analysis, disableScoreDialogs, disableSensorDialogs, display, sensors, style, ...otherProps }) {
  return (
    <div {...otherProps} style={{ ...containerStyle, ...style }}>
      <svg version='1.1' viewBox='0 0 1000 1000' preserveAspectRatio='xMinYMin' style={svgStyle}>
        <Head strokeWidth={0} />
        {(display === 'tips') && <Heatmap disableDialogs={disableScoreDialogs} strokeWidth={STROKE_WIDTH} />}
        {(display === 'heatmap') && <Heatmap analysis={analysis} disableDialogs={disableScoreDialogs} strokeWidth={STROKE_WIDTH} />}
        {(display === 'wiring') && <Wiring analysis={analysis} disableDialogs={disableScoreDialogs} sensors={sensors} strokeWidth={STROKE_WIDTH * 1.5} />}
        <Head fill='none' strokeWidth={STROKE_WIDTH} withLabels />
        <Sensors disableDialogs={disableSensorDialogs} display={display} sensors={sensors} strokeWidth={STROKE_WIDTH} />
        {(display === 'tips') && <SensorsTips sensors={sensors} />}
      </svg>
    </div>
  )
}

BrainCanvas.propTypes = {
  analysis: analysisShape,
  disableScoreDialogs: PropTypes.bool,
  disableSensorDialogs: PropTypes.bool,
  display: PropTypes.oneOf(['tips','heatmap', 'wiring']),
  sensors: sensorsShape,
}

export default BrainCanvas

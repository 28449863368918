import audioKinesthetic from './audioKinesthetic'
import back from './back'
import backLeft from './backLeft'
import backRight from './backRight'
import bookends from './bookends'
import center from './center'
import centerBack from './centerBack'
import centerFront from './centerFront'
import diamond from './diamond'
import diamonds from './diamonds'
import evenField from './evenField'
import focusedHolistic from './focusedHolistic'
import front from './front'
import halo from './halo'
import hiddenStarburst from './hiddenStarburst'
import left from './left'
import openAnalytic from './openAnalytic'
import pillar from './pillar'
import randomField from './randomField'
import right from './right'
import strongStarburst from './strongStarburst'
import superAudio from './superAudio'
import weakStarburst from './weakStarburst'
import zigzag from './zigzag'

export const wiringPatterns = {
  front,
  'center-front': centerFront,
  center,
  'center-back': centerBack,
  back,
  //
  diamond,
  diamonds,
  'hidden-starburst': hiddenStarburst,
  'weak-starburst': weakStarburst,
  'strong-starburst': strongStarburst,
  //
  left,
  'back-left': backLeft,
  'super-audio': superAudio,
  'back-right': backRight,
  right,
  //
  halo,
  'even-field': evenField,
  'random-field': randomField,
  'focused-holistic': focusedHolistic,
  'open-analytic': openAnalytic,
  //
  'audio-kinesthetic': audioKinesthetic,
  zigzag,
  bookends,
  pillar,
}

export default wiringPatterns

import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import Rating from '@mui/material/Rating'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import BrainImagingExtras from './BrainImagingExtras'
import BrainImagingPriceTable from './BrainImagingPriceTable'
import BrainCanvas from '@/features/eeg-sessions/components/BrainCanvas'
import CircuitDialog from '@/features/eeg-sessions/components/CircuitDialog'
import SensorDialog from '@/features/eeg-sessions/components/SensorDialog'
import { SENSOR_IDS } from '@/features/eeg-sessions/store/propTypes/sensors'
import { br } from '@/features/intl/utils/intl'
import BrainImagingReportExample from '@/features/landing/components/BrainImagingReportExample'
import userDetails from '@/features/landing/components/BrainImagingReportExample/userDetails'
import BodyContainer from '@/features/ui/components/BodyContainer'
import FooterHeader from '@/features/ui/components/FooterHeader'
import Header from '@/features/ui/components/Header'
import Heading from '@/features/ui/components/Heading'
import Helmet from '@/features/ui/components/Helmet'
import TextMediaContainer from '@/features/ui/components/TextMediaContainer'
import { getMediaPictureSrc } from '@/utils/mediaStorage'

function Review ({ comment, firstName, rating, title }) {
  return (
    <div>
      <i>{comment}</i>
      <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
        <Rating value={5} readOnly sx={{ mr: 1 }} />
        <FormattedMessage defaultMessage='{firstName} ・ {title}' values={{ firstName, title }} />
      </Box>
    </div>
  )
}

function BrainImagingLandingView () {
  const intl = useIntl()
  const theme = useTheme()

  const [open, setOpen] = React.useState(false)
  const openReportDialog = () => setOpen(true)
  const closeReportDialog = () => setOpen(false)

  const subheader = intl.formatMessage({ defaultMessage: 'What makes you tick? Ready to explore what makes you, you?', description: 'Header subheader' })

  const reviews = (
    <Stack spacing={3}>
      <Review rating={5}
        comment={<FormattedMessage defaultMessage='"I always wondered why I react the way I do. Seeing the way my brain is wired helped me understand different sides of myself, the ones I can trust, and the ones I could train or get help with."' />}
        firstName={<FormattedMessage defaultMessage='Sofia' description='Review firstname' />}
        title={<FormattedMessage defaultMessage='Real Estate Agent' description='Review title' />} />
      <Review rating={5}
        comment={<FormattedMessage defaultMessage='"I was really curious about how years of habits would shape my brain and how it would link to my personality to gain insights about myself. The result is unique!"' />}
        firstName={<FormattedMessage defaultMessage='Peter' description='Review firstname' />}
        title={<FormattedMessage defaultMessage='Data Scientist' description='Review title' />} />
    </Stack>
  )

  return (
    <React.Fragment>
      <Helmet
        title={intl.formatMessage({ defaultMessage: 'Brain imaging sessions', description: 'Meta title' })}
        description={subheader} />
      <Header component='h1' variant='h1' minHeight='100vh'
        title={<FormattedMessage defaultMessage='Brain imaging{br}sessions' description='Header title' values={{ br }}/>}
        subheader={subheader}
        buttonProps={{
          children: <FormattedMessage defaultMessage='Learn about it' description='Button label' />,
          href: '#services',
        }} />
      {/* Header grid */}
      <Box id='services' sx={{ my: 0.5 }}>
        <Grid container spacing={0.5}>
          <Grid item xs={12} md={4}>
            <Header component='span' variant='h3' overlayProps={{ opacity: 0.35 }}
              image={getMediaPictureSrc('/misc/brain-imaging/brain-imaging-session-1024x576.jpg?alt=media&token=649670a8-adb9-4b4a-9d8c-0818410d4662')}
              title={<FormattedMessage defaultMessage='Peek at{br}your brain' description='Header title' values={{ br }} />}
              subheader={<FormattedMessage defaultMessage="Find out what strategies you're adopting with a 30 min brain imaging session with various tasks to use your brain as you would everyday." description='Header subheader' />} />
          </Grid>
          <Grid item xs={12} md={4}>
            <Header component='span' variant='h3'
              image={getMediaPictureSrc('/misc/brain-imaging/brain-imaging-drawing-1024x576.jpg?alt=media&token=0b2e384f-70a9-483b-871e-f5f82a3b9f51')}
              title={<FormattedMessage defaultMessage='Focus on{br}a specific task' description='Header title' values={{ br }} />}
              subheader={<FormattedMessage defaultMessage='Discover which brain regions you use when you focus on a specific task and unlock your potential. When do you get in the flow?' description='Header subheader' />} />
          </Grid>
          <Grid item xs={12} md={4}>
            <Header component='span' variant='h3' overlayProps={{ opacity: 0.35 }}
              image={getMediaPictureSrc('/misc/brain-imaging/turn-on-your-team-brain-1024x576.jpg?alt=media&token=a529c266-353c-4a0d-a81a-4a8742c5b0e0')}
              title={<FormattedMessage defaultMessage="Turn on your{br}team's brain" description='Header title' values={{ br }} />}
              subheader={<FormattedMessage defaultMessage='Imagine peering into your brain to learn how it truly works best. Now imagine confidently building off of a rich brain-based analysis of your team to maximize its potential.' description='Header subheader' />} />
          </Grid>
        </Grid>
      </Box>
      <BodyContainer sx={{ pb: 0 }}>
        <Heading component='h2' variant='h2' gutterBottom
          title={<FormattedMessage defaultMessage='What you get' descriptiong='Heading title' />} />
      </BodyContainer>
      {/* Introduction to brain imaging session */}
      <TextMediaContainer component='h2' sx={{ pt: 0 }}
        subheader={<FormattedMessage defaultMessage='Friendly, safe and confidential' />}
        title={<FormattedMessage defaultMessage='Gain insights to unlock your potential' description='Heading title' />}
        text={<FormattedMessage defaultMessage='Learn about yourself in a new way with a safe technology based on 15 years of hands-on research from {researcher} that links personality, brain waves, skills, creative flow, and emotional responses.{br}{br}Receive a unique profile of strengths and weaknesses including favorite brain regions, top skills, and when you may get in the "flow".{br}{br}Classic "EEG" technology uses passive, harmless sensors in a nylon cap to rapidly and accurately measure your brain waves.{br}{br}We enjoy different competencies. For each of us, brain regions activate with a different degree of stimulus, competence, motivation, and energy level. What turns you on?' values={{
          br,
          researcher: (
            <Link external underline='none' href='https://www.linkedin.com/in/dario-nardi-a94a1a1/'>
              <FormattedMessage defaultMessage='Dario Nardi' />
            </Link>
          ),
        }} />}
        buttonProps={{
          children: <FormattedMessage defaultMessage='Get in touch' description='Button label' />,
          href: '/contact-us',
        }}
        imageProps={{
          alt: intl.formatMessage({ defaultMessage: 'Brain imaging sessions', description: 'Alt text' }),
          src: getMediaPictureSrc('/misc/brain-imaging/brain-imaging-sessions-frames-768x768.png?alt=media&token=dbece66c-0e9d-4a9c-b4d1-bded5cd2a866'),
        }} />
      {/* Report example dialog */}
      <BodyContainer maxWidth='xs'>
        <Button variant='outlined' fullWidth size='large' onClick={openReportDialog} sx={{ py: 5 }}>
          <FormattedMessage defaultMessage="Discover {firstName}'s report" values={{ firstName: userDetails.firstName }} />
        </Button>
      </BodyContainer>
      <Dialog open={open} onClose={closeReportDialog}
        maxWidth='xl' fullScreen={useMediaQuery(theme.breakpoints.down('md'))}>
        <DialogTitle>
          <FormattedMessage defaultMessage="{firstName}'s brain-savvy personality & skills profile" values={{ firstName: userDetails.firstName }}/>
        </DialogTitle>
        <DialogContent sx={{ px: 0 }}>
          <BrainImagingReportExample />
          <FooterHeader />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={closeReportDialog}>
            <FormattedMessage defaultMessage='Close' />
          </Button>
          <Button onClick={closeReportDialog}>
            <FormattedMessage defaultMessage='Sweet' description="Report dialog's button label" />
          </Button>
        </DialogActions>
      </Dialog>
      {/* Introduction to cognitive skill-sets */}
      <TextMediaContainer component='h2' variant='text-right'
        subheader={<FormattedMessage defaultMessage='What makes you you' description='Heading subheader' />}
        title={<FormattedMessage defaultMessage='Dive into your cognitive skill set' description='Heading title' />}
        text={<FormattedMessage defaultMessage="Your brain consists of many small modules. Each module is a neural circuit that helps you do a task.{br}{br}Some tasks are concrete, such as recognizing faces, hearing voice tone, and moving a hand. Other tasks are abstract, such as evaluating ethics, adjusting to others' feedback, and mentally rehearsing a future action.{br}{br}Explore this bird's eye view of the neocortex, that big outer layer of the brain that is quintessentially human and the seat of conscious thought. Which tasks do you enjoy or excel?{br}{br}Now explore with someone you feel safe to share with. What's your common ground? Where do you complement each other?" values={{ br }} />}
        mediaElement={(
          <Stack alignItems='center' spacing={3}>
            <BrainCanvas display='tips' sensors={SENSOR_IDS} />
            <Typography component='div' variant='body1' sx={{ textAlign: 'center' }}>
              <FormattedMessage defaultMessage='💡 Click circles on the map to get more insights. ☝️' />
            </Typography>
          </Stack>
        )}
        buttonProps={{
          children: <FormattedMessage defaultMessage='Dive in' description='Button label' />,
          href: '/contact-us',
        }} />
      <SensorDialog />
      <CircuitDialog />
      {/* Customer reviews */}
      <TextMediaContainer component='h2'
        subheader={<FormattedMessage defaultMessage='They did it, and they talk about it' description='Heading subheader' />}
        title={<FormattedMessage defaultMessage='Meet pioneering inner explorers' description='Heading title' />}
        text={reviews}
        buttonProps={{
          children: <FormattedMessage defaultMessage='Join them' description='Button label' />,
          href: '/contact-us',
        }}
        imageProps={{
          alt: intl.formatMessage({ defaultMessage: 'Pioneering inner explorers', description: 'Alt text' }),
          src: getMediaPictureSrc('/misc/brain-imaging/brain-imaging-4-people-frames-768x768.png?alt=media&token=f4debaf6-5c5d-4919-a591-ee25000d5d57'),
        }} />
      {/* Price table */}
      <BodyContainer>
        <Heading component='h2' variant='h2' gutterBottom
          title={<FormattedMessage defaultMessage='Shopping time' description='Heading title' />} />
        <BrainImagingPriceTable />
        <Heading component='h2' variant='h2' gutterTop gutterBottom
          title={<FormattedMessage defaultMessage='Extras' description='Heading title' />} />
        <BrainImagingExtras />
      </BodyContainer>
    </React.Fragment>
  )
}

export default BrainImagingLandingView

import isEmpty from 'lodash/isEmpty'
import PropTypes from 'prop-types'
import React from 'react'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/styles'

import BodyContainer from '@/features/ui/components/BodyContainer'
import Heading from '@/features/ui/components/Heading'

function TextMediaContainer ({ buttonProps, component, imageProps, mediaElement, subheader, text, title, variant, ...otherProps }) {
  const theme = useTheme()

  if (imageProps) mediaElement = <img alt='' {...imageProps} style={{ maxWidth: '100%' }} />
  if (!mediaElement) mediaElement = <Skeleton variant='rectangle' sx={{ width: '100%', height: '100%' }} />

  return (
    <BodyContainer {...otherProps}>
      <Grid container spacing={5} sx={{
        [theme.breakpoints.up('md')]: {
          flexDirection: variant === 'text-left' ? 'row' : 'row-reverse',
        },
      }}>
        <Grid item xs={12} md={6}>
          <Stack spacing={5} sx={{ alignItems: 'flex-start' }}>
            <Heading component={component} variant='h3'
              subheader={subheader} title={title} />
            {text ? (
              <Typography component='div' variant='body1'>
                {text}
              </Typography>
            ) : (
              <React.Fragment>
                <Skeleton variant='rectangle' width='100%' height={60} />
                <Skeleton variant='rectangle' width='100%' height={60} />
              </React.Fragment>
            )}
            {isEmpty(buttonProps) ? null : (
              <Button color='inherit' variant='outlined' {...buttonProps} />
            )}
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          {mediaElement}
        </Grid>
      </Grid>
    </BodyContainer>
  )
}

TextMediaContainer.propTypes = {
  imageProps: PropTypes.shape({
    alt: PropTypes.string.isRequired,
    src: PropTypes.string.isRequired,
  }),
  mediaElement: PropTypes.element,
  variant: PropTypes.oneOf(['text-left', 'text-right']),
}

TextMediaContainer.defaultProps = {
  subheader: <Skeleton />,
  title: <Skeleton />,
  variant: 'text-left',
}

export default TextMediaContainer

import React from 'react'
import { useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import BrainCanvas from '@/features/eeg-sessions/components/BrainCanvas'
import PlaygroundSettings from '@/features/eeg-sessions/components/PlaygroundSettings'
import { displaySelector, filenameSelector, sessionSelector } from '@/features/eeg-sessions/store/reducers/playgroundSettings'
import { getAnalysis } from '@/features/eeg-sessions/utils/analyses'
import BodyContainer from '@/features/ui/components/BodyContainer'
import Heading from '@/features/ui/components/Heading'

function PlaygroundView () {
  const display = useSelector(displaySelector)
  const session = useSelector(sessionSelector)
  const filename = useSelector(filenameSelector)

  const analysis = getAnalysis(session)
  const { sensors } = session || {}

  return (
    <React.Fragment>
      <BodyContainer>
        <Heading component='h1' variant='h2' gutterBottom
          title={<FormattedMessage defaultMessage='Playground' />} />
        <PlaygroundSettings sx={{ my: 10}} />
      </BodyContainer>
      <BodyContainer maxWidth='sm' disableGutters>
        <BrainCanvas analysis={analysis} display={display} sensors={sensors} />
      </BodyContainer>
      <BodyContainer sx={{ textAlign: 'center' }}>
        <Stack direction='column' spacing={5}>
          {/* Filename */}
          {!filename ? null : (
            <Typography component='div' variant='h4' gutterBottom>
              {filename}
            </Typography>
          )}
          {/* Brain imaging session duration */}
          {!(session && session.duration) ? null : (
            <React.Fragment>
              <FormattedMessage defaultMessage='Brain imaging session duration: {minutes} minutes {seconds, plural, =0 {} one {# second} other {# seconds}}.' values={{
                  minutes: Math.floor(session.duration / 60),
                  seconds: session.duration % 60,
                }} />
              <br/>
            </React.Fragment>
          )}
          {/* Top circuit score */}
          {!(session && session.duration && analysis && analysis.circuits && analysis.topCircuitHits) ? null : (
            <FormattedMessage defaultMessage='({circuit}) has a top score with {hits} hits, every {speed} seconds on average.' values={{
              circuit: analysis.circuits[0].nodes.split(',').join(', '),
              hits: analysis.topCircuitHits,
              speed: (session.duration / analysis.topCircuitHits).toFixed(2),
            }} />
          )}
        </Stack>
      </BodyContainer>
    </React.Fragment>
  )
}

export default PlaygroundView

import React from 'react'
import { default as MUIPagination } from '@mui/material/Pagination'

import { usePaginationParams } from '@/features/ui/components/Pagination'

export const DEFAULT_PAGE = 1

function Pagination ({ count, ...otherProps }) {
  const [paginationParams, setPaginationParams] = usePaginationParams();
  const { page = DEFAULT_PAGE } = paginationParams
  
  // Reset to first page when page displayed is higher than total pages
  React.useEffect(() => {
    if ((page && page >= 1) && (!count || count < page)) {
      setPaginationParams({ page: DEFAULT_PAGE })
    }
  }, [count, page]) // eslint-disable-line react-hooks/exhaustive-deps

  const handlePageChange = (e, value) => {
    setPaginationParams({ page: value })
  }

  return (
    <MUIPagination count={count} page={page} onChange={handlePageChange} {...otherProps} />
  )
}

export default Pagination
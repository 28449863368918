import React from 'react'
import { useNavigate } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import LoadingButton from '@mui/lab/LoadingButton'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/styles'

import { useSignupMutation } from '@/features/auth/services/api'
import CountrySelect from '@/features/forms/components/CountrySelect'
import BodyContainer from '@/features/ui/components/BodyContainer'
import Helmet from '@/features/ui/components/Helmet'

function SignupView ({ history }) {
  const intl = useIntl()
  const theme = useTheme()
  const navigate = useNavigate()

  const [firstName, setFirstName] = React.useState('')
  const [lastName, setLastName] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [phoneNumber, setPhoneNumber] = React.useState('')
  const [country, setCountry] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [errors, setErrors] = React.useState({})
  const [signup, { isLoading }] = useSignupMutation()

  const handleChange = (set) => (e) => { set(e.target.value) }
  const handleCountryChange = (e, option) => { setCountry(option ? option.code : '') }

  const handleSubmit = (e) => {
    e.preventDefault()

    return signup({ email, password, firstName, lastName, phoneNumber, country })
      .unwrap()
      .then(() => navigate('/account/brain-imaging/reports'))
      .catch(({ status, data }) => {
        const { code } = data || {}

        const errors = {}
        if (code === 'auth/email-already-in-use') errors.email = <FormattedMessage defaultMessage='Email already in use' />
        else if (code === 'auth/invalid-email') errors.email = <FormattedMessage defaultMessage='Invalid email' />
        else if (code === 'auth/weak-password') errors.password = <FormattedMessage defaultMessage='Weak password' />
        else if (code === 'auth/operation-not-allowed') errors.general = <FormattedMessage defaultMessage='Operation not allowed' />
        else errors.general = <FormattedMessage defaultMessage='Internal error' />

        setErrors(errors)
      })
  }

  const title = intl.formatMessage({ defaultMessage: 'Sign Up', description: 'Title for sign up forms' })

  return (
    <React.Fragment>
      <Helmet
        title={title} />
      <BodyContainer maxWidth='xs'
        sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Avatar sx={{ m: 1, bgcolor: theme.palette.secondary.main }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component='h1' variant='h5'>
          {title}
        </Typography>
        <form noValidate>
          <TextField id='email' name='email' autoComplete='email' required
            fullWidth autoFocus variant='outlined' margin='normal'
            helperText={errors.email} error={!!errors.email} onChange={handleChange(setEmail)}
            label={<FormattedMessage defaultMessage='Email address' />} />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField id='firstName' name='firstName' autoComplete='firstName' required
                fullWidth variant='outlined' margin='normal'
                helperText={errors.firstName} error={!!errors.firstName} onChange={handleChange(setFirstName)}
                label={<FormattedMessage defaultMessage='First name' />} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField id='lastName' name='lastName' autoComplete='lastName' required
                fullWidth variant='outlined' margin='normal'
                helperText={errors.lastName} error={!!errors.lastName} onChange={handleChange(setLastName)}
                label={<FormattedMessage defaultMessage='Last name' />} />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField id='phoneNumber' name='phoneNumber' autoComplete='phoneNumber'
                fullWidth variant='outlined' margin='normal'
                helperText={errors.phoneNumber} error={!!errors.phoneNumber} onChange={handleChange(setPhoneNumber)}
                label={<FormattedMessage defaultMessage='Phone number' />} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CountrySelect id='country' name='country'
                fullWidth variant='outlined' margin='normal'
                helperText={errors.country} error={!!errors.country} onChange={handleCountryChange}
                label={<FormattedMessage defaultMessage='Country of residence' />} />
            </Grid>
          </Grid>
          <TextField id='password' name='password' type='password' autoComplete='current-password' required
            fullWidth variant='outlined' margin='normal'
            helperText={errors.password} error={!!errors.password} onChange={handleChange(setPassword)}
            label={<FormattedMessage defaultMessage='Password' />} />
          <LoadingButton type='submit' fullWidth variant='contained' color='primary' sx={{ mt: 3, mb: 2 }}
            onClick={handleSubmit} disabled={!(email && password && firstName && lastName)} loading={isLoading}>
            <FormattedMessage defaultMessage='Sign up' />
          </LoadingButton>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant='body2'>
              <FormattedMessage defaultMessage='Already have an account?' />
            </Typography>
            <Link href='/login' variant='body2'>
              <FormattedMessage defaultMessage='Sign in' />
            </Link>
          </Box>
          {errors.general && (
            <Typography variant='body2' sx={{ color: 'red', fontSize: '0.8rem', mt: 1 }}>
              {errors.general}
            </Typography>
          )}
        </form>
      </BodyContainer>
    </React.Fragment>
  )
}

export default SignupView

import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import Stack from '@mui/material/Stack'

import ContactUsForm from '@/features/landing/components/ContactUsForm'
import BodyContainer from '@/features/ui/components/BodyContainer'
import Header from '@/features/ui/components/Header'
import Heading from '@/features/ui/components/Heading'
import Helmet from '@/features/ui/components/Helmet'

function ContactUsView () {
  const intl = useIntl()

  const title = intl.formatMessage({ defaultMessage: 'Contact us', description: 'Header title' })
  
  return (
    <React.Fragment>
      <Helmet
        title={title} />
      <Header component='h1' variant='h1'
        title={title}
        subheader={<FormattedMessage defaultMessage='Tell us how we can help you.' description='Header subheader' />}
        buttonProps={{
          children: <FormattedMessage defaultMessage='Contact us' description='Header button label' />,
          href:'#contact',
        }} />
      <BodyContainer id='contact' maxWidth='md'>
        <Stack spacing={5}>
          <Heading component='h2' variant='h2'
            title={<FormattedMessage defaultMessage='Contact' description='Heading title' />} />
          <ContactUsForm />
        </Stack>
      </BodyContainer>
    </React.Fragment>

  )
}

export default ContactUsView

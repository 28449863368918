import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  mode: localStorage.getItem('ThemeMode') || 'dark',
}

export const themeModeSlice = createSlice({
  name: 'themeMode',
  initialState,
  reducers: {
    setDarkMode: (state, action) => {
      const mode = action.payload ? 'dark' : 'light'

      // TODO: Move this out of the reducer
      // Move it to a thunk or some other solution for side-effect
      localStorage.setItem('ThemeMode', mode)

      return {
        ...state,
        mode: action.payload ? 'dark' : 'light',
      }
    },
  },
})

export const { setDarkMode } = themeModeSlice.actions

export const modeSelector = ((state) => state.ui.themeMode.mode)

export default themeModeSlice.reducer

import PropTypes from 'prop-types'
import React from 'react'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'

function PriceTable ({ children, container, item, highlight, ...otherProps }) {
  if (item) {
    return (
      <Grid item {...otherProps}>
        <Paper sx={{ height: '100%', px: 3, py: 3 }} elevation={highlight ? 6 : 1}>
          <Stack spacing={4} alignItems='center' sx={{ display: 'flex', height: '100%' }}>
            {children}
          </Stack>
        </Paper>
      </Grid>
    )
  }

  if (container) {
    return (
      <Grid container alignItems='stretch' {...otherProps}>
        {children}
      </Grid>
    )
  }

  return null
}

PriceTable.propTypes = {
  container: PropTypes.bool,
  highlight: PropTypes.bool,
  item: PropTypes.bool,
}

export default PriceTable

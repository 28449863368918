import React from 'react'
import { FormattedMessage } from 'react-intl'
import Stack from '@mui/material/Stack'

import SensorDescription from '@/features/eeg-sessions/components/SensorDescription'
import { analysisShape } from '@/features/eeg-sessions/store/propTypes'
import { EEG_ANALYSIS_TOP_SKILLS_COUNT } from '@/features/eeg-sessions/store/propTypes/analyses'
import { getTopSkills } from '@/features/eeg-sessions/utils/analyses'
import { b, br } from '@/features/intl/utils/intl'
import Heading from '@/features/ui/components/Heading'
import { pagebreak } from '@/utils/print'

function TopSkillsSection ({ analysis }) {
  const topSkills = getTopSkills({ analysis, top: EEG_ANALYSIS_TOP_SKILLS_COUNT })
  if (!topSkills.length) return null

  return (
    <React.Fragment>
      <Heading id='topSkills' component='h2' variant='h2' gutterTop gutterBottom style={pagebreak}
        title={<FormattedMessage defaultMessage='Your {top} best developed skills' values={{ top: topSkills.length }} />} />
      <FormattedMessage defaultMessage='<b>Which parts of your brain are most developed from years of habits?</b> Based on your lab session, those are the six brain regions below. They appear in order of use and show development from longterm habit. They use them well or have gotten into the habit of using them. Consider how you used them in the past. If you do not identify with all aspects of a region, you may just do some aspects very well.{br}{br}Note: Only one of your "executive" regions (Fp1, Fpz, or Fp2) will be shown because what matters is which one is most densely wired, even with a small difference. Similarly only one of regions (O1, O2) and (PO7, PO8) will be shown.' values={{ b, br }} />
      <Stack spacing={3} sx={{ mt: 3 }}>
        {topSkills.map((id, index) => (
          <SensorDescription key={id} id={id} inTheWorkplace rank={index + 1} withTitle />
        ))}
      </Stack>
    </React.Fragment>
  )
}

TopSkillsSection.propTypes = {
  analysis: analysisShape,
}

export default TopSkillsSection

export const openAnalytic = {
  circuits: [
    { nodes: 'Fp1,Fpz', score: 100 },
    { nodes: 'Fp2,Fpz', score: 96 },
    { nodes: 'T5,O1', score: 93 },
    { nodes: 'F8,Fp2', score: 83 },
    { nodes: 'F4,F8', score: 77 },
    { nodes: 'T5,P3', score: 72 },
    { nodes: 'Fp1,Fp2', score: 55 },
    { nodes: 'O1,O2', score: 55 },
    { nodes: 'F8,FTX', score: 52 },
    { nodes: 'P3,O1', score: 47 },
    { nodes: 'P3,Pz', score: 47 },
    { nodes: 'P4,T6', score: 45 },
    { nodes: 'T5,PO7', score: 41 },
    { nodes: 'PO7,O1', score: 36 },
    { nodes: 'O2,PO8', score: 33 },
    { nodes: 'PO8,T6', score: 33 },
    { nodes: 'P3,FC6', score: 32 },
    { nodes: 'FC6,T4', score: 31 },
    { nodes: 'T4,F8', score: 31 },
    { nodes: 'T4,FTX', score: 29 },
    { nodes: 'F4,Fp2', score: 28 },
    { nodes: 'T3,Fp2', score: 28 },
    { nodes: 'Fp1,F3', score: 27 },
    { nodes: 'F7,Fp1', score: 27 },
    { nodes: 'F7,FT9', score: 26 },
    { nodes: 'T3,CP5', score: 26 },
    { nodes: 'T3,T5', score: 25 },
    { nodes: 'F7,T3', score: 25 },
    { nodes: 'PO7,P3', score: 24 },
    { nodes: 'F4,FC6', score: 23 },
    { nodes: 'T5,O2', score: 23 },
    { nodes: 'T3,O1', score: 23 },
    { nodes: 'T3,FT9', score: 23 },
    { nodes: 'P3,O2', score: 22 },
    { nodes: 'F7,P3', score: 22 },
    { nodes: 'Pz,P4', score: 20 },
    { nodes: 'O1,T6', score: 20 },
    { nodes: 'T6,T4', score: 20 },
    { nodes: 'T4,Fp2', score: 20 },
    { nodes: 'T4,CP6', score: 20 },
    { nodes: 'C4,FC6', score: 20 },
    { nodes: 'FC6,F8', score: 20 },
    { nodes: 'F7,F8', score: 19 },
    { nodes: 'F7,F3', score: 19 },
    { nodes: 'F7,FC5', score: 19 },
    { nodes: 'FC5,C3', score: 19 },
    { nodes: 'F3,F4', score: 19 },
    { nodes: 'Fp1,F8', score: 19 },
    { nodes: 'Fp2,FTX', score: 18 },
    { nodes: 'Fp2,F7', score: 18 },
  ],
}

export default openAnalytic

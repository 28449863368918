import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import LoadingButton from '@mui/lab/LoadingButton'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

function ConfirmationDialog ({ content, loading, onClose, onConfirmation, open, title }) {
  const handleConfirmationClick = async (e) => {
    if (!onConfirmation && onClose) return onClose(e)

    if (onConfirmation) {
      // Do not close dialog if there was an error blocking
      // NOTE: This assumes onConfirmation to return an object with an error field
      const payload = await onConfirmation(e)
      if (payload?.error) return

      if (onClose) onClose(e)
    }
  }

  return (
    <Dialog maxWidth='xs' open={open}>
      {!title ? null : (
        <DialogTitle>
          {title}
        </DialogTitle>
      )}
      {!content ? null : (
        <DialogContent>
          <DialogContentText>
            {content}
          </DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Button autoFocus onClick={onClose}>
          <FormattedMessage defaultMessage='Cancel' description='Dialog action' />
        </Button>
        <LoadingButton onClick={handleConfirmationClick} loading={loading}>
          <FormattedMessage defaultMessage='Ok' description='Dialog action' />
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

ConfirmationDialog.propTypes = {
  content: PropTypes.element,
  loading: PropTypes.bool,
  onConfirmation: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.element,
}

export default ConfirmationDialog

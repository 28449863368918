import React from 'react'
import { FormattedMessage } from 'react-intl'

import Header from '@/features/ui/components/Header'
import NavigationBar from '@/features/ui/components/NavigationBar'

function NotFoundView () {
  return (
    <React.Fragment>
      <NavigationBar />
      <Header component='h1' variant='h1' minHeight='100vh'
        title={<FormattedMessage defaultMessage='Whoops' description='Header title' />}
        subheader={<FormattedMessage defaultMessage="404. Sorry the page you requested could not be found. That's all we know." description='Header subheader' />}
        buttonProps={{
          children: <FormattedMessage defaultMessage='Go home' description='Button label' />,
          href: '/',
        }} />
    </React.Fragment>
  )
}

export default NotFoundView

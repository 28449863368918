import PropTypes from 'prop-types'

export const WIRING_PATTERN_IDS = [
  'audio-kinesthetic',
  'back',
  'back-left',
  'back-right',
  'bookends',
  'center',
  'center-back',
  'center-front',
  'diamond',
  'diamonds',
  'even-field',
  'focused-holistic',
  'front',
  'halo',
  'hidden-starburst',
  'left',
  'open-analytic',
  'random-field',
  'right',
  'strong-starburst',
  'super-audio',
  'weak-starburst',
  'zigzag',
  'pillar',
]

export const STARBURST_PATTERN_IDS = [
  'hidden-starburst',
  'strong-starburst',
  'weak-starburst',
]

export const wiringPatternShape = PropTypes.oneOf(WIRING_PATTERN_IDS)

import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'

import BrainCanvas from '@/features/eeg-sessions/components/BrainCanvas'
import wiringPatterns from '@/features/eeg-sessions/datasets/wiring-patterns'
import { messages as intlWiringPatterns } from '@/features/eeg-sessions/messages/wiringPatterns.msg'
import { SENSOR_IDS } from '@/features/eeg-sessions/store/propTypes/sensors'

export function WiringPatternDescription ({ id, direction, ranking, withTitle }) {
  // NOTE: Starbursts should call a weakStarburst or strongStarburst id
  if (!id) return null

  return (
    <Box>
      {withTitle && (
        <h3 style={{ marginTop: 0 }}>
          <Stack direction='row' justifyContent='space-between' alignItems='center'>
            <FormattedMessage defaultMessage='{bias} Bias' description='Bias variable is replaced to display Left Bias, Right Bias, etc.' values={{
              bias: <FormattedMessage {...intlWiringPatterns.titles[id]} />
            }} />
            {ranking && (
              <span>#{ranking}</span>
            )}
          </Stack>
        </h3>
      )}
      <Stack direction={direction || { xs: 'column', sm: 'row' }} alignItems='center'>
        <Box sx={{ flexShrink: 0, width: 200, mx: 2 }}>
          {/* NOTE: Pass all sensors since data is defined for that */}
          <BrainCanvas disableScoreDialogs display='wiring' analysis={wiringPatterns[id]} sensors={SENSOR_IDS} />
        </Box>
        <Box sx={{ mt: 2 }}>
          <FormattedMessage {...intlWiringPatterns.descriptions[id]} />
        </Box>
      </Stack>
    </Box>
  )
}

WiringPatternDescription.propTypes = {
  id: PropTypes.string,
  direction: PropTypes.string,
  ranking: PropTypes.number,
  withTitle: PropTypes.bool,
}

export default WiringPatternDescription

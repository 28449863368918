import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import LoadingButton from '@mui/lab/LoadingButton'
import Alert from '@mui/material/Alert'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import { userIdSelector } from '@/features/auth/store/reducers/tokens'
import CountrySelect, { getCountryOption } from '@/features/forms/components/CountrySelect'
import DatePicker from '@/features/forms/components/DatePicker'
import BodyContainer from '@/features/ui/components/BodyContainer'
import Header from '@/features/ui/components/Header'
import Helmet from '@/features/ui/components/Helmet'
import { useGetUserDetailsQuery, useUpdateUserDetailsMutation, useUpdateUserProfilePictureMutation } from '@/features/users/services/api'
import { convertDateToYYYYMMDDFormat } from '@/utils/dates'

function AccountProfileView () {
  const intl = useIntl()
  const userId = useSelector(userIdSelector)
  const { data: user } = useGetUserDetailsQuery(userId, { skip: !userId })
  const [updateUserDetails, { isLoading: isEditingUserDetails }] = useUpdateUserDetailsMutation()
  const [updateUserProfilePicture, { isLoading: isEditingUserProfilePicture }] = useUpdateUserProfilePictureMutation()

  const [birthdate, setBirthdate] = React.useState(null)
  const [country, setCountry] = React.useState(null)
  const [email, setEmail] = React.useState('')
  const [errors, setErrors] = React.useState({})
  const [firstName, setFirstName] = React.useState('')
  const [lastName, setLastName] = React.useState('')
  const [phoneNumber, setPhoneNumber] = React.useState('')

  // Sync store with form state
  React.useEffect(() => {
    if (!user) return

    setBirthdate(user.birthdate ? new Date(user.birthdate) : null )
    setCountry(getCountryOption({ code: user.country }) || null)
    setEmail(user.email || '')
    setFirstName(user.firstName || '')
    setLastName(user.lastName || '')
    setPhoneNumber(user.phoneNumber || '')
  }, [user])

  const handleChange = (set) => (e) => { set(e.target.value) }
  const handleBirthdateChange = (value) => { setBirthdate(value ? value : null)}
  const handleCountryChange = (e, option) => { setCountry(option || null) }

  const handleImageChange = (e) => {
    const file = e.target.files[0]
    if (!file) return

    const formData = new FormData()
    formData.append('file', file)

    return updateUserProfilePicture({ id: userId, formData })
      .unwrap()
      .catch(({ status, data }) => {
        const errors = {}
        errors.profilePicture = <FormattedMessage defaultMessage='Internal error' />
        setErrors(errors)
      })
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    return updateUserDetails({
      birthdate: convertDateToYYYYMMDDFormat(birthdate),
      country: (country && country.code),
      email,
      firstName,
      id: userId,
      lastName,
      phoneNumber,
    })
      .unwrap()
      .then(() => {
        setErrors({})
      })
      .catch(({ status, data }) => {
        const errors = {}
        // TODO: Handle form validation errors
        errors.userDetails = <FormattedMessage defaultMessage='Internal error' />
        setErrors(errors)
      })
  }

  const title = intl.formatMessage({ defaultMessage: 'Your profile', description: 'Header title' })

  return (
    <React.Fragment>
      <Helmet
        title={title} />
      <Header component='h1' variant='h1' narrow
        title={title} />
      <BodyContainer>
        {/* User's profile picture card */}
        <Card variant='outlined'>
          <CardContent>
            <Typography gutterBottom variant='h4'>
              {user && `${user.firstName} ${user.lastName}`}
            </Typography>
            {errors.profilePicture && (
              <Alert severity='error' sx={{ mt: 3 }}>
                {errors.profilePicture}
              </Alert>
            )}
          </CardContent>
          <CardActions>
            <label htmlFor='profile-picture-button-file'>
              <input id='profile-picture-button-file' style={{ display: 'none' }}
                type='file' accept='image/*' multiple={false}
                onChange={handleImageChange} />
              <LoadingButton type='submit' component='span' size='large'
                startIcon={<CloudUploadIcon />} disabled={isEditingUserProfilePicture} loading={isEditingUserProfilePicture}>
                <FormattedMessage defaultMessage='Upload photo' description='Card action' />
              </LoadingButton>
            </label>
          </CardActions>
        </Card>
        {/* User details card */}
        <Card variant='outlined' sx={{ mt: 3 }}>
          <form autoComplete='off' noValidate>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <TextField name='firstName' variant='outlined' margin='dense' fullWidth
                    value={firstName} onChange={handleChange(setFirstName)}
                    label={<FormattedMessage defaultMessage='First name' />} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField name='lastName' variant='outlined' margin='dense' fullWidth
                    value={lastName} onChange={handleChange(setLastName)}
                    label={<FormattedMessage defaultMessage='Last name' />} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DatePicker name='birthdate' textFieldProps={{ variant: 'outlined', margin: 'dense', fullWidth: true }}
                    value={birthdate} onChange={handleBirthdateChange}
                    label={<FormattedMessage defaultMessage='Birthdate' />} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CountrySelect name='country' variant='outlined' margin='dense' fullWidth
                    value={country} onChange={handleCountryChange}
                    label={<FormattedMessage defaultMessage='Country of residence' />} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  {/* TODO: Handle user email update with authentication */}
                  <TextField disabled
                    name='email' variant='outlined' margin='dense' fullWidth
                    value={email} onChange={handleChange(setEmail)}
                    label={<FormattedMessage defaultMessage='Email address' />} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField name='phoneNumber' variant='outlined' margin='dense' fullWidth
                    value={phoneNumber} onChange={handleChange(setPhoneNumber)}
                    label={<FormattedMessage defaultMessage='Phone number' />} />
                </Grid>
              </Grid>
              {errors.userDetails && (
                <Alert severity='error' sx={{ mt: 3 }}>
                  {errors.userDetails}
                </Alert>
              )}
            </CardContent>
            <CardActions>
              <LoadingButton size='large' type='submit' color='secondary'
                onClick={handleSubmit} disabled={isEditingUserDetails} loading={isEditingUserDetails}>
                <FormattedMessage defaultMessage='Save' description='Card action' />
              </LoadingButton>
            </CardActions>
          </form>
        </Card>
      </BodyContainer>
    </React.Fragment>
  )
}

export default AccountProfileView

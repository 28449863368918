// NOTE: We use a notion of mirror sensors to define half the data
// Mirror notion is used to compute polygons, coordinates
// and guess whether a sensor is left or right brain
// NOTE: Here we defined right brain sensors as mirrors for left brain sensors
export const SENSORS_MIRRORS = {
  'Fp1': 'Fp2',
  'F3': 'F4',
  'F7': 'F8',
  'FC1': 'FC2',
  'FC5': 'FC6',
  'FT9': 'FTX',
  'C3': 'C4',
  'T3': 'T4',
  'CP1': 'CP2',
  'CP5': 'CP6',
  'P3': 'P4',
  'T5': 'T6',
  'O1': 'O2',
  'PO7': 'PO8',
}

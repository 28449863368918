import { createSlice, isAnyOf } from '@reduxjs/toolkit'

import { getUserProps } from '@/features/auth/utils/tokens'
import { authApi } from '@/features/auth/services/api'
import { USER_ROLE_ADMIN, USER_ROLE_PRACTITIONER, USER_ROLE_RESEARCHER } from '@/features/users/store/propTypes/roles'

const accessToken = localStorage.getItem('AccessToken')

const initialState = {
  accessToken,
  refreshToken: localStorage.getItem('RefreshToken'),
  ...getUserProps(accessToken),
}

export const tokensSlice = createSlice({
  name: 'tokens',
  initialState,
  reducers: {
    logout: (state, action) => {
      localStorage.removeItem('AccessToken')
      localStorage.removeItem('RefreshToken')

      return {
        accessToken: undefined,
        refreshToken: undefined,
        ...getUserProps(undefined),
      }
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        authApi.endpoints.login.matchFulfilled,
        authApi.endpoints.refreshSession.matchFulfilled,
        authApi.endpoints.signup.matchFulfilled,
      ),
      (state, { payload: { accessToken, refreshToken } }) => {
        // TODO: Move this out of the reducer
        // Move it to a thunk or some other solution for side-effect
        localStorage.setItem('AccessToken', accessToken)
        localStorage.setItem('RefreshToken', refreshToken)

        return {
          ...state,
          accessToken,
          refreshToken,
          ...getUserProps(accessToken),
        }
      }
    )
  },
})

export const { logout } = tokensSlice.actions

export const accessTokenSelector = (state) => state.auth.tokens.accessToken
export const isLoggedInSelector = (state) => !!accessTokenSelector(state)
export const refreshTokenSelector = (state) => state.auth.tokens.refreshToken
export const userRolesSelector = (state) => state.auth.tokens.roles
export const userRolesArraySelector = (state) => {
  const userRoles = userRolesSelector(state)
  return Object.keys((userRoles)).filter((o) => !!userRoles[o])
}
export const isAdminSelector = (state) => state.auth.tokens.roles[USER_ROLE_ADMIN]
export const isPractitionerSelector = (state) => state.auth.tokens.roles[USER_ROLE_PRACTITIONER]
export const isResearcherSelector = (state) => state.auth.tokens.roles[USER_ROLE_RESEARCHER]
export const userIdSelector = (state) => state.auth.tokens.userId

export default tokensSlice.reducer

import PropTypes from 'prop-types'
import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'

const Wire = styled('div')(({ theme, variant }) => ({
  // TODO: SVG Wires backgroundImage: 'url("data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg' preserveAspectRatio='none' overflow='visible' height='100%' viewBox='0 0 24 24' fill='none' stroke='black' stroke-width='1' stroke-linecap='square' stroke-miterlimit='10'%3E%3Cpath d='M0,21c3.3,0,8.3-0.9,15.7-7.1c6.6-5.4,4.4-9.3,2.4-10.3c-3.4-1.8-7.7,1.3-7.3,8.8C11.2,20,17.1,21,24,21\'/%3E%3C/svg%3E")',
  borderBottom: '1px solid',
  borderColor: theme.palette.primary.main,
  height: 20,
  width: variant === 'h2'
    ? 124
    : (variant === 'h3' ? 68 : 24),
  padding: '16px 0',
  margin: variant === 'h2' ? '0 auto' : undefined,
}))

const Subheader = styled('div')(({ theme, variant }) => ({
  color: theme.palette.text.secondary,
  fontSize: 16,
  fontWeight: 700,
  marginBottom: 5,
  textAlign: variant === 'h2' ? 'center' : undefined,
  textTransform: 'uppercase',
}))

function Heading ({ component, gutterBottom, gutterTop, subheader, title, variant, ...otherProps }) {
  return (
    <Box component='div' {...otherProps} sx={{
        width: '100%',
        mt: !gutterTop ? undefined : (variant === 'h2' ? 10 : 5),
        mb: !gutterBottom ? undefined : (variant === 'h2' ? 10 : 5),
        ...(otherProps.sx || {}),
      }}>
      {!subheader ? null : (
        <Subheader variant={variant}>
          {subheader}
        </Subheader>
      )}
      <Typography component={component} variant={variant} sx={{ lineHeight: 1 }}>
        {title}
      </Typography>
      <Wire variant={variant} />
    </Box>
  )
}

Heading.propTypes = {
  gutterBottom: PropTypes.bool,
  gutterTop: PropTypes.bool,
  subheader: PropTypes.element,
  title: PropTypes.element,
  variant: PropTypes.string.isRequired,
}

export default Heading

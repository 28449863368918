import { combineReducers } from 'redux'

import circuitDialogReducer from './circuitDialog'
import playgroundSettingsReducer from './playgroundSettings'
import sensorDialogReducer from './sensorDialog'
import wiringPatternDialogReducer from './wiringPatternDialog'

export const eegSessionsReducer = combineReducers({
  circuitDialog: circuitDialogReducer,
  playgroundSettings: playgroundSettingsReducer,
  sensorDialog: sensorDialogReducer,
  wiringPatternDialog: wiringPatternDialogReducer,
})

export default eegSessionsReducer

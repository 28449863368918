import { parseNodes } from '@/features/eeg-sessions/utils/analyses'

// NOTE: Starburst is probably set to 7+ (out of 32) to prevent displaying a diamond to would cover the entire map
const STARBURST_FROM = 7
const STARBURST_COMPLETE_RATIO = 0.6 // As a percentage of measured channels
const DIAMOND_FROM = 4

export function isStarburst(circuit = {}) {
  const nodes = !!circuit.nodes ? parseNodes(circuit) : circuit
  return nodes.length >= STARBURST_FROM
}

export function isPartialStarburst(circuit = {}, channelCount) {
  const nodes = !!circuit.nodes ? parseNodes(circuit) : circuit
  return (
    isStarburst(nodes) && nodes.length < STARBURST_COMPLETE_RATIO * channelCount
  )
}

export function isDiamond (circuit = {}) {
  const nodes = !!circuit.nodes ? parseNodes(circuit) : circuit
  return (nodes.length >= DIAMOND_FROM) && !isStarburst(nodes)
}

export function toPoints (arr) {
  return arr.map((p) => p.join(',')).join(' ')
}

export function getStarburstCenter (nodes = [], sensorsCoordinates = {}) {
  // Draw starbursts from Cz, or first element if Cz is not in the starburst
  return nodes.includes('Cz') ? sensorsCoordinates.Cz : sensorsCoordinates[nodes[0]]
}

export function getDiamondCenter (nodes = [], sensorsCoordinates = {}, { existing = [] } = {}) {
  // Draw diamonds from first element that hasn't been used and is not Cz
  // NOTE: Cz is reserved for starbursts
  let from
  let index = 0
  do {
    from = nodes[index++]
  } while (index < nodes.length && (from === 'Cz' || existing.includes(from)))

  // Handle cases where all nodes where already used
  if (!from || index === nodes.length) from = nodes[0]
  if (from === 'Cz') from = nodes[1]

  // Push node to existing ones
  if (!existing.includes(from)) existing = [...existing, from]

  return { from: sensorsCoordinates[from], existing }
}

// Draw lines between all nodes and a given center
export function getStarburstLines (nodes, { circuitId, cursor, from, handleClick, sensorsCoordinates, stroke, strokeWidth }) {
  const lines = []

  for (const id of nodes) {
    const to = sensorsCoordinates[id]
    if (!to) continue
    if ((from.x === to.x) && (from.y === to.y)) continue

    lines.push(
      <line key={`circuit-${circuitId}-${from.x},${from.y}-${to.x},${to.y}`}
        x1={from.x} y1={from.y} x2={to.x} y2={to.y}
        stroke={stroke} strokeWidth={strokeWidth}
        style={cursor} onClick={handleClick} />
    )
  }

  return lines
}

// Draw lines between all nodes of a circuits
export function getDiamondLines (nodes, { circuitId, cursor, handleClick, sensorsCoordinates, stroke, strokeWidth }) {
  const lines = []

  for (let i = 0; i < nodes.length; i++) {
    const from = sensorsCoordinates[nodes[i]]
    if (!from) continue

    const otherNodes = nodes.slice(i + 1)
    for (let j = 0; j < otherNodes.length; j++) {
      const to = sensorsCoordinates[otherNodes[j]]
      if (!to) continue

      lines.push(
        <line key={`circuit-${circuitId}-${from.x},${from.y}-${to.x},${to.y}`}
          x1={from.x} y1={from.y} x2={to.x} y2={to.y}
          stroke={stroke} strokeWidth={strokeWidth}
          style={cursor} onClick={handleClick} />
      )
    }
  }

  return lines
}

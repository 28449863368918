export const back = {
  circuits: [
    { nodes: 'O1,O2', score: 100 },
    { nodes: 'Fp1,Fpz', score: 96 },
    { nodes: 'Fp2,Fpz', score: 93 },
    { nodes: 'PO7,O1', score: 83 },
    { nodes: 'PO8,O2', score: 77 },
    { nodes: 'T5,T6', score: 72 },
    { nodes: 'T5,PO7', score: 55 },
    { nodes: 'T6,PO8', score: 55 },
    { nodes: 'T3,T5', score: 52 },
    { nodes: 'T4,T6', score: 47 },
    { nodes: 'O1,T5', score: 47 },
    { nodes: 'O2,T6', score: 45 },
    { nodes: 'O1,T3', score: 41 },
    { nodes: 'O2,T4', score: 36 },
    { nodes: 'O1,P3', score: 33 },
    { nodes: 'O2,P4', score: 33 },
    { nodes: 'O1,Pz', score: 32 },
    { nodes: 'O2,Pz', score: 31 },
    { nodes: 'PO7,P3', score: 31 },
    { nodes: 'PO8,P4', score: 29 },
    { nodes: 'P3,CP1', score: 28 },
    { nodes: 'P4,CP2', score: 28 },
    { nodes: 'P3,CP5', score: 27 },
    { nodes: 'P4,CP6', score: 27 },
    { nodes: 'CP5,T3', score: 26 },
    { nodes: 'CP6,T4', score: 26 },
    { nodes: 'T5,P3', score: 25 },
    { nodes: 'P4,T6', score: 25 },
    { nodes: 'P3,Pz', score: 24 },
    { nodes: 'P4,Pz', score: 23 },
    { nodes: 'PO7,PO8', score: 23 },
    { nodes: 'C3,P3', score: 23 },
    { nodes: 'C4,P4', score: 23 },
    { nodes: 'C3,CP5', score: 22 },
    { nodes: 'C4,CP6', score: 22 },
    { nodes: 'C4,FC6', score: 20 },
    { nodes: 'C3,FC5', score: 20 },
    { nodes: 'FC5,F3', score: 20 },
    { nodes: 'FC6,F4', score: 20 },
    { nodes: 'F4,F8', score: 20 },
    { nodes: 'F3,F7', score: 20 },
    { nodes: 'F7,FT9', score: 20 },
    { nodes: 'F8,FTX', score: 19 },
    { nodes: 'F7,T3', score: 19 },
    { nodes: 'F8,T4', score: 19 },
    { nodes: 'FTX,T4', score: 19 },
    { nodes: 'FT9,T3', score: 19 },
    { nodes: 'Fp1,F7', score: 19 },
    { nodes: 'Fp2,F8', score: 18 },
  ],
}

export default back

import PropTypes from 'prop-types'
import React from 'react'
import AdjustIcon from '@mui/icons-material/Adjust'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

function FooterItem ({ href, icon, message }) {
  return (
    <Stack direction='row' spacing={2}>
      {React.cloneElement(icon, { color: 'primary' })}
      <Typography component='div' variant='body1'>
        {href ? (
          <Link href={href} color='inherit' underline='hover' variant='h6'>
            {message}
          </Link>
        ) : (
          <React.Fragment>
            {message}
          </React.Fragment>
        )}
      </Typography>
    </Stack>
  )
}

FooterItem.propTypes = {
  icon: PropTypes.element.isRequired,
  message: PropTypes.element.isRequired,
}

FooterItem.defaultProps = {
  icon: <AdjustIcon />,
}

export default FooterItem

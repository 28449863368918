import * as edfjs from 'edfjs';
import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import LoadingButton from '@mui/lab/LoadingButton'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Grid from '@mui/material/Grid'
import InputAdornment from '@mui/material/InputAdornment'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'

import ActivitiesFormSection from './ActivitiesFormSection'
import AnalysisFormSection from './AnalysisFormSection'
import ChartsFormSection from './ChartsFormSection'
import CircuitDialog from '@/features/eeg-sessions/components/CircuitDialog'
// import SensorsAutocomplete from '@/features/eeg-sessions/components/SensorsAutocomplete'
import SensorDialog from '@/features/eeg-sessions/components/SensorDialog'
import {
  useGetEEGSessionQuery,
  useEditEEGSessionMutation,
} from '@/features/eeg-sessions/services/api'
import { EEG_ANALYSIS_VARIANT_MIXED } from '@/features/eeg-sessions/store/propTypes/analyses'
import { buildSessionFromEdf } from '@/features/eeg-sessions/utils/sessions'
import DateTimePicker from '@/features/forms/components/DateTimePicker'
import FileUploadTextField from '@/features/forms/components/FileUploadTextField'
import ActionAppBar from '@/features/ui/components/ActionAppBar'
import BodyContainer from '@/features/ui/components/BodyContainer'
import Header from '@/features/ui/components/Header'
import Heading from '@/features/ui/components/Heading'
import Helmet from '@/features/ui/components/Helmet'
import { activeRoleSelector } from '@/features/ui/store/reducers/userRole'
import UserAutocomplete from '@/features/users/components/UserAutocomplete'
import { useGetUserDetailsQuery } from '@/features/users/services/api'
import { USER_ROLE_ADMIN } from '@/features/users/store/propTypes/roles'

const textFieldProps = {
  variant: 'outlined',
  margin: 'dense',
  fullWidth: true,
}

const handleChange = (set) => (e) => {
  set(e.target.value)
}
const hasAnalysisCircuits = (o) => !!o.circuits?.length

// TODO: Use React-Final-Form? ActivitiesFormSection slowly reacts to inputs
function EditReportView() {
  const { sessionId } = useParams()
  const { data: session } = useGetEEGSessionQuery(sessionId, {
    skip: !sessionId,
  })
  const activeRole = useSelector(activeRoleSelector)

  const { userId: subjectId, practitionerId } = session || {}
  const { data: practitionerDetails } = useGetUserDetailsQuery(practitionerId, {
    skip: !practitionerId,
  })
  const { data: subjectDetails } = useGetUserDetailsQuery(subjectId, {
    skip: !subjectId,
  })
  const [editEEGSession, { isLoading: isEditingEEGSession }] =
    useEditEEGSessionMutation()

  const [activities, setActivities] = React.useState([])
  const [amplitudesAndFreq, setAmplitudesAndFreq] = React.useState(null)
  const [analyses, setAnalyses] = React.useState([])
  const [doneAt, setDoneAt] = React.useState(null)
  const [duration, setDuration] = React.useState('')
  const [errors, setErrors] = React.useState({})
  const [edfFileName, setEdfFileName] = React.useState('')
  const [practitioner, setPractitioner] = React.useState(null)
  const [samplingRate, setSamplingRate] = React.useState('')
  const [sensors, setSensors] = React.useState([])
  const [title, setTitle] = React.useState('')
  const [subject, setSubject] = React.useState(null)

  // Fetch practitioner details
  React.useEffect(() => {
    if (practitionerDetails) setPractitioner(practitionerDetails)
  }, [practitionerDetails])

  // Fetch subject details
  React.useEffect(() => {
    if (subjectDetails) setSubject(subjectDetails)
  }, [subjectDetails])

  // Initialize form values
  React.useEffect(() => {
    if (!session) return

    setActivities(cloneDeep(session.activities || []))
    setAmplitudesAndFreq({
      amplitudes: cloneDeep(session.amplitudes),
      frequencies: cloneDeep(session.frequencies),
    })
    setAnalyses(cloneDeep(session.analyses || []))
    setDoneAt(new Date(session.doneAt) || null)
    setDuration(session.duration || '')
    setSamplingRate(session.samplingRate || '')
    setSensors(session.sensors || [])
    setTitle(session.title || '')
  }, [session])

  const handleAddAnalysis = React.useCallback(
    (e) => {
      setAnalyses([...analyses, { variant: EEG_ANALYSIS_VARIANT_MIXED }])
    },
    [analyses]
  )

  // Inject an empty analysis if there is none
  React.useEffect(() => {
    if (!analyses?.length) handleAddAnalysis()
  }, [analyses, handleAddAnalysis])

  const handleSave = () => {
    if (!session) return

    return editEEGSession({
      activities: activities?.filter((o) => !isEmpty(o)),
      amplitudes: amplitudesAndFreq?.amplitudes,
      analyses: analyses?.filter(hasAnalysisCircuits), // NOTE: Do not filter on empty analysis since we inject analysis variant by default
      doneAt,
      duration: duration ? Number(duration) : undefined,
      frequencies: amplitudesAndFreq?.frequencies,
      id: session.id,
      practitionerId: practitioner ? practitioner.id : undefined,
      samplingRate: samplingRate ? Number(samplingRate) : undefined,
      sensors,
      title,
      userId: subject ? subject.id : undefined,
    })
      .unwrap()
      .then(() => {
        // Reset errors
        setErrors({})
      })
      .catch(({ status, data }) => {
        const errors = {}
        // TODO: Handle form validation errors
        errors.general = <FormattedMessage defaultMessage="Internal error" />
        setErrors(errors)
      })
  }

  const handleAnalysisDelete = (i) => () => {
    const newAnalyses = [...analyses]
    newAnalyses.splice(i, 1)
    setAnalyses(newAnalyses)
  }

  const handleAnalysisChange = (i) => (patch) => {
    const newAnalyses = [...analyses]
    newAnalyses[i] = { ...newAnalyses[i], ...patch }
    return setAnalyses(newAnalyses)
  }

  const shouldDisplayAutoFilledInput =
    !!analyses?.filter(hasAnalysisCircuits).length

  // TODO: Display BottomAppBar only if form data has changed
  const isTouched = true

  const onFileChange = async (event) => {
    const file = event.target.files && event.target.files[0];
    if (!file) return
    
    setEdfFileName(file.name)
    
    // Process EDF file
    const edf = await new edfjs.EDF().from_file(file)
    if (!edf) return

    // Build session
    const session = buildSessionFromEdf(edf)
    if (!session) return
   
    // Update form
    setAnalyses(session.analyses)
    setDoneAt(session.doneAt)
    setDuration(session.duration)
    setSamplingRate(session.samplingRate)
    setSensors(session.sensors)
    setTitle(session.title)
    setAmplitudesAndFreq({ amplitudes: session.amplitudes, frequencies: session.frequencies })
  }

  return (
    <React.Fragment>
      <Helmet
        title={
          subjectDetails &&
          `${subjectDetails.firstName} ${subjectDetails.lastName}`
        }
      />
      <Header
        component="h1"
        variant="h1"
        narrow
        title={
          <FormattedMessage
            defaultMessage="Edit {firstName}'s session"
            description="Header title"
            values={{ firstName: subject && subject.firstName }}
          />
        }
      />
      <BodyContainer>
        <Box sx={{ display: 'flex', justifyContent: 'end', mb: 3 }}>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            href={`/account/brain-imaging/reports/${session?.id}`}
            target="_blank"
          >
            <FormattedMessage defaultMessage="See report" />
          </Button>
        </Box>
        <form noValidate>
          <Stack spacing={3}>
            {/* Meta data section */}
            <Card variant="outlined">
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <FileUploadTextField
                      id="edf-filename"
                      name="edf-filename"
                      {...textFieldProps}
                      value={edfFileName}
                      onChange={onFileChange}
                      accept=".edf"
                      label={<FormattedMessage defaultMessage="EDF file" />}
                      helperText={
                        <FormattedMessage
                          defaultMessage="Experimental"
                          description="TextField helperText"
                        />
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      {...textFieldProps}
                      value={title}
                      onChange={handleChange(setTitle)}
                      label={<FormattedMessage defaultMessage="Title" />}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <DateTimePicker
                      textFieldProps={textFieldProps}
                      value={doneAt}
                      onChange={(value) => {
                        setDoneAt(value ? value : null)
                      }}
                      label={<FormattedMessage defaultMessage="Session date" />}
                    />
                  </Grid>
                  {shouldDisplayAutoFilledInput || duration ? (
                    <>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          {...textFieldProps}
                          value={duration}
                          onChange={(e) => {
                            setDuration(
                              e.target.value ? Number(e.target.value) : ''
                            )
                          }}
                          InputProps={{
                            readOnly: true,
                            endAdornment: (
                              <InputAdornment position="end">
                                <FormattedMessage defaultMessage="seconds" />
                              </InputAdornment>
                            ),
                          }}
                          label={<FormattedMessage defaultMessage="Duration" />}
                          helperText={
                            <FormattedMessage defaultMessage="Uploading an analysis fills in the duration." />
                          }
                        />
                      </Grid>
                      <Grid item xs={0} sm={6} />
                    </>
                  ) : null}
                  <Grid item xs={12} sm={6}>
                    <UserAutocomplete
                      disabled={activeRole !== USER_ROLE_ADMIN}
                      value={practitioner}
                      onChange={(e, option) => {
                        setPractitioner(option || null)
                      }}
                      textFieldProps={{
                        ...textFieldProps,
                        label: (
                          <FormattedMessage defaultMessage="Practitioner" />
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <UserAutocomplete
                      value={subject}
                      onChange={(e, option) => {
                        setSubject(option || null)
                      }}
                      textFieldProps={{
                        ...textFieldProps,
                        label: <FormattedMessage defaultMessage="Subject" />,
                      }}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            {/* Headset data section */}
            <Card variant="outlined">
              <CardHeader
                title={
                  <FormattedMessage
                    defaultMessage="Headset"
                    description="Card header title"
                  />
                }
              />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      {...textFieldProps}
                      value={samplingRate}
                      onChange={(e) => {
                        setSamplingRate(
                          e.target.value ? Number(e.target.value) : ''
                        )
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <FormattedMessage defaultMessage="Hz" />
                          </InputAdornment>
                        ),
                      }}
                      label={
                        <FormattedMessage defaultMessage="Sampling rate" />
                      }
                    />
                  </Grid>
                  {/* {shouldDisplayAutoFilledInput && (
                    <Grid item xs={12} sm={8}>
                      <SensorsAutocomplete
                        key={3}
                        limitTags={1}
                        value={sensors}
                        onChange={(e, value) => setSensors(value)}
                        textFieldProps={{
                          ...textFieldProps,
                          InputProps: { readOnly: true },
                          label: <FormattedMessage defaultMessage="Sensors" />,
                          helperText: (
                            <FormattedMessage defaultMessage="Uploading an analysis fills in sensors" />
                          ),
                        }}
                      />
                    </Grid>
                  )} */}
                </Grid>
              </CardContent>
            </Card>
            <Heading
              component="h2"
              variant="h3"
              gutterTop
              title={
                <FormattedMessage
                  defaultMessage="Activities"
                  description="Heading title"
                />
              }
            />
            <ActivitiesFormSection
              sessionId={session?.id}
              textFieldProps={textFieldProps}
              activities={activities}
              setActivities={setActivities}
            />
            {/* Analyses section */}
            <Heading
              component="h2"
              variant="h3"
              gutterTop
              title={
                <FormattedMessage
                  defaultMessage="Analyses"
                  description="Heading title"
                />
              }
            />
            {analyses.map((analysis, i) => (
              <AnalysisFormSection
                key={i}
                variant="outlined"
                textFieldProps={textFieldProps}
                analysis={analysis}
                duration={session?.duration}
                sensors={sensors}
                onChange={handleAnalysisChange(i)}
                onDelete={handleAnalysisDelete(i)}
                onDurationChange={setDuration}
                onSensorsChange={setSensors}
              />
            ))}
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button variant="outlined" onClick={handleAddAnalysis}>
                <FormattedMessage defaultMessage="Add analysis" />
              </Button>
            </Box>
            {/* Charts section */}
            <Heading
              component="h2"
              variant="h3"
              gutterTop
              title={
                <FormattedMessage
                  defaultMessage="Charts"
                  description="Heading title"
                />
              }
            />
            <ChartsFormSection
              duration={Number(duration)}
              amplitudesAndFreq={amplitudesAndFreq}
              setAmplitudesAndFreq={setAmplitudesAndFreq}
            />
          </Stack>
          {/* ActionAppBar */}
          {isTouched && (
            <ActionAppBar>
              <Stack
                direction="row"
                spacing={2}
                justifyContent="flex-end"
                alignItems="center"
              >
                {errors.general && (
                  <Alert severity="error" sx={{ flexGrow: 1 }}>
                    {errors.general}
                  </Alert>
                )}
                <LoadingButton
                  type="submit"
                  color="secondary"
                  variant="contained"
                  sx={{ flexShrink: 0 }}
                  onClick={handleSave}
                  disabled={!session}
                  loading={isEditingEEGSession}
                >
                  <FormattedMessage defaultMessage="Save" />
                </LoadingButton>
              </Stack>
            </ActionAppBar>
          )}
        </form>
      </BodyContainer>
      <SensorDialog />
      <CircuitDialog />
    </React.Fragment>
  )
}

export default EditReportView

/**
  * Convert a date into its yyyy-mm-dd format, e.g. for storing in database
  *
  * @param {Date} date
  * @return {String}
  */
export function convertDateToYYYYMMDDFormat (date) {
  if (!date) return

  const offset = date.getTimezoneOffset()

  return (new Date(date.getTime() - (offset * 60 * 1000)))
    .toISOString().split('T')[0]
}

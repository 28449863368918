import isEmpty from 'lodash/isEmpty'
import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import without from 'lodash/without'

import FormattedSensor from '@/features/eeg-sessions/components/FormattedSensor'
import { sensorIdShape, sensorsShape } from '@/features/eeg-sessions/store/propTypes'
import { isSensorRightBrain, isSensorLeftBrain } from '@/features/eeg-sessions/utils/sensors'

function TableItem ({ backgroundColor, sensor }) {
  return (
    <FormattedMessage defaultMessage='{sensor}: {description}' values={{
      sensor,
      description: <FormattedSensor id={sensor} messages='shortDescriptions' />,
    }}>
      {txt => (
        <Typography variant='caption' component='div' sx={{ backgroundColor, padding: '1px 4px', height: '100%' }} >
          {txt}
        </Typography>
      )}
    </FormattedMessage>
  )
}

TableItem.propTypes = {
  backgroundColor: PropTypes.string,
  sensor: sensorIdShape,
}

const getBackgroundColor = (i) => i % 2 ? 'rgba(255,255,255,.3)' : 'rgba(120,120,120,.3)'

function SensorsReferenceTable ({ sensors, ...otherProps }) {
  if (isEmpty(sensors)) return null

  const rightBrain = sensors.filter(isSensorRightBrain)
  const leftBrain = sensors.filter(isSensorLeftBrain)
  const centralBrain = without(sensors, ...rightBrain, ...leftBrain)

  const items = []
  for (let i = 0; i < rightBrain.length; i++) {
    const backgroundColor = getBackgroundColor(i)
    items.push(
      <Grid key={leftBrain[i]} item xs={12} sm={6}>
        <TableItem backgroundColor={backgroundColor} sensor={leftBrain[i]} />
      </Grid>,
      <Grid key={rightBrain[i]} item xs={12} sm={6}>
        <TableItem backgroundColor={backgroundColor} sensor={rightBrain[i]} />
      </Grid>,
    )
  }

  for (let i = 0; i < centralBrain.length; i++) {
    const backgroundColor = getBackgroundColor(i)
    const id = centralBrain[i]

    items.push(
      <Grid key={`${id}-pl`} item xs={0} sm={3} />,
      <Grid key={`${id}`} item xs={0} sm={6}>
        <TableItem backgroundColor={backgroundColor} sensor={id} />
      </Grid>,
      <Grid key={`${id}-pr`} item xs={0} sm={3} />,
    )
  }

  return (
    <Grid container spacing={0.25} {...otherProps}>
      {items}
    </Grid>
  )
}

SensorsReferenceTable.propTypes ={
  sensors: sensorsShape,
}

export default SensorsReferenceTable

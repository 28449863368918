import React from 'react'
import { FormattedMessage } from 'react-intl'
import { PieChart } from 'react-minimal-pie-chart'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import ExecutiveStyleSection from './ExecutiveStyleSection'
import { analysisShape, sensorsShape } from '@/features/eeg-sessions/store/propTypes'
import { computeSensorsScore } from '@/features/eeg-sessions/utils/analyses'
import Heading from '@/features/ui/components/Heading'
import { br } from '@/features/intl/utils/intl'
import { pagebreak } from '@/utils/print'

const BREAKPOINT = 'md'

const StyledPieChart = ({ orientation, ...otherProps }) => {
  const props = {
    label: ({ dataEntry: { percentage } = {} }) => `${Math.round(percentage)} %`,
    labelStyle: { fontSize: '.5rem', fontWeight: 'bold' },
    lineWidth: 75,
    radius: 45,
    segmentsShift: 1,
    startAngle: orientation === 'vertical' ? 0 : -90,
    ...otherProps,
  }

  return <PieChart {...props} />
}

function Hint () {
  return (
    <Typography component='div' variant='body1'>
      <FormattedMessage defaultMessage="Of course, we tend to focus on both. The difference is a matter of emphasis. If the difference is more than 5%, and certainly if it's 15% or more, then it's likely a strong preference for you." />
    </Typography>
  )
}

function Labels ({ children }) {
  return (
    <Stack alignItems='center'>
      {children.map((item, i) => (
        <Typography key={i} component='div' variant='body1' sx={{ fontWeight: 'bold', textTransform: 'uppercase' }}>
          {item}
        </Typography>
      ))}
    </Stack>
  )
}

function OrientationSection ({ analysis, sensors }) {
  const theme = useTheme()

  const orientation = useMediaQuery(theme.breakpoints.down(BREAKPOINT)) ? 'vertical' : 'horizontal'

  const socialFocus = computeSensorsScore(['FC6', 'T4', 'O2', 'C4', 'PO8', 'F8'], analysis)
  const technicalFocus = computeSensorsScore(['FC5', 'T3', 'O1', 'PO7', 'Pz', 'CP1'], analysis)
  const abstractStyle = computeSensorsScore(['F7', 'F4', 'T6', 'FT9', 'P4', 'Fz', 'CP2'], analysis)
  const concreteStyle = computeSensorsScore(['FC1', 'F3', 'T5', 'FTX', 'P3', 'C3', 'FC2'], analysis)

  return (
    <React.Fragment>
      <Heading id='orientation' component='h2' variant='h2' gutterTop gutterBottom style={pagebreak}
        title={<FormattedMessage defaultMessage='Your orientation' />} />
      {/* Decision-making style */}
      <Heading component='h3' variant='h3' gutterTop gutterBottom
        title={<FormattedMessage defaultMessage='Your favorite focus: {focus}' values={{
          focus: technicalFocus > socialFocus
            ? <FormattedMessage defaultMessage='technical' description='Your favorite focus: {focus}' />
            : <FormattedMessage defaultMessage='social' description='Your favorite focus: {focus}' />
        }} />} />
      <FormattedMessage defaultMessage="In many life situations, there are two targets to focus on.
        For example, when talking to a person about a task in front of you, you may focus more on the person or the
        task.{br}{br}If you focus more on person, on the quality of social interaction, then you attend most to the person's
        body language, voice tone, and emotions. In contrast, if you focus more on the task, on the technical issues,
        then you attend most to visual precision, word content, and efficiency." values={{ br }} />
      <br/>
      <br/>
      <Hint />
      <Stack spacing={3} direction={{ xs: 'column', [BREAKPOINT]: 'row' }} justifyContent='space-around' alignItems='center' sx={{ py: 8 }}>
        <Labels>
          <FormattedMessage defaultMessage="Technical" />
          <FormattedMessage defaultMessage="Tasks" />
          <FormattedMessage defaultMessage="Things" />
        </Labels>
        <Container maxWidth='xs'>
          <Stack spacing={3} sx={{ textAlign: 'center' }}>
            <StyledPieChart orientation={orientation} data={[
              { value: socialFocus, color: theme.palette.primary.main },
              { value: technicalFocus, color: theme.palette.primary.light },
            ]} />
          </Stack>
        </Container>
        <Labels>
          <FormattedMessage defaultMessage="Social" />
          <FormattedMessage defaultMessage="Emotions" />
          <FormattedMessage defaultMessage="People" />
        </Labels>
      </Stack>
      {/* Perception style */}
      <Heading component='h3' variant='h3' gutterTop gutterBottom
        title={<FormattedMessage defaultMessage='Your favorite style: {style}' values={{
          style: concreteStyle > abstractStyle
            ? <FormattedMessage defaultMessage='concrete' description='Your favorite style: {style}' />
            : <FormattedMessage defaultMessage='abstract' description='Your favorite style: {style}' />
        }} />} />
      <FormattedMessage defaultMessage="In many life situations, we take a concrete or abstract approach.
        For example, when thinking how to best understanding a situation, you can attend on all the details, what
        facts or steps are involved, and what you can rely on from experience.{br}{br}Alternatively, you can attend to the
        big picture, what broad patterns or concepts are involved, and what you envision playing out in the future." values={{ br }} />
      <br/>
      <br/>
      <Hint />
      <Stack spacing={3} direction={{ xs: 'column', [BREAKPOINT]: 'row' }} justifyContent='space-around' alignItems='center' sx={{ py: 8 }}>
        <Labels>
          <FormattedMessage defaultMessage="Concrete" />
          <FormattedMessage defaultMessage="Linear" />
          <FormattedMessage defaultMessage="Details" />
        </Labels>
        <Container maxWidth='xs'>
          <Stack spacing={3} sx={{ textAlign: 'center' }}>
            <StyledPieChart orientation={orientation} data={[
              { value: abstractStyle, color: theme.palette.primary.main },
              { value: concreteStyle, color: theme.palette.primary.light },
            ]} />
          </Stack>
        </Container>
        <Labels>
          <FormattedMessage defaultMessage="Abstract" />
          <FormattedMessage defaultMessage="Imagination" />
          <FormattedMessage defaultMessage="Concepts" />
        </Labels>
      </Stack>
      <ExecutiveStyleSection analysis={analysis} sensors={sensors} />
    </React.Fragment>
  )
}

OrientationSection.propTypes = {
  analysis: analysisShape,
  sensors: sensorsShape,
}

export default OrientationSection

import React from 'react'
import PropTypes from 'prop-types'
import { FormattedDate, FormattedMessage } from 'react-intl'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardHeader from '@mui/material/CardHeader'
import Skeleton from '@mui/material/Skeleton'

import { useDeleteEEGSessionMutation } from '@/features/eeg-sessions/services/api'
import { sessionShape } from '@/features/eeg-sessions/store/propTypes'
import ConfirmationDialog from '@/features/ui/components/ConfirmationDialog'
import { useGetUserDetailsQuery } from '@/features/users/services/api'

function ReportCard ({ canDelete, canEdit, session, withUserDetails }) {
  const [isConfirmationDialogOpen, setConfirmationDialogOpen] = React.useState(false)

  const { userId } = session || {}
  const { data: user = {}, isFetching: isUserFetching } = useGetUserDetailsQuery(userId, {
    skip: !withUserDetails || !userId,
  })
  const [deleteEEGSession, { isLoading: isDeletingEEGSession }] = useDeleteEEGSessionMutation()

  if (!session) return null

  const initial = user.firstName && user.firstName.charAt(0)

  const avatar = withUserDetails
    ? <Avatar src={user.imageUrl}>{initial}</Avatar>
    : undefined

  let title = (withUserDetails && (user.firstName || user.lastName))
    ? `${user.firstName} ${user.lastName} - ${session.title}`
    : session.title
  if (isUserFetching) title = <Skeleton variant='text' />

  const handleDeleteClick = (e) => { setConfirmationDialogOpen(true) }
  const handleDialogClose = (e) => { setConfirmationDialogOpen(false) }
  const handleDialogConfirmation = (e) => deleteEEGSession(session.id)

  return (
    <Card variant='outlined'>
      <CardHeader
        avatar={avatar} title={title}
        subheader={<FormattedDate value={new Date(session.doneAt)} />} />
			<CardActions>
				<Button size='small' href={`/account/brain-imaging/reports/${session.id}`}>
					<FormattedMessage defaultMessage='View' description='Card action' />
				</Button>
        {!canEdit ? null : (
          <Button size='small' href={`/account/brain-imaging/reports/${session.id}/edit`}>
            <FormattedMessage defaultMessage='Edit' description='Card action' />
          </Button>
        )}
        {!canDelete ? null : (
          <React.Fragment>
            <Button size='small' onClick={handleDeleteClick}>
              <FormattedMessage defaultMessage='Delete' description='Card action' />
            </Button>
            <ConfirmationDialog keepMounted open={isConfirmationDialogOpen} onClose={handleDialogClose}
              onConfirmation={handleDialogConfirmation} loading={isDeletingEEGSession}
              title={<FormattedMessage defaultMessage='Hold on!' description='Dialog title' />}
              content={<FormattedMessage defaultMessage='Are you sure you want to delete this session? Once you confirm, we will not be able to recover it.' />} />
          </React.Fragment>
        )}
			</CardActions>
		</Card>
  )
}

ReportCard.propTypes = {
  canDelete: PropTypes.bool,
  canEdit: PropTypes.bool,
  session: sessionShape.isRequired,
  withUserDetails: PropTypes.bool.isRequired,
}

export default ReportCard

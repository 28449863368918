export const audioKinesthetic = {
  circuits: [
    { nodes: 'Fp1,Fp2', score: 100 },
    { nodes: 'T3,T4', score: 96 },
    { nodes: 'T3,F7', score: 93 },
    { nodes: 'T4,F8', score: 83 },
    { nodes: 'T3,C3', score: 77 },
    { nodes: 'T4,C4', score: 72 },
    { nodes: 'C3,Cz', score: 55 },
    { nodes: 'C4,Cz', score: 55 },
    { nodes: 'T3,T5', score: 52 },
    { nodes: 'T4,T6', score: 47 },
    { nodes: 'T3,FT9', score: 47 },
    { nodes: 'T4,FTX', score: 45 },
    { nodes: 'T4,FC6', score: 41 },
    { nodes: 'F4,FC6', score: 36 },
    { nodes: 'T3,FC5', score: 33 },
    { nodes: 'FC5,F3', score: 33 },
    { nodes: 'C3,F4', score: 32 },
    { nodes: 'C4,F3', score: 31 },
    { nodes: 'T3,Fp1', score: 31 },
    { nodes: 'T4,Fp2', score: 29 },
    { nodes: 'T3,Cz', score: 28 },
    { nodes: 'T4,Cz', score: 28 },
    { nodes: 'O1,O2', score: 27 },
    { nodes: 'O1,O2', score: 27 },
    { nodes: 'O1,O2', score: 26 },
    { nodes: 'O1,O2', score: 26 },
    { nodes: 'O1,O2', score: 25 },
    { nodes: 'O1,O2', score: 25 },
    { nodes: 'O1,O2', score: 24 },
    { nodes: 'O1,O2', score: 23 },
    { nodes: 'Fp1,T4', score: 23 },
    { nodes: 'T3,PO7', score: 23 },
    { nodes: 'T3,CP1', score: 23 },
    { nodes: 'CP1,CP2', score: 22 },
    { nodes: 'CP2,T4', score: 22 },
    { nodes: 'CP5,P3', score: 20 },
    { nodes: 'P3,CP1', score: 20 },
    { nodes: 'CP2,P4', score: 20 },
    { nodes: 'P4,CP6', score: 20 },
    { nodes: 'PO8,T4', score: 20 },
    { nodes: 'T3,FC1', score: 20 },
    { nodes: 'FC2,T4', score: 20 },
    { nodes: 'CP2,C4', score: 19 },
    { nodes: 'C4,FC6', score: 19 },
    { nodes: 'C4,CP6', score: 19 },
    { nodes: 'FC2,T4', score: 19 },
    { nodes: 'CP1,Cz', score: 19 },
    { nodes: 'CP2,Cz', score: 19 },
    { nodes: 'C3,FC5', score: 18 },
    { nodes: 'C3,CP1', score: 18 },
    { nodes: 'C3,CP5', score: 18 },
  ],
}

export default audioKinesthetic

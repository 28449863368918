import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector, useDispatch } from 'react-redux'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

import FormattedSensor from '@/features/eeg-sessions/components/FormattedSensor'
import SensorDescription from '@/features/eeg-sessions/components/SensorDescription'
import { openSelector, sensorIdSelector, setOpen } from '@/features/eeg-sessions/store/reducers/sensorDialog'

function SensorDialog() {
  const open = useSelector(openSelector)
  const sensorId = useSelector(sensorIdSelector)

  const dispatch = useDispatch()
  const handleClose = () => { dispatch(setOpen(false)) }

  const descriptionElementRef = React.useRef(null)
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef
      if (descriptionElement !== null) {
        descriptionElement.focus()
      }
    }
  }, [open])

  return (
    <Dialog open={open} onClose={handleClose} scroll='paper'
      aria-labelledby="sensor-info-dialog-title"
      aria-describedby="sensor-info-dialog-description">
      <DialogTitle id="sensor-info-dialog-title">
        <Container disableGutters sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <FormattedSensor id={sensorId} messages='titles' />
          <span>{sensorId}</span>
        </Container>
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText id="sensor-info-dialog-description"
          ref={descriptionElementRef} tabIndex={-1}>
          <SensorDescription id={sensorId} />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>
          <FormattedMessage defaultMessage='Got it' />
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SensorDialog

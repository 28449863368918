import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'

import { EXECUTIVE_STYLES_IDS } from '@/features/eeg-sessions/store/propTypes/executiveStyles'
import { messages as intlExecutiveStyles } from '@/features/eeg-sessions/messages/executiveStyles.msg'

function ExecutiveStyleAutocomplete ({ textFieldProps = {}, ...otherProps }) {
  const intl = useIntl()

  const getOptionLabel = (id) => intl.formatMessage(intlExecutiveStyles.titles[id])

  const renderInput = (params) => (
    <TextField {...params}
      label={<FormattedMessage defaultMessage='Executive style' description='ExecutiveStyleAutocomplete' />}
      {...textFieldProps} />
  )

  return (
    <Autocomplete disablePortal options={EXECUTIVE_STYLES_IDS}
    getOptionLabel={getOptionLabel} renderInput={renderInput} {...otherProps} />
  )
}

export default ExecutiveStyleAutocomplete

import React from 'react'
import { FormattedMessage } from 'react-intl'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'

export const ActivityGrid = ({ activity, canRemove, onChange, onRemove, textFieldProps }) => {
  const { from, observation, title, to } = activity || {}

  return (
    <Grid container columnSpacing={1}>
      <Grid item xs={6} md={4}>
        <TextField {...textFieldProps} value={title} onChange={onChange('title')}
          label={<FormattedMessage defaultMessage='Activity' description='TextField label' />} />
      </Grid>
      <Grid item xs={3} md={1}>
        <TextField {...textFieldProps} value={from} onChange={onChange('from')}
          label={<FormattedMessage defaultMessage='Start' description='TextField label' />} />
      </Grid>
      <Grid item xs={3} md={1}>
        <TextField {...textFieldProps} value={to} onChange={onChange('to')}
          label={<FormattedMessage defaultMessage='End' description='TextField label' />} />
      </Grid>
      <Grid item xs={10} md={5}>
        <TextField {...textFieldProps} value={observation} onChange={onChange('observation')}
          label={<FormattedMessage defaultMessage='Observation' description='TextField label' />} />
      </Grid>
      {canRemove && (
        <Grid item xs={2} md={1} sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
          <IconButton color='inherit' onClick={onRemove}>
            <RemoveCircleOutlineIcon />
          </IconButton>
        </Grid>
      )}
    </Grid>
  )
}

function ActivitiesFormSection ({ activities, sessionId, setActivities, textFieldProps }) {
    // Inject an empty activity if there is none
    React.useEffect(() => {
      if (!activities?.length) setActivities([{}])
    }, [activities, setActivities])

    const handleAdd = React.useCallback((e) => {
      setActivities([ ...activities, {}])
    }, [activities, setActivities])

    // TODO: Prevent a single activity change from rerendering all activities
    const handleChange = React.useCallback((i) => (field) => (e) => {
      const newActivities = [...activities]
      newActivities[i] = { ...newActivities[i], [field]: e.target.value }
      setActivities(newActivities)
    }, [activities, setActivities])

    const handleRemove = React.useCallback((i) => (e) => {
      const newActivities = [...activities]
      newActivities.splice(i , 1)
      setActivities(newActivities)
    }, [activities, setActivities])

    return (
      <Card variant='outlined'>
        <CardContent>
          <Stack spacing={{ xs: 5, md: 1 }}>
            {activities?.map((activity, i) => (
              <div key={i}>
                <ActivityGrid activity={activity} textFieldProps={textFieldProps}
                  onChange={handleChange(i)} canRemove={activities?.length > 1} onRemove={handleRemove(i)} />
              </div>
            ))}
          </Stack>
        </CardContent>
        <CardActions>
          <Button onClick={handleAdd}>
            <FormattedMessage defaultMessage='Add' description='Card action' />
          </Button>
        </CardActions>
      </Card>
    )
}

export default ActivitiesFormSection
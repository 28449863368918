import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import Header from '@/features/ui/components/Header'
import Helmet from '@/features/ui/components/Helmet'

function SeminarsLandingView () {
  const intl = useIntl()

  const title = intl.formatMessage({ defaultMessage: 'Seminars', description: 'Header title' })
  
  return (
    <React.Fragment>
      <Helmet
        title={title} />
      <Header component='h1' variant='h1' minHeight='100vh'
        title={title}
        subheader={<FormattedMessage defaultMessage='Enjoy a change of environment to learn about your differences.' description='Header subheader'/>} />
    </React.Fragment>
  )
}

export default SeminarsLandingView

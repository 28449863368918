import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { br } from '@/features/intl/utils/intl'
import BlockQuote from '@/features/ui/components/BlockQuote'
import BodyContainer from '@/features/ui/components/BodyContainer'
import Header from '@/features/ui/components/Header'
import Helmet from '@/features/ui/components/Helmet'
import TextMediaContainer from '@/features/ui/components/TextMediaContainer'
import { getMediaPictureSrc } from '@/utils/mediaStorage'

function WorkshopsAndEpicsLandingView () {
  const intl = useIntl()

  return (
    <React.Fragment>
      <Helmet
        title={intl.formatMessage({ defaultMessage: 'Workshops & Epics', description: 'Meta title' })}
        description={intl.formatMessage({ defaultMessage: "Find out what strategies you're adopting with 30 min brain imaging sessions doing a specific task or various tasks to use your brain as you would everyday.", description: 'Header subheader' })} />
      <Header component='h1' variant='h1' minHeight='100vh'
        title={<FormattedMessage defaultMessage='Workshops{br}& Epics' description='Header title' values={{ br }} />}
        subheader={<FormattedMessage defaultMessage='Experience differences.' description='Header subheader' />}
        buttonProps={{
          children: <FormattedMessage defaultMessage="See workshops & epics" description='Header button' />,
          href: '#workshops',
        }} />
      <TextMediaContainer id='workshops' component='h3'
        subheader={<FormattedMessage defaultMessage='A neuroscience and management workshop' description='Heading subheader' />}
        title={<FormattedMessage defaultMessage='Meet with Big Stone' description='Heading title' />}
        text={<FormattedMessage defaultMessage="Defrosting a 21,000-year-old man to tell us about his lifestyle which forged both his and our brain? This is a surprising idea!{br}{br}He is not the fastest animal, nor the fiercest, and must survive while consuming 3000 calories a day. His sight, hearing, and sense of smell enable him to tell a danger or to direct him towards a reward. Like all mammals, his emotions are the surest way to perfectly memorize risky situations.{br}{br}This neuroscience workshop helps us realize that reality as we perceive it is only a simplistic and biased reconstruction of the world around us. We treat others with stereotypes, useful for discerning a potential threat in a fraction of a second, but which turn against us through hasty judgments about individuals.{br}{br}After a series of cognitive difficulties, emotions, fear and joy, in a natural sound context with a smell of fire, we are able to take a step back on the condition of homo sapiens and also on our own condition." values={{ br }} />}
        imageProps={{
          alt: intl.formatMessage({ defaultMessage: 'Insphere Big stone workshops', description: 'Alt text' }),
          src: getMediaPictureSrc('/misc/workshops-and-epics/insphere-big-stone-frames-768x768.png?alt=media&token=e84376a7-56bd-415a-8543-518d18358d31'),
        }}
        buttonProps={{
          children: <FormattedMessage defaultMessage='Meet with Big Stone 👋' description='Button label' />,
          href: '/contact-us',
        }} />
      <BodyContainer maxWidth='sm'>
        <BlockQuote
          quote={<FormattedMessage defaultMessage="We don't see things as they are, we see things as we are." />}
          author={<FormattedMessage defaultMessage='Anaïs Nin' />} />
      </BodyContainer>
      <TextMediaContainer component='h3' variant='text-right'
        subheader={<FormattedMessage defaultMessage='Theatrical and sound epics based on science of personalities' description='Heading subheader' />}
        title={<FormattedMessage defaultMessage='Walk a mile in my shoes' description='Heading title' />}
        text={<FormattedMessage defaultMessage="Human beings are incredibly complex. We have parts of ourselves that want one thing, and other parts that seem to want the exact opposite.{br}{br}Playing a role in theatrical and sound epics, we invite you to explore these tensions that lie within and between us. These tensions originate from different cognitive processes that drive us and shape our reality.{br}{br}Let's pretend our mind is a four passenger vehicle with a driver, a navigator, a 10 year old and a 3 year old. Each participant receives instructions to play a passenger and go on a guided and friendly quest.{br}{br}Together we create and experience unique life stories. After a series of quests to experiment with different personality types, we are able to enjoy different ways of navigating the world." values={{ br }} />}
        imageProps={{
          alt: intl.formatMessage({ defaultMessage: 'Car model epics for personality development', description: 'Alt text' }),
          src: getMediaPictureSrc('/misc/workshops-and-epics/car-model-epics-frames-768x768.png?alt=media&token=5fdb16b8-1396-4e2d-9810-ad5834a8d808'),
        }}
        buttonProps={{
          children: <FormattedMessage defaultMessage='Experience differences 🙀' description='Button label' />,
          href: '/contact-us',
        }} />
      <TextMediaContainer component='h3'
        subheader={<FormattedMessage defaultMessage='Beyond awareness with shamanic journeys' description='Heading subheader' />}
        title={<FormattedMessage defaultMessage='Explore safely with your inner allies' description='Heading title' />}
        text={<FormattedMessage defaultMessage="A shamanic journey is taken in order to go beyond our own awareness to get greater insight and wisdom for life experiences and challenges.{br}{br}Unwind with relaxing and energizing breathing exercises and define your intentions. What do you need help with? Then let drums carry you away to other worlds to meet the wise spirits of nature.{br}{br}The other worlds are landscapes filled with rich symbolism. When we journey we are able to check out our inner landscape to see what is showing up. This is a powerful tool we can practice to process and accept differences.{br}{br}When a drum beat reaches 180 beats per minute, it stimulates production of theta waves in the brain. These brain waves are responsible for our altered states of consciousness.{br}{br}An accessible inner journey to explore and take a new look at ourselves and the world. Which animal spirit will be your ally?" values={{ br }} />}
        imageProps={{
          alt: intl.formatMessage({ defaultMessage: 'Modern shamanic journeys frames', description: 'Alt text' }),
          src: getMediaPictureSrc('/misc/workshops-and-epics/shamanic-journeys-frames-768x768.png?alt=media&token=0ae2ce76-de65-419b-9707-2e24699b334b'),
        }}
        buttonProps={{
          children: <FormattedMessage defaultMessage='Meet with your animal spirit 🐣' description='Button label' />,
          href: '/contact-us',
        }} />
    </React.Fragment>
  )
}

export default WorkshopsAndEpicsLandingView

import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { useTheme } from '@mui/styles'

import { toPoints } from './utils'

export function Head ({ fill, strokeWidth, withLabels }) {
  const theme = useTheme()

  // Use background prop to
  fill = fill || theme.brainCanvas.head.fill
  const { stroke } = theme.brainCanvas.head

  // Draw skull
  const skull = <path key='skull' d='M 500 120 Q 150 150, 150 610 Q 150 930, 500 920 Q 850 930, 850 610 Q 850 150, 500 120'
    stroke={stroke} strokeWidth={strokeWidth} fill={fill} />

  // Draw nose
  let nose = toPoints([ [450, 127.5], [500, 80], [550, 127.5] ])
  nose = <polyline key='nose' points={nose} stroke={stroke} strokeWidth={strokeWidth} fill={fill} />

  // Draw left ear
  let leftEar = toPoints([ [163, 450], [140, 450], [95, 530], [100, 575], [150, 620] ])
  leftEar = <polyline key='left-ear' points={leftEar} stroke={stroke} strokeWidth={strokeWidth} fill={fill} />

  // Draw right ear
  let rightEar = toPoints([ [1000 - 163, 450], [1000 - 140, 450], [1000 - 95, 530], [1000 - 100, 575], [1000 - 150, 620] ])
  rightEar = <polyline key='right-ear' points={rightEar} stroke={stroke} strokeWidth={strokeWidth} fill={fill} />

  let labels = []
  if (withLabels) {
    // Draw labels
    fill = theme.palette.text.secondary

    labels = [
      <text key='left-ear-label' x='160' y='442' fill={fill} textAnchor='end'>
        <FormattedMessage defaultMessage='ear' />
      </text>,
      <text key='right-ear-label' x='840' y='442' fill={fill} textAnchor='start'>
        <FormattedMessage defaultMessage='ear' />
      </text>,
      <text key='nose-label' x='0' y='0' fill={fill} textAnchor='start'
        transform='translate(446, 122) rotate(-44)'>
        <FormattedMessage defaultMessage='nose' />
      </text>,
    ]
  }

  return [skull, nose, leftEar, rightEar, ...labels]
}

Head.propTypes = {
  strokeWidth: PropTypes.number.isRequired,
  withLabels: PropTypes.bool,
}

export default Head

import React from 'react'
import Box from '@mui/material/Box'

function PriceTableImage (props) {
  return (
    <Box sx={{ px: 3 }}>
      <img alt='' {...props} style={{ maxWidth: '100%' }} />
    </Box>
  )
}

export default PriceTableImage

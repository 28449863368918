import React from 'react'
import { FormattedMessage } from 'react-intl'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Box from '@mui/material/Box'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import WiringPattern from '@/features/eeg-sessions/components/WiringPattern'
import WiringPatternDescription from '@/features/eeg-sessions/components/WiringPatternDescription'
import wiringPatterns from '@/features/eeg-sessions/datasets/wiring-patterns'
import { messages as intlWiringPatterns } from '@/features/eeg-sessions/messages/wiringPatterns.msg'
import { analysisShape } from '@/features/eeg-sessions/store/propTypes'
import { b, br } from '@/features/intl/utils/intl'
import Heading from '@/features/ui/components/Heading'
import { pagebreak } from '@/utils/print'


function TopWiringPatternsSection({ analysis }) {
  const { topWiringPatterns = [] } = analysis || {}

  const headingTitle = topWiringPatterns?.length ? (
    <FormattedMessage defaultMessage="Your top {top} brain networks" values={{ top: topWiringPatterns.length }} />
  ) : (
    <FormattedMessage defaultMessage="Your brain networks" />
  )

  return (
    <React.Fragment>
      <Heading
        id="networks"
        component="h2"
        variant="h2"
        gutterTop
        gutterBottom
        style={pagebreak}
        title={headingTitle}
      />
      <Stack spacing={3}>
        <Typography>
          <FormattedMessage defaultMessage="Your brain is wired in three key ways. That is, every brain is different and unique but there are also broad patterns. Typically, there is a dominant pattern. That's #1. Then there is a secondary pattern (#2) that is not as strong but still present and playing a significant role. Finally, there may be a third, less-noticeable pattern (#3), perhaps hard to see and likely half formed." />
        </Typography>
        {topWiringPatterns?.length ? (
          <Typography>
            <FormattedMessage defaultMessage="Your top three are shown below with the strongest shown first. As you read, consider how that pattern plays out in your life." />
          </Typography>
        ) : (
          <Typography>
            <FormattedMessage defaultMessage="No patterns have been selected for you yet. Explore stereotypical wiring patterns to find which networks match your brain wiring." />
          </Typography>
        )}
      </Stack>
      {/* Top patterns */}
      {!topWiringPatterns?.length ? null : (
        <Stack spacing={6} sx={{ my: 6 }}>
          {topWiringPatterns.map((id, i) => (
            <WiringPatternDescription
              key={id}
              id={id}
              ranking={i + 1}
              withTitle
            />
          ))}
        </Stack>
      )}
      <Box sx={{ my: 5 }}>
        {/* Stereotypical patterns */}
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography component="h3" variant="h4">
              <FormattedMessage defaultMessage="Stereotypical wiring patterns" />
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormattedMessage
              defaultMessage="<b>More people are a mix of two patterns.</b> That is, these wiring diagrams are exaggerated to highlight biases. Thus, most people show a mix of two, with results that are not so symmetrical.{br}{br}<b>Each pattern translates to a broad meaning.</b> For example, a front bias tends to be more extroverted, center is more kinesthetic, starburst is more creative, and so on."
              values={{ b, br }}
            />
          </AccordionDetails>
          <AccordionDetails>
            <FormattedMessage defaultMessage="💡 Click images below to get more insights for each pattern. 👇" />
          </AccordionDetails>
          <AccordionDetails>
            <Grid container sx={{ mt: 5 }}>
              {Object.keys(wiringPatterns).map((id) => (
                <Grid key={id} item xs={5} sm={3} columns={15} sx={{ my: 1 }}>
                  <WiringPattern id={id} analysis={wiringPatterns[id]} />
                  <div style={{ textAlign: 'center' }}>
                    <b>
                      <FormattedMessage {...intlWiringPatterns.titles[id]} />
                    </b>
                  </div>
                </Grid>
              ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Box>
    </React.Fragment>
  )
}

TopWiringPatternsSection.propTypes = {
  analysis: analysisShape,
}

export default TopWiringPatternsSection

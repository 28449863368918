import React from 'react'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'

export function IconBox ({ icon, iconSize, title, text }) {
  const theme = useTheme()

  return (
    <Stack spacing={2} sx={{ textAlign: 'center' }} alignItems='center'>
      {icon
        ? React.cloneElement(icon, {
          sx: { color: theme.palette.primary.light, fontSize: iconSize },
        })
        : <Skeleton variant='rectangular' height={iconSize} width={iconSize} />}
      <Typography component='h3' variant='h4'>
        {title}
      </Typography>
      <Typography component='div' variant='body1'>
        {text}
      </Typography>
    </Stack>
  )
}

IconBox.defaultProps = {
  iconSize: 120,
}

export default IconBox

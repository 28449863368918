import React from 'react'
import { FormattedMessage } from 'react-intl'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'

import StyledListSubheader from './StyledListSubheader'

function VisitorMenu () {
  return (
    <List component='nav'>
      <ListItemButton component='a' href='/'>
        <ListItemText primary={<FormattedMessage defaultMessage='Home' description='Drawer button' />} />
      </ListItemButton>
      <ListItemButton component='a' href='/contact-us'>
        <ListItemText primary={<FormattedMessage defaultMessage='Contact us' description='Drawer button' />} />
      </ListItemButton>
      {/* Explore */}
      <StyledListSubheader>
        <FormattedMessage defaultMessage='Explore your differences' description='Drawer subheader' />
      </StyledListSubheader>
      <ListItemButton component='a' href='/brain-imaging'>
        <ListItemText primary={<FormattedMessage defaultMessage='Brain imaging' description='Drawer button' />} />
      </ListItemButton>
      <ListItemButton component='a' href='/workshops-and-epics'>
        <ListItemText primary={<FormattedMessage defaultMessage='Workshops & epics' description='Drawer button' />} />
      </ListItemButton>
      <ListItemButton component='a' href='/seminars'>
        <ListItemText primary={<FormattedMessage defaultMessage='Seminars' description='Drawer button' />} />
      </ListItemButton>
      {/* Join us */}
      <StyledListSubheader>
        <FormattedMessage defaultMessage='Join us' description='Drawer subheader' />
      </StyledListSubheader>
      <ListItemButton component='a' href='/login'>
        <ListItemText primary={<FormattedMessage defaultMessage='Log in' description='Drawer button' />} />
      </ListItemButton>
      <ListItemButton component='a' href='/signup'>
        <ListItemText primary={<FormattedMessage defaultMessage='Sign up' description='Drawer button' />} />
      </ListItemButton>
    </List>
  )
}

export default VisitorMenu

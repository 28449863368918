import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import Avatar from '@mui/material/Avatar'
import Divider from '@mui/material/Divider'
import List from '@mui/material/List'
import ListItemButton  from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import Stack from '@mui/material/Stack'

import { logout, userIdSelector, userRolesSelector } from '@/features/auth/store/reducers/tokens'
import { activeRoleSelector, setActiveRole } from '@/features/ui/store/reducers/userRole'
import { useGetUserDetailsQuery } from '@/features/users/services/api'
import { USER_ROLES, USER_ROLE_ADMIN, USER_ROLE_EXPLORER, USER_ROLE_PRACTITIONER, USER_ROLE_RESEARCHER } from '@/features/users/store/propTypes/roles'
import StyledListSubheader from './StyledListSubheader'

export const USER_ROLES_PLAYGROUND = [
  USER_ROLE_RESEARCHER,
]

function UserMenu ({ closeDrawer }) {
  const activeRole = useSelector(activeRoleSelector)
  const userId = useSelector(userIdSelector)
  const userRoles = useSelector(userRolesSelector)
  const dispatch = useDispatch()

  const handleUserRole = (userRole) => async (e) => {
    const res = await dispatch(setActiveRole({ activeRole: userRole }))

    // NOTE: Keep menu open after switching
    // if (closeDrawer) closeDrawer(e)

    return res
  }

  const handleLogout = async (e) => {
    // NOTE: SessionManager auth component redirects to login page automatically after logging out
    await dispatch(logout())
  }

  const { data: user = {} } = useGetUserDetailsQuery(userId, { skip: !userId })

  const getUserRoleItemText = (userRole) => {
    switch (userRole) {
      case USER_ROLE_ADMIN:
        return <FormattedMessage defaultMessage='Switch to admin' description='Drawer button' />
      case USER_ROLE_PRACTITIONER:
        return <FormattedMessage defaultMessage='Switch to practitioning' description='Drawer button' />
      case USER_ROLE_RESEARCHER:
        return <FormattedMessage defaultMessage='Switch to researching' description='Drawer button' />
      case USER_ROLE_EXPLORER:
      default:
        return <FormattedMessage defaultMessage='Switch to exploring' description='Drawer button' />
    }

  }
  return (
    <React.Fragment>
      <Stack alignItems='center'>
        <Avatar src={user.imageUrl} sx={{ height: 100, width: 100, flexShrink: 0, flexGrow: 0, mt: 2.5 }} />
        <p>{user.firstName} {user.lastName}</p>
      </Stack>
      <Divider />
      <List component='nav'>
        {/* Profile */}
        <ListItemButton component='a' href='/account/profile'>
          <ListItemText primary={<FormattedMessage defaultMessage='Profile' description='Drawer button' />} />
        </ListItemButton>
        {/* Explore */}
        <StyledListSubheader>
          <FormattedMessage defaultMessage='Explore your differences' description='Drawer subheader' />
        </StyledListSubheader>
        <ListItemButton component='a' href='/workshops-and-epics'>
          <ListItemText primary={<FormattedMessage defaultMessage='Workshops & epics' description='Drawer button' />} />
        </ListItemButton>
        <ListItemButton component='a' href='/seminars'>
          <ListItemText primary={<FormattedMessage defaultMessage='Seminars' description='Drawer button' />} />
        </ListItemButton>
        {/* Brain imaging */}
        <StyledListSubheader>
          <FormattedMessage defaultMessage='Brain imaging' description='Drawer subheader' />
        </StyledListSubheader>
        <ListItemButton component='a' href='/brain-imaging'>
          <ListItemText primary={<FormattedMessage defaultMessage='Discover' description='Drawer button' />} />
        </ListItemButton>
        <ListItemButton component='a' href='/account/brain-imaging/reports'>
          <ListItemText primary={activeRole === USER_ROLE_PRACTITIONER
            ? <FormattedMessage defaultMessage='Customer sessions' description='Drawer button' />
            : <FormattedMessage defaultMessage='Sessions' description='Drawer button' />} />
        </ListItemButton>
        {!~USER_ROLES_PLAYGROUND.indexOf(activeRole) ? null : (
          <ListItemButton component='a' href='/playground'>
            <ListItemText primary={<FormattedMessage defaultMessage='Playground' description='Drawer button' />} />
          </ListItemButton>
        )}
        {/* Navigate */}
        <StyledListSubheader>
          <FormattedMessage defaultMessage='Navigate' description='Drawer subheader' />
        </StyledListSubheader>
        {USER_ROLES
          .filter((role) => userRoles[role] && activeRole !== role)
          .map((role) => (
            <ListItemButton key={role} component='a' onClick={handleUserRole(role)}>
              <ListItemText primary={getUserRoleItemText(role)} />
            </ListItemButton>
          ))}
				<ListItemButton component='a' onClick={handleLogout}>
					<ListItemText primary={<FormattedMessage defaultMessage='Log out' description='Drawer button' />} />
				</ListItemButton>
      </List>
    </React.Fragment>
  )
}

UserMenu.propTypes = {
  closeDrawer: PropTypes.func,
}

export default UserMenu

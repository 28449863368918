import { getAuth, sendPasswordResetEmail } from 'firebase/auth'
import isEmpty from 'lodash/isEmpty'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import LoadingButton from '@mui/lab/LoadingButton'
import Alert from '@mui/material/Alert'
import Avatar from '@mui/material/Avatar'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/styles'

import { isEmail } from '@/features/forms/utils/validation'
import BodyContainer from '@/features/ui/components/BodyContainer'
import Helmet from '@/features/ui/components/Helmet'

function ForgotPasswordView () {
  const intl = useIntl()
  const theme = useTheme()

  const [email, setEmail] = React.useState('')
  const [errors, setErrors] = React.useState({})
  const [isLoading, setLoading] = React.useState(false)
  const [isSuccess, setSuccess] = React.useState(undefined)

  const handleSubmit = (e) => {
    e.preventDefault()
    setLoading(true)

    return sendPasswordResetEmail(getAuth(), email, {
      url: `${window?.location?.origin}/login`,
    })
      .then(() => {
        setLoading(false)
        setSuccess(true)
        setErrors({})
      })
      .catch((err) => {        
        const { code } = err || {}
        const errors = {}
        if (code === 'auth/invalid-email') errors.email = <FormattedMessage defaultMessage='Invalid email' />
        else if (code === 'auth/user-not-found') errors.email = <FormattedMessage defaultMessage='No user corresponds to the email address.' />
        else errors.general = <FormattedMessage defaultMessage='Internal error' />
        
        setErrors(errors)
        setLoading(false)
        setSuccess(false)
      })
  }

  const handleEmailChange = (e) => {
    const email = e.target.value
    setEmail(email)

    // Handle error display
    if (!isEmail(email)) setErrors({ email: true })
    else setErrors({})

    // Handle alert display
    if (isSuccess) setSuccess(undefined)
  }

  const title = intl.formatMessage(({ defaultMessage: 'Forgot password?' }))
  const description = intl.formatMessage(({ defaultMessage: "Enter the email address associated with your account, and we'll email you a link to reset your password." }))

  return (
    <React.Fragment>
    <Helmet
      title={title}
      description={description} />
    <BodyContainer maxWidth='xs'
      sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Avatar sx={{ m: 1, bgcolor: theme.palette.secondary.main }}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component='h1' variant='h5'>
        {title}
      </Typography>
      <Typography component='div' variant='body1' sx={{ my: 3 }}>
         {description}
      </Typography>
      <form noValidate>
        <TextField id='email' name='email' autoComplete='email' required
          fullWidth autoFocus variant='outlined' margin='normal'
          helperText={typeof errors.email === 'boolean' ? undefined : errors.email}
          error={!isEmpty(errors)} onChange={handleEmailChange}
          label={<FormattedMessage defaultMessage='Email address' />} />
        <LoadingButton type='submit' fullWidth variant='contained' color='primary' sx={{ mt: 3, mb: 2 }}
          onClick={handleSubmit} disabled={!isEmail(email)} loading={isLoading}>
          <FormattedMessage defaultMessage='Send reset link' />
        </LoadingButton>
        {/* Errors */}
        {!errors.general ? null : (
          <Typography variant='body2' sx={{ color: 'red', fontSize: '0.8rem', mt: 1 }}>
            {errors.general}
          </Typography>
        )}
        {/* Success */}
        {!isSuccess ? null : (
          <React.Fragment>
            <Alert severity='success' variant='outlined' sx={{ my: 2 }}>
              <FormattedMessage defaultMessage="A link to reset your password has been sent to {email}." description='Alert message' values={{ email }} />
            </Alert>
            <Alert severity='info'  variant='outlined'>
              <FormattedMessage defaultMessage="Check your spam folder if you can't find it in your inbox." description='Alert message' />
            </Alert>
          </React.Fragment>        
        )}
      </form>
    </BodyContainer>
  </React.Fragment>
  )
}

export default ForgotPasswordView
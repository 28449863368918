import React from 'react'
import { FormattedMessage } from 'react-intl'

import Header from '@/features/ui/components/Header'

function FooterHeader () {
  return (
    <Header component='span' variant='h1'
      title={<FormattedMessage defaultMessage='Enjoy your differences' description='Footer header title' />}
      subheader={<FormattedMessage defaultMessage='Explore, understand and accept differences to build trust and find balance.' description='Footer header subheader '/>} />
  )
}

export default FooterHeader

export const backLeft = {
  circuits: [
    { nodes: 'O1,O2', score: 100 },
    { nodes: 'PO7,O1', score: 96 },
    { nodes: 'T5,O1', score: 93 },
    { nodes: 'T5,O2', score: 83 },
    { nodes: 'T3,T5', score: 77 },
    { nodes: 'T5,P3', score: 72 },
    { nodes: 'FT9,T5', score: 55 },
    { nodes: 'FC5,T5', score: 55 },
    { nodes: 'FT9,T3', score: 52 },
    { nodes: 'T5,PO7', score: 47 },
    { nodes: 'Fp1,Fpz', score: 47 },
    { nodes: 'Fp1,Fp2', score: 45 },
    { nodes: 'Fpz,Fp2', score: 41 },
    { nodes: 'Fp1,F7', score: 36 },
    { nodes: 'F7,F3', score: 33 },
    { nodes: 'F7,FT9', score: 33 },
    { nodes: 'T5,T4', score: 32 },
    { nodes: 'T5,PO8', score: 31 },
    { nodes: 'T5,CP1', score: 31 },
    { nodes: 'T5,CP5', score: 29 },
    { nodes: 'CP5,C3', score: 28 },
    { nodes: 'CP5,P3', score: 28 },
    { nodes: 'T3,CP5', score: 27 },
    { nodes: 'FT9,FC5', score: 27 },
    { nodes: 'F3,FC5', score: 26 },
    { nodes: 'T3,FC5', score: 26 },
    { nodes: 'T3,T4', score: 25 },
    { nodes: 'P3,PO7', score: 25 },
    { nodes: 'P3,O1', score: 24 },
    { nodes: 'O2,PO8', score: 23 },
    { nodes: 'PO7,PO8', score: 23 },
    { nodes: 'PO7,O2', score: 23 },
    { nodes: 'T5,T6', score: 23 },
    { nodes: 'F7,T5', score: 22 },
    { nodes: 'T3,O1', score: 22 },
    { nodes: 'T3,PO8', score: 20 },
    { nodes: 'T3,CP1', score: 20 },
    { nodes: 'T3,C3', score: 20 },
    { nodes: 'T3,Fp1', score: 20 },
    { nodes: 'FT9,F3', score: 20 },
    { nodes: 'F3,FC1', score: 20 },
    { nodes: 'FC5,C3', score: 20 },
    { nodes: 'C3,FC1', score: 19 },
    { nodes: 'C3,CP1', score: 19 },
    { nodes: 'P3,CP1', score: 19 },
    { nodes: 'PO8,T6', score: 19 },
    { nodes: 'T4,T6', score: 19 },
    { nodes: 'T4,FTX', score: 19 },
    { nodes: 'F8,FTX', score: 18 },
    { nodes: 'Fp2,F8', score: 18 },
  ],
}

export default backLeft

import React from 'react'
import { useSelector } from 'react-redux'
import dates_en from 'date-fns/locale/en-US'
import dates_fr from 'date-fns/locale/fr'
import dates_ja from 'date-fns/locale/ja'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import MuiDateTimePicker from '@mui/lab/DateTimePicker'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import TextField from '@mui/material/TextField'

import { localeSelector } from '@/features/intl/store/reducers/intl'

// WARNING: Date-fns locales are non-serializable values according to Redux
const localeMap = {
  en: dates_en,
  fr: dates_fr,
  ja: dates_ja,
}

// NOTE: Handled by default: <MuiDatePicker mask={maskMap[locale]} />
// const maskMap = {
//   en: '__/__/____',
//   fr: '__/__/____',
//   ja: '____/__/__',
// }

function DateTimePicker ({ textFieldProps = {}, ...otherProps }) {
  const locale = useSelector(localeSelector)

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={localeMap[locale]}>
      <MuiDateTimePicker

        renderInput={(params) => <TextField {...textFieldProps} {...params} />}
        {...otherProps} />
    </LocalizationProvider>
  )
}

export default DateTimePicker

import React from 'react'
import { FormattedMessage } from 'react-intl'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Link from '@mui/material/Link'
import TextField from '@mui/material/TextField'

import { useSearchUsersMutation } from '@/features/users/services/api'
import { isEmail } from '@/features/forms/utils/validation'

export const getOptionLabel = (option) => option
  ? `${option.firstName} ${option.lastName}`
  : ''

export const isOptionEqualToValue = (option, value) => option.id === value.id
export const filter = createFilterOptions()

export function UserAvatar ({ option }) {
  return <Avatar src={option && option.imageUrl} sx={{ height: 30, width: 30 }} />
}

function UserAutocomplete ({ onChange, textFieldProps = {}, value, ...otherProps }) {
  const [inputValue, setInputValue] = React.useState('')
  const [options, setOptions] = React.useState(value ? [value] : []) // eslint-disable-line no-unused-vars

  const [searchUsers, { isLoading: isSearching }] = useSearchUsersMutation()

  React.useEffect(() => {
    let active = true

    if (inputValue === '' || !isEmail(inputValue)) {
      // NOTE: Not sure why it does not show value in options when there is one
      // e.g. Enter an email address, select the option, click on TextField again.
      // It should show selected value, but it currently shows "No options"
      setOptions(value ? [value] : [])
      return undefined
    }

    searchUsers({ email: inputValue, limit: 5 })
      .unwrap()
      .then((results) => {
        if (active) {
          let options = []
          if (value) options = [value]
          if (results.length) options = [...options, ...results]
          // Inject creatable option with an href
          else options = [{ href: '/signup', email: inputValue }]

          setOptions(options)
        }
      })

    return () => { active = false }
  }, [value, inputValue, searchUsers])

  const getInputProps = (params) => ({
    ...params.InputProps,
    startAdornment: (
      <React.Fragment>
        {params.InputProps.startAdornment}
        <UserAvatar option={value && (inputValue === getOptionLabel(value) ? value : null)} />
      </React.Fragment>
    ),
    endAdornment: (
      <React.Fragment>
        {!isSearching ? null : <CircularProgress color='inherit' size={20} />}
        {params.InputProps.endAdornment}
      </React.Fragment>
    ),
  })

  const renderInput = (params) => (
    <TextField {...params}
      label={<FormattedMessage defaultMessage='User' description='Autocomplete label' />}
      helperText={<FormattedMessage defaultMessage='Use an email address for searching.' descrition='TextField helperText' />}
      InputProps={getInputProps(params)} {...textFieldProps} />
  )


  const filterOptions = (options, params) => options

  const renderOption = (props, option) => (
    <li {...props}>
      {!option.href ? (
        <React.Fragment>
          <Box sx={{ mr: 1 }}>
            <UserAvatar option={option} />
          </Box>
          {option.firstName} {option.lastName}
        </React.Fragment>
      ) : (
        <Link href={option.href} sx={{ textDecoration: 'none', color: 'inherit' }}>
          <FormattedMessage defaultMessage='Signup {email}' description='UserAutocomplete creatable' values={{ email: option.email }} />
        </Link>
      )}
    </li>
  )

  return (
    <Autocomplete autoComplete includeInputInList filterSelectedOptions
      getOptionLabel={getOptionLabel} filterOptions={filterOptions} options={options}
      value={value} onChange={(e, option) => { setOptions(option ? [option, ...options] : options); onChange(e, option); }}
      onInputChange={(e, inputValue) => { setInputValue(inputValue && inputValue.trim()) }}
      loading={isSearching} loadingText={<FormattedMessage defaultMessage='Loading...' />}
      noOptionsText={<FormattedMessage defaultMessage='No options' />}
      renderInput={renderInput} renderOption={renderOption} {...otherProps} />
  )
}

export default UserAutocomplete

import React from 'react'
import { FormattedMessage } from 'react-intl'
import Grid from '@mui/material/Grid'

import { br } from '@/features/intl/utils/intl'
import Header from '@/features/ui/components/Header'
import { getMediaPictureSrc } from '@/utils/mediaStorage'

export function Services () {
  return (
    <Grid container spacing={0.5}>
      <Grid item xs={12} md={4}>
        <Header component='span' variant='h3'
          image={getMediaPictureSrc('/misc/landing/brain-imaging-session-1024x576.jpg?alt=media&token=4e20149e-1079-4723-a517-70089934c5a6')}
          title={<FormattedMessage defaultMessage='Brain imaging{br}sessions' description='Header title' values={{ br }} />}
          subheader={<FormattedMessage defaultMessage='Dive into your cognition.' description='Header subheader' />}
          buttonProps={{
            children: <FormattedMessage defaultMessage='Dive in' description='Button label' />,
            href: '/brain-imaging',
          }} />
      </Grid>
      <Grid item xs={12} md={4}>
        <Header component='span' variant='h3'
          image={getMediaPictureSrc('/misc/landing/workshops-1024x576.jpg?alt=media&token=e501fcb8-4fa3-466c-af11-2a96ff5de137')}
          title={<FormattedMessage defaultMessage='Workshops{br}& Epics' description='Header title' values={{ br }} />}
          subheader={<FormattedMessage defaultMessage='Experience differences.' description='Header subheader' />}
          buttonProps={{
            children: <FormattedMessage defaultMessage='See more' />,
            href: '/workshops-and-epics',
          }} />
      </Grid>
      <Grid item xs={12} md={4}>
        <Header component='span' variant='h3'
          image={getMediaPictureSrc('/misc/landing/seminars-1024x576.jpg?alt=media&token=19a168ec-5c40-4884-9b90-bdffaa1cd107')}
          title={<FormattedMessage defaultMessage='Seminars' description='Header title' />}
          subheader={<FormattedMessage defaultMessage='Enjoy a change of environment to learn about your differences.' description='Header subheader' />}
          buttonProps={{
            children: <FormattedMessage defaultMessage='Explore' description='Button label' />,
            href: '/seminars',
          }} />
      </Grid>
      {/* <Grid item xs={12} md={4}>
        <Header component='span' variant='h3' overlayProps={{ opacity: 0.35 }}
          image={getMediaPictureSrc('/misc/landing/conferences-1024x576.jpg?alt=media&token=86239b8a-89a7-4f3a-953c-19fba411d103')}
          title={<FormattedMessage defaultMessage='Conferences' description='Header title' />}
          subheader={<FormattedMessage defaultMessage='Get inspired by differences.' description='Header subheader' />}
          buttonProps={{
            children: <FormattedMessage defaultMessage='See more' />,
            href: '/conferences',
          }} />
      </Grid> */}
      {/* <Grid item xs={12} md={4}>
        <Header component='span' variant='h3' overlayProps={{ opacity: 0.35 }}
          image={getMediaPictureSrc('/misc/landing/training-1024x576.jpg?alt=media&token=d8c82fab-95ad-42bd-bc7f-9a8ecda6a096')}
          title={<FormattedMessage defaultMessage='Training{br}& Coaching' description='Header title' values={{ br }} />}
          subheader={<FormattedMessage defaultMessage='Learn how to work with differences.' description='Header subheader' />}
          buttonProps={{
            children: <FormattedMessage defaultMessage='Learn about it' description='Button label' />,
            href: '/training-and-coaching',
          }} />
      </Grid> */}
    </Grid>
  )
}

export default Services

import ListSubheader from '@mui/material/ListSubheader'
import { styled } from '@mui/material/styles'

const StyledListSubheader = styled(ListSubheader)(({ theme }) => {
  if (theme.mode !== 'light') return

  return {
    backgroundColor: 'rgba(0,0,0,0.06)'
  }
})

export default StyledListSubheader
import PropTypes from 'prop-types'

import { executiveStyleShape } from '@/features/eeg-sessions/store/propTypes/executiveStyles'
import { sensorsShape } from '@/features/eeg-sessions/store/propTypes/sensors'
import { wiringPatternShape } from '@/features/eeg-sessions/store/propTypes/wiringPatterns'

export const EEG_ANALYSIS_VARIANT_HIGH_FREQ = 'high-freq'
export const EEG_ANALYSIS_VARIANT_LOW_FREQ = 'low-freq'
export const EEG_ANALYSIS_VARIANT_MIXED = 'mixed'

export const EEG_ANALYSIS_VARIANTS = [
  EEG_ANALYSIS_VARIANT_HIGH_FREQ,
  EEG_ANALYSIS_VARIANT_LOW_FREQ,
  EEG_ANALYSIS_VARIANT_MIXED,
]

export const analysisVariantShape = PropTypes.oneOf(EEG_ANALYSIS_VARIANTS)

export const EEG_ANALYSIS_TOP_SKILLS_COUNT = 6
export const EEG_ANALYSIS_TOP_SKILLS_IN_THE_WORKPLACE_COUNT = 3

export const analysisShape = PropTypes.shape({
  circuits: PropTypes.arrayOf(PropTypes.shape({
    nodes: PropTypes.string.isRequired,
    score: PropTypes.number.isRequired,
  }).isRequired),
  executiveStyle: executiveStyleShape,
  normalizedScores: PropTypes.objectOf(PropTypes.number),
  topCircuitHits: PropTypes.number, // number of hits of the highest score of circuits
  topSkills: sensorsShape,
  topWiringPatterns: PropTypes.arrayOf(wiringPatternShape.isRequired),
  variant: analysisVariantShape,
})

import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import ListItem  from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Switch from '@mui/material/Switch'
import { useTheme } from '@mui/styles'

import UserMenu from './UserMenu'
import VisitorMenu from './VisitorMenu'
import { isLoggedInSelector } from '@/features/auth/store/reducers/tokens'
import LocaleSelect from '@/features/intl/components/LocaleSelect'
import { modeSelector, setDarkMode } from '@/features/ui/store/reducers/themeMode'

function DrawerMenu ({ closeDrawer, ...otherProps }) {
  const dispatch = useDispatch()
  const theme = useTheme()
  const isLoggedIn = useSelector(isLoggedInSelector)
  const mode = useSelector(modeSelector)

  const handleModeChange = (e) => { dispatch(setDarkMode(e.target.checked)) }

  return (
    <Drawer style={{ padding: 24 }} {...otherProps}>
      <Box sx={theme.mixins.toolbar} />
      <Divider />
      {isLoggedIn
        ? <UserMenu closeDrawer={closeDrawer} />
        : <VisitorMenu />}
      <Divider />
      <List component='nav' sx={{ minWidth: 300 }}>
        <ListItem>
         <ListItemText primary={<FormattedMessage defaultMessage='Dark mode' />} />
         <Switch edge='end' onChange={handleModeChange} checked={mode === 'dark'} />
       </ListItem>
       <ListItem sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
         <LocaleSelect />
       </ListItem>
      </List>
    </Drawer>
  )
}

DrawerMenu.propTypes = {
  closerDrawer: PropTypes.func,
}

export default DrawerMenu

import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import Autocomplete from '@mui/material/Autocomplete'
import Checkbox from '@mui/material/Checkbox'
import TextField from '@mui/material/TextField'

import FormattedSensor from '@/features/eeg-sessions/components/FormattedSensor'
import { SENSOR_IDS } from '@/features/eeg-sessions/store/propTypes/sensors'


function SensorsAutocomplete ({ textFieldProps = {}, ...otherProps }) {
  const intl = useIntl()

  const getOptionLabel = (id) => id

  const getOptionTitle = (id) => intl.formatMessage({ defaultMessage: '{title} ({id})', description: 'SensorsAutocomplete labels' }, {
    id,
    title: <FormattedSensor id={id} messages='titles' />,
  })

  const renderInput = (params) => (
    <TextField {...params}
      label={<FormattedMessage defaultMessage='Sensors' description='TextField label' />}
      placeholder={intl.formatMessage({ defaultMessage: 'Sensor', description: 'SensorsAutocomplete placeholder' })}
      {...textFieldProps} />
  )

  const renderOption = (props, option, { selected }) => (
    <li {...props}>
      <Checkbox checked={selected} style={{ marginRight: 8 }}
        icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
        checkedIcon={<CheckBoxIcon fontSize='small' />} />
        {getOptionTitle(option)}
    </li>
  )

  return (
    <Autocomplete options={SENSOR_IDS}
      multiple disableCloseOnSelect
      getOptionLabel={getOptionLabel} renderOption={renderOption}
      renderInput={renderInput} {...otherProps} />
  )
}

export default SensorsAutocomplete

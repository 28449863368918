// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import withReauth from '@/features/auth/services/withReauth'
import { accessTokenSelector } from '@/features/auth/store/reducers/tokens'

// Define a service using a base URL and expected endpoints
export const coreApi = createApi({
  reducerPath: 'coreApi',
  baseQuery: withReauth(fetchBaseQuery({
    baseUrl: process.env.REACT_APP_CORE_API_BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const accessToken = accessTokenSelector(getState())
      if (accessToken) headers.set('Authorization', `Bearer ${accessToken}`)

      return headers
    }
  })),
  // NOTE: Endpoints are injected from each /features/:feature/services/api
  endpoints: () => ({}),
})

import React from 'react'
import Typography from '@mui/material/Typography'

function PriceTableDescription (props) {
  return (
    <Typography component='div' variant='body1' {...props} />
  )
}

export default PriceTableDescription
